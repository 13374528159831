<template>
  <div class="w-full grow flex flex-col h-full overflow-hidden">
    <tx-data-table
      :data="data" :columns="columns" :loading="loadingData" :item-actions="itemActions" :list-actions="listActions"
      :sort-column="{ UpdatedDate: false }" :title="t('general.articles')" @refresh="doRefresh"
    />
    <!-- IMPORT -->
    <tx-drawer v-model="showImport" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refDataImporter" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" @data-imported="submitImportedData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- Update Via Import -->
    <tx-drawer v-model="showDataUpdateViaImport" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refUpdateViaImport" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" @data-imported="submitUpdateViaImportData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- IMPORT -->
    <tx-drawer v-model="showDataUpdateViaImportByModel" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refUpdateViaImportModel" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" :is-update-by-model="true" @data-imported="submitUpdateViaImportModelData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- Import By Licensee -->
    <tx-drawer v-model="showImportByLicenseeModel" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refImportByLicenseeModel" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" :is-update-by-model="true" @data-imported="submitImportByLicenseeData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- Set Sizes(Bulk) By Licensee Model Number -->
    <tx-drawer v-model="showSetSizesByLicenseeModel" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refSetSizesByLicenseeModel" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" :is-update-by-model="true" @data-imported="submitSetSizesByLicenseeModelNumberData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- Set Prices(Bulk) By Licensee Model Number -->
    <tx-drawer v-model="showSetPricesByLicenseeModel" right width="700px">
      <tx-data-importer v-if="importModel && selectedAction" ref="refSetPricesByLicenseeModel" :model="importModel" :action="selectedAction.id" :title-text="selectedAction.label" :data="data" :is-update-by-model="true" @data-imported="submitSetPricesByLicenseeModelNumberData" @cancel="closeImportDrawer" />
    </tx-drawer>
    <!-- ALERTS DIALOG -->
    <tx-dialog v-model="showAlertDialog" :show-ok="true" :title="alertDialogTitle" confirm-text="general.ok" width="450px" height="250px" @ok="showAlertDialog = false" @closed="onAlertDialogClosed">
      <div class="whitespace-pre-line">
        {{ alertDialogMessage }}
      </div>
    </tx-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import type { IImportModel, IRequestPrices, IRequestSizes } from '../../type'
import ArticleModel from './article'
import TxDataTable from '@/shared/components/txDataTable'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction } from '@/shared/components/txDataTable/TxDataTable.types'
import TxDataImporter from '@/shared/components/txDataImporter/TxDataImporter.vue'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import { useNotificationStore } from '@/store/notification'
import Job from '@/models/job'
import { articlesBgJob, importByLicenseeBgJob, setPricesByLicenseeModelNumberBgJob, setSizesByLicenseeModelNumberBgJob, updateViaImportBgJob, updateViaImportModelBgJob } from '@/api/t1/article'
import utils from '@/services/utils'
import type { MasterSizeScaleModel, SizeScaleModel } from '@/api/t1/model/sizeModel'
import { getMasterSizeScales, getSellerAvailableSizeScales } from '@/api/t1/size'
import TxDialog from '@/shared/components/TxDialog.vue'
import useEventsBus from '@/shared/composables/eventBus'
import { privileges } from '@/models/constants'

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { onAppEvent, offAppEvent } = useEventsBus()

const columns = ref<ITxDataTableColumn[]>([])
const data = ref<Article[]>([])
const loadingData = ref(true)
const refDataImporter = ref<InstanceType<typeof TxDataImporter>>()
const refUpdateViaImport = ref<InstanceType<typeof TxDataImporter>>()
const refUpdateViaImportModel = ref<InstanceType<typeof TxDataImporter>>()
const refImportByLicenseeModel = ref<InstanceType<typeof TxDataImporter>>()
const refSetSizesByLicenseeModel = ref<InstanceType<typeof TxDataImporter>>()
const refSetPricesByLicenseeModel = ref<InstanceType<typeof TxDataImporter>>()
const selectedAction = ref<ITxDataTableListAction>()
const importArticlesBgFailed = ref('') // TODO SHOW error message
const showImport = ref(false)
const showDataUpdateViaImport = ref(false)
const showDataUpdateViaImportByModel = ref(false)
const showImportByLicenseeModel = ref(false)
const showSetSizesByLicenseeModel = ref(false)
const showSetPricesByLicenseeModel = ref(false)
const showAlertDialog = ref(false)
const alertDialogTitle = ref('')
const alertDialogMessage = ref('')

const articleModel = shallowRef<ArticleModel>()
const importModel = ref<IImportModel>()

const itemActions = computed(() => ([
  // {
  //   id: 'delete',
  //   enabled: true,
  //   visible: item => item.Status > 0,
  //   icon: 'fa-light fa-trash-can',
  //   label: t('general.delete'),
  //   onClick: () => console.log('delete'),
  // },
  // {
  //   id: 'activate',
  //   enabled: true,
  //   visible: item => item.Status === 0,
  //   icon: 'fa-light fa-rotate-right',
  //   label: t('activeOrDeactiveArticle.activate'),
  //   onClick: () => console.log('activate'),
  // },
]) as Array<ITxDataTableItemAction>)

const listActions = computed(() => {
  const actions: ITxDataTableListAction[] = [{
    id: 'import',
    label: t('admin.articles.actions.importArticles'),
    icon: 'fa-light fa-file-import',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UI_ImportArticles),
    enabled: true,
    onClick: () => showImportWizard('import'),
  }, {
    id: 'updateViaImport',
    label: t('admin.articles.actions.updateViaImport'),
    icon: 'fa-light fa-file-pen',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateOnlyCatalogBulkArticle),
    enabled: true,
    onClick: () => showImportWizard('updateViaImport'),
  }, {
    id: 'updateViaImportModel',
    label: t('admin.articles.actions.updateViaImportModel'),
    icon: 'fa-light fa-file-pen',
    visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateBulkAttributesByModelNumbers),
    enabled: true,
    onClick: () => showImportWizard('updateViaImportModel'),
  }]
  // if ArticleAttribute and ModelAttribute is not assigned then disable the importByLicensee related actions
  const articleAttribute = userStore.activeCatalog?.ArticleAttribute
  const modelAttribute = userStore.activeCatalog?.ModelAttribute

  if (userStore.activeCatalog && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: 'importByLicensee',
      label: t('admin.articles.actions.importByLicensee'),
      icon: 'fa-light fa-file-arrow-up',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.ImportArticlesByLicenseeInfo),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '' && utils.isDefined(articleAttribute) && articleAttribute.toString().trim() !== '',
      onClick: () => showImportWizard('importByLicensee'),
    })
  }
  if (userStore.activeCatalog && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: 'setSizesByLicenseeModelNumber',
      label: t('admin.articles.actions.setSizesByLicenseeModelNumber'),
      icon: 'fa-light fa-wave-sine',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdateSizeScaleByLicensee),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '',
      onClick: () => showImportWizard('setSizesByLicenseeModelNumber'),
    })
  }
  if (userStore.activeCatalog && userStore.activeCatalog.IsPriceByStyleApply && userStore.activeCatalog.DataSourceTypeId === 1) {
    actions.push({
      id: 'setPricesByLicenseeModelNumber',
      label: t('admin.articles.actions.setPricesByLicenseeModelNumber'),
      icon: 'fa-light fa-money-check-dollar-pen',
      visible: userStore.userProfile.isValidPrivilege(privileges.admin.articles.listActions.UpdatePricesByLicensee),
      enabled: utils.isDefined(modelAttribute) && modelAttribute.toString().trim() !== '',
      onClick: () => showImportWizard('setPricesByLicenseeModelNumber'),
    })
  }

  return actions
})

onMounted(async () => {
  articleModel.value = new ArticleModel(t)
  columns.value = articleModel.value.getDataTableColumns()
  await doRefresh()
  onAppEvent('refreshAdminArticles', doRefresh)
})
onUnmounted(() => {
  offAppEvent('refreshAdminArticles', doRefresh)
})
async function doRefresh() {
  loadingData.value = true
  data.value = []
  await userStore.doLoadData(['Articles'])
  data.value = await appConfig.DB!.getAdminArticles(userStore.activeCatalog!.CatalogCode)
  loadingData.value = false
}

// Import Functions
function showImportWizard(action) {
  selectedAction.value = listActions.value.find(listAction => listAction.id === action)
  importModel.value = undefined
  if (articleModel.value) {
    importModel.value = articleModel.value.getImportData(action)
  }
  if (action === 'import') {
    showImport.value = true
  }
  else if (action === 'updateViaImport') {
    showDataUpdateViaImport.value = true
  }
  else if (action === 'updateViaImportModel') {
    showDataUpdateViaImportByModel.value = true
  }
  else if (action === 'importByLicensee') {
    showImportByLicenseeModel.value = true
  }
  else if (action === 'setSizesByLicenseeModelNumber') {
    showSetSizesByLicenseeModel.value = true
  }
  else if (action === 'setPricesByLicenseeModelNumber') {
    showSetPricesByLicenseeModel.value = true
  }
}

async function submitImportedData(validData) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
    if (article.ArticleNumber === '') {
      delete article.ArticleNumber
    }
    if (article.ModelNumber === '') {
      delete article.ModelNumber
    }
  })

  importArticlesBgFailed.value = ''
  await articlesBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'Articles',
        Status: res.data.Status,
        UserId: userStore.userProfile.id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.jobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}
async function submitUpdateViaImportData(validData) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
  })

  importArticlesBgFailed.value = ''
  await updateViaImportBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'updateViaImport',
        Status: res.data.Status,
        UserId: userStore.userProfile.id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.updateViaImportJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}
async function submitUpdateViaImportModelData(validData) {
  importArticlesBgFailed.value = ''
  await updateViaImportModelBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'updateViaImportModel',
        Status: res.data.Status,
        UserId: userStore.userProfile.id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.updateViaImportModelJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}
async function submitImportByLicenseeData(validData) {
  const isApplyRetailWindowPrivilegeIsAssigned = userStore.userProfile.isValidPrivilege(privileges.admin.articles.UpdateArticleShipmentWindowRange)
  const isRestrictedRetailWindowOnRole = (utils.isDefined(userStore.activeCatalog?._RoleRestrictedRetailWindow) && !isEmpty(userStore.activeCatalog?._RoleRestrictedRetailWindow) && userStore.activeCatalog?._RoleRestrictedRetailWindow.NotEditable)
  validData.forEach((article) => {
    // Send IsShippingWindowUpdatable as 1 when "DummyArticleApplyRetailWindow" privilege is assigned and IsShippingWindowApply is 1
    // if retail window is restricted on role send IsShippingWindowUpdatable as 0
    article.IsShippingWindowUpdatable = (isApplyRetailWindowPrivilegeIsAssigned && userStore.activeCatalog?.IsShippingWindowApply === 1 && !isRestrictedRetailWindowOnRole) ? 1 : 0
    if (utils.isDefined(article.RetailExitMonthId) && article.RetailExitMonthId !== '') {
      article.ShipmentWindowRangeId = article.RetailExitMonthId
      delete article.RetailIntroMonthId
      delete article.RetailExitMonthId
    }
  })
  importArticlesBgFailed.value = ''
  await importByLicenseeBgJob(userStore.activeCatalog!.CatalogCode, validData).then(async (res) => {
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'importByLicensee',
        Status: res.data.Status,
        UserId: userStore.userProfile.id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.importByLicenseeJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  })
    .catch((e) => {
      console.error(e)
      importArticlesBgFailed.value = t('general.unexpectedError')
    })
    .finally()
}
async function submitSetSizesByLicenseeModelNumberData(validData) {
  try {
    // Get master size scales
    const masterSizeScales: MasterSizeScaleModel[] = await getMasterSizeScales(userStore.activeCatalog!.AccountId)
    const masterSizeScaleMap = masterSizeScales.reduce((map, scale) => {
      map[scale.SizeScale] = scale.Id
      return map
    }, {})

    // Get seller available size scales
    const sizeScales: SizeScaleModel[] = await getSellerAvailableSizeScales(userStore.activeCatalog!.AccountId)
    const requestbody: IRequestSizes = validData.reduce((body, item) => {
      const masterSizeScaleId = masterSizeScaleMap[item.MasterSizeScale]
      const sizeScale = sizeScales.find(ss => ss.SizeScale === item.SizeScale && ss.MasterSizeScaleId === masterSizeScaleId)

      if (sizeScale) {
        body.push({
          SizeScaleId: sizeScale.Id,
          LicenseeModelNumber: item.LicenseeModelNumber,
        })
      }
      else {
        console.warn(`Size scale not found for MasterSizeScaleName: ${item.MasterSizeScale} and SizeScale: ${item.SizeScale}`)
      }
      return body
    }, [])

    importArticlesBgFailed.value = ''
    const res = await setSizesByLicenseeModelNumberBgJob(userStore.activeCatalog!.CatalogCode, requestbody)
    if (userStore.activeCatalog && res.data) {
      const job = new Job({
        Id: res.data.Id,
        CatalogCode: userStore.activeCatalog.CatalogCode,
        Type: 'setSizesByLicenseeModelNumber',
        Status: res.data.Status,
        UserId: userStore.userProfile.id,
        CreatedDate: new Date(),
      })
      await appConfig.DB!.jobs.put(job)
      const message = t('admin.articles.messages.setSizesByLicenseeModelNumberJobSuccess')
      notificationStore.addNotification({ message, type: 'Success' })
    }
  }
  catch (e) {
    console.error(e)
    importArticlesBgFailed.value = t('general.unexpectedError')
  }
}
async function submitSetPricesByLicenseeModelNumberData(validData) {
  // TODO, remove ChangeReason and SkipChangeApproval with price locking
  const requestObj = {
    ArticlePrices: [] as IRequestPrices[],
    ChangeReason: '',
    SkipChangeApproval: 1,
  }

  // Transform data into required format
  const transformedData: IRequestPrices[] = []

  validData.forEach((item) => {
  // Initialize newElement with the expected structure
    const newElement: IRequestPrices = {
      LicenseeModelNumber: item.LicenseeModelNumber,
      Prices: [],
    }

    importModel.value?.fields.forEach((price) => {
      // Skip if there is no price for the price-group, since price-groups are non-mandatory and may not be sent from Excel
      if (utils.isDefined(item[price.SystemName]) && price.SystemName !== 'LicenseeModelNumber') {
        newElement.Prices.push({ PriceGroupId: price.SystemName, Price: item[price.SystemName] })
      }
    })

    // Skip article entry if there is no price group sent
    if (newElement.Prices.length) {
      transformedData.push(newElement)
    }
  })

  requestObj.ArticlePrices = transformedData

  // if there is no priceGroup editable ArticlePrices will be empty array
  if (requestObj.ArticlePrices.length) {
    importArticlesBgFailed.value = ''
    await setPricesByLicenseeModelNumberBgJob(userStore.activeCatalog!.CatalogCode, requestObj).then(async (res) => {
      if (userStore.activeCatalog && res.data) {
        const job = new Job({
          Id: res.data.Id,
          CatalogCode: userStore.activeCatalog.CatalogCode,
          Type: 'setPricesByLicenseeModelNumber',
          Status: res.data.Status,
          UserId: userStore.userProfile.id,
          CreatedDate: new Date(),
        })
        await appConfig.DB!.jobs.put(job)
        const message = t('admin.articles.messages.setPricesByLicenseeModelNumberJobSuccess')
        notificationStore.addNotification({ message, type: 'Success' })
      }
    })
      .catch((e) => {
        console.error(e)
        importArticlesBgFailed.value = t('general.unexpectedError')
      })
      .finally()
  }
  else {
    showAlertDialog.value = true
    alertDialogTitle.value = t('admin.articles.dialog.noEditablePriceGroups.title')
    alertDialogMessage.value = t('admin.articles.dialog.noEditablePriceGroups.body')
  }
}
function onAlertDialogClosed() {
  showAlertDialog.value = false
  alertDialogTitle.value = ''
  alertDialogMessage.value = ''
}
function closeImportDrawer() {
  switch (selectedAction.value?.id) {
    case 'import':
      showImport.value = false
      break
    case 'updateViaImport':
      showDataUpdateViaImport.value = false
      break
    case 'updateViaImportModel':
      showDataUpdateViaImportByModel.value = false
      break
    case 'importByLicensee':
      showImportByLicenseeModel.value = false
      break
    case 'setSizesByLicenseeModelNumber':
      showSetSizesByLicenseeModel.value = false
      break
    case 'setPricesByLicenseeModelNumber':
      showSetPricesByLicenseeModel.value = false
      break
  }
}
</script>
