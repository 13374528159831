export default class LinkedCustomer {
  CatalogCode: number
  CustomerId: number
  CustomerNumber: string
  DPAccountGroup?: string
  Name: string
  Address?: string
  LeadTime?: string
  Status: number
  CustomerChannel?: string
  CustomerCategory?: string
  Discount?: string
  WholesalePriceGroupId: number
  RetailPriceGroupId: number
  RetailAltPriceGroupId: number
  OrderPriceGroupId: number
  Division?: string
  OrderTypes: number[]
  BuyerAccountId: number
  BuyerAccountName: string
  StockDefinitionId?: number
  StockCode?: string
  StockName?: string
  AllocationGroups: LinkedCustomerAllocationGroup[]

  constructor(CatalogCode: number, { CustomerId, CustomerNumber, DPAccountGroup, Name, Address, LeadTime, Status, CustomerChannel, CustomerCategory, Discount, WholesalePriceGroupId, RetailPriceGroupId, OrderPriceGroupId, Division, OrderTypes, BuyerAccountId, BuyerAccountName, StockDefinitionId, StockCode, StockName, AllocationGroups, RetailAltPriceGroupId }: any) {
    this.CatalogCode = CatalogCode
    this.CustomerId = CustomerId
    this.CustomerNumber = CustomerNumber
    this.DPAccountGroup = DPAccountGroup
    this.Name = Name
    this.Address = Address
    this.LeadTime = LeadTime
    this.Status = Status
    this.CustomerChannel = CustomerChannel
    this.CustomerCategory = CustomerCategory
    this.Discount = Discount
    this.WholesalePriceGroupId = WholesalePriceGroupId
    this.RetailPriceGroupId = RetailPriceGroupId
    this.OrderPriceGroupId = OrderPriceGroupId
    this.Division = Division
    this.OrderTypes = OrderTypes
    this.BuyerAccountId = BuyerAccountId
    this.BuyerAccountName = BuyerAccountName
    this.StockDefinitionId = StockDefinitionId
    this.StockCode = StockCode
    this.StockName = StockName
    this.AllocationGroups = AllocationGroups || []
    this.RetailAltPriceGroupId = RetailAltPriceGroupId
  }
}

export class LinkedCustomerAllocationGroup {
  AllocationGroupId: number
  AllocationGroupName: string
  MaxQuantity: number | null
  MinQuantity: number | null

  constructor(customerAllocationGroup: any) {
    this.AllocationGroupId = customerAllocationGroup.Id
    this.AllocationGroupName = customerAllocationGroup.AllocationGroupName
    this.MaxQuantity = customerAllocationGroup.Quantity
    this.MinQuantity = customerAllocationGroup.MinQuantity
  }
}
