<template>
  <tx-dialog
    v-model="dialogVisible" :title="t('merch.dialog.sharedSlides.title')" height="700px" width="800px" show-ok :ok-state="loading ? 'loading' : (currentStepIndex === 0 && checkedNodes.length === 0) ? 'disabled' : 'enabled'"
    :confirm-text="currentStepIndex < (steps.length - 1) ? 'general.next' : 'general.update'" :show-back="true"
    :back-state="currentStepIndex === 0 ? 'disabled' : 'enabled'" @opened="onOpen" @back="onBack" @ok="onNext"
  >
    <div class="flex flex-col w-full h-full">
      <loader v-if="loading" />
      <div v-if="!loading" class="h-full overflow-hidden">
        <!-- Steps -->
        <div class="px-6 py-4 bg-gray-100 border-t border-gray-200 h-[65px]">
          <div class="flex justify-between">
            <template v-for="(step, index) in steps" :key="step">
              <div class="flex flex-col items-center">
                <div
                  class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                  :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                  v-text="index + 1"
                />
                <div class="text-sm" v-text="step" />
              </div>
              <div
                v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
                :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
              />
            </template>
          </div>
        </div>
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        <div v-if="currentStepIndex === 0" class="mt-5 h-[calc(100%_-_90px)] overflow-hidden">
          <div class="mb-2 text-md">
            {{ t('merch.dialog.sharedSlides.selectMerchBoardSlides') }}
          </div>
          <div class="h-[calc(100%_-_20px)] overflow-scroll">
            <tx-tree
              v-if="!loading" ref="refSlideTree" class="tree" show-checkbox="onHoverOrOtherChecked" :data="slideTree" @check-change="OnNodeCheckedChange"
            />
          </div>
        </div>
        <div v-if="currentStepIndex === 1" class="mt-5 h-[calc(100%_-_90px)] overflow-hidden">
          <div class="mb-2 text-md">
            {{ t('merch.dialog.sharedSlides.selectUsers') }}
          </div>
          <div class="flex h-[220px]">
            <!-- Available -->
            <div class="w-1/2 mr-1 border rounded-md h-full">
              <div class="h-10 text-sm text-center bg-gray-200">
                <tx-input v-model="filter" :placeholder="t('shareUserDialog.filter')" />
              </div>
              <ul tabindex="-1" role="listbox" class="w-full overflow-y-auto leading-10 bg-white h-[140px]">
                <li
                  v-for="(accessibleItem) in availableAccessibleItems" :key="`${accessibleItem.type}${accessibleItem.id}`" tabindex="0"
                  class="relative flex items-center h-10 cursor-pointer hover:bg-active"
                  @click="onAccessibleItemSelected(accessibleItem)"
                >
                  <div class="flex flex-1 m-1 text-sm border-b border-grey">
                    <div class="w-full text-left text-primary-500 line-clamp-1">
                      {{ accessibleItem.name }}
                    </div>
                    <div class="w-full text-xs text-right line-clamp-1">
                      {{ accessibleItem.subTitle }}
                    </div>
                  </div>
                </li>
              </ul>
              <div class="h-10 p-1 pr-4 bg-gray-200">
                <tx-button type="text" :text="t('shareUserDialog.addAll')" height="32px" @click="onAddAll" />
              </div>
            </div>

            <!-- Selected -->
            <div class="w-1/2 ml-1 border rounded-md h-full">
              <div class="flex items-center h-10 text-sm bg-gray-200">
                <p class="w-full text-center">
                  {{ t('shareUserDialog.nSelectedUsers', modelValue.length) }}
                </p>
              </div>
              <ul tabindex="-1" role="listbox" class="w-full overflow-y-auto leading-10 h-[140px]">
                <li
                  v-for="accessibleItem in modelValue" :key="accessibleItem.id" tabindex="0"
                  class="relative flex items-center h-10 cursor-pointer hover:bg-active"
                  @click="onSelectedAccessibleItemSelected(accessibleItem)"
                >
                  <div class="flex flex-1 m-1 text-sm border-b border-grey">
                    <div class="w-full text-left text-primary-500 line-clamp-1">
                      {{ accessibleItem.name }}
                    </div>
                    <div class="w-full text-xs text-right line-clamp-1">
                      {{ accessibleItem.subTitle }}
                    </div>
                  </div>
                </li>
              </ul>
              <div class="h-10 p-1 pr-4 bg-gray-200">
                <tx-button type="text" :text="t('shareUserDialog.removeAll')" height="32px" @click="onRemoveAll" />
              </div>
            </div>
          </div>
          <div class="my-2 text-md">
            {{ t('merch.dialog.sharedSlides.selectFolder') }}
          </div>
          <div class="h-[calc(100%_-_280px)] overflow-scroll">
            <tx-tree
              v-if="!loading" ref="refSharedFolderTree" class="tree" show-checkbox="onHoverOrOtherChecked" :data="sharedFolderTree" :allow-strict-check="true"
            />
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import { clone } from 'lodash-es'
import { buildSharedFolderTree, searchNodeInTree } from '@/modules/merch/utils'
import TxDialog from '@/shared/components/TxDialog.vue'
import Loader from '@/shared/components/Loader.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type { AccessibleUserGroupModel, AccessibleUserModel } from '@/api/t1/model/userModel'
import { getAccessibleUsers, getAccessibleUsersGroups } from '@/api/t1/user'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import type Merch from '@/modules/merch/services/merch'
import { merchConstants } from '@/models/constants'
import TxTree from '@/shared/components/TxTree.vue'
import type SharedFolder from '@/models/sharedFolder'
import type MerchSlide from '@/models/merchSlide'
import type { sharedFolderModel, sharedUserGroupModel, sharedUserModel } from '@/api/t1/model/merchModel'
import { useNotificationStore } from '@/store/notification'

export interface IAccessibleItem {
  id: number
  type: AccessibleItemType
  name: string
  subTitle: string
}

interface IProps {
  merch: Merch | undefined
  selectedNodeId?: string
  modelValue?: IAccessibleItem[]
}
const props = withDefaults(defineProps<IProps>(), { selectedNodeId: '', modelValue: () => [] as IAccessibleItem[] })

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const notificationStore = useNotificationStore()

const dialogVisible = ref(false)
const loading = ref(false)
const filter = ref('')
const accessibleItems = ref<IAccessibleItem[]>([])
const steps = ['Slides', 'Users']
const currentStepIndex = ref(0)
const slideTree = ref<ITreeNode[]>([])
const refSlideTree = ref<InstanceType<typeof TxTree>>()
const refSharedFolderTree = ref<InstanceType<typeof TxTree>>()
const checkedNodes = ref<ITreeNode[]>([])
const sharedFolderTree = ref<ITreeNode[]>([])
const indexedAccessibleUsersGroups = ref<Record<number, AccessibleUserGroupModel>>({})
const indexedAccessibleUsers = ref<Record<number, AccessibleUserModel>>({})
const indexedSharedFolders = ref<Record<number, SharedFolder>>({})
const preSelectedUsers = ref<IAccessibleItem[]>([])
const preSelectedSharedFolderIds = ref<number[]>([])
const inactiveUsersGroupsList = ref<number[]>([])
const inactiveUsersList = ref<number[]>([])
const inactiveSharedFolderList = ref<number[]>([])
const modelValue = ref<IAccessibleItem[]>([])

const availableAccessibleItems = computed(() => {
  const selectedItemIds = new Set(modelValue.value.map(x => x.id))
  if (utils.isValidStringValue(filter.value)) {
    return accessibleItems.value.filter(x => !selectedItemIds.has(x.id) && (x.name.toLowerCase().includes(filter.value.toLowerCase()) || x.subTitle.toLowerCase().includes(filter.value.toLowerCase())))
  }
  return accessibleItems.value.filter(x => !selectedItemIds.has(x.id))
})

watch(() => preSelectedSharedFolderIds.value, (keys) => {
  if (sharedFolderTree.value) {
    keys.forEach((key) => {
      const node: ITreeNode | undefined = searchNodeInTree(sharedFolderTree.value, key, 'key', false, false)
      if (utils.isDefined(node)) {
        node.checked = true
      }
    })
  }
}, { deep: true, immediate: true })
function onOpen() {
  loading.value = true
  buildSlideTree()
  if (props.selectedNodeId && slideTree.value) {
    const node: ITreeNode | undefined = searchNodeInTree(slideTree.value, props.selectedNodeId, 'key', false, false)
    if (utils.isDefined(node)) {
      node.checked = true
      checkedNodes.value.push(node)
    }
  }
  loading.value = false
}
function buildSlideTree() {
  if (props.merch && props.merch.merchSlides && props.merch.merchSlides.value && Object.keys(props.merch.merchSlides.value).length) {
    slideTree.value = []
    Object.values(props.merch.merchSlides.value).forEach((slideData) => {
      const target = createFolderTrailAndGetTargetNode(slideData, slideTree.value)
      if (!Array.isArray(target)) {
        const node: ITreeNode = {
          key: slideData.SlideId,
          label: slideData.SlideName,
          sortOrder: slideData.SortOrder,
          faicon: 'fa-light fa-frame',
          checked: false,
          expanded: false,
          isFolder: false,
          path: [],
          children: [],
          actions: [],
        }
        node.parent = target
        utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'labelKey']))
      }
    })
  }
}
function createFolderTrailAndGetTargetNode(slideData, target: ITreeNode[] | ITreeNode) {
  const folderIdPathList = slideData.FolderId.split(merchConstants.folderPathSeparator)
  const folderNamePathList = slideData.FolderName.split(merchConstants.folderPathSeparator)
  for (let i = 0; i < folderIdPathList.length; i++) {
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key === folderIdPathList[i])
    if (!subFolder) {
      subFolder = {
        key: folderIdPathList[i],
        label: folderNamePathList[i],
        sortOrder: slideData.FolderIdSortOrder ? slideData.FolderIdSortOrder : 0,
        checked: false,
        faicon: 'fa-light fa-folder',
        expanded: false,
        isFolder: true,
        path: [],
        children: [],
        actions: [], // ['Edit', 'Delete'],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'label']))
      }
    }
    else {
      subFolder.badgeValue += slideData.unavailableArticleCount
    }
    target = subFolder
  }
  return target
}
function OnNodeCheckedChange() {
  const checkedData = refSlideTree.value?.getCheckedNodes(true)
  checkedNodes.value = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    checkedNodes.value = checkedData.filter(node => node.isFolder === false)
  }
}
function onBack() {
  if (currentStepIndex.value === 1) {
    preSelectedSharedFolderIds.value = []
    currentStepIndex.value--
  }
}
function onNext() {
  if (currentStepIndex.value === 0) {
    errorMessage.value = ''

    // As per current implementation all the slides selected will be shared or unshared with all the users selected.
    // And inactive users will be passed silently to the users slide while calling the AP to share. And all the selected slide have the access to the
    // inactive user once it is activated.
    accessibleItems.value = []
    if (userStore.activeCatalog && props.merch) {
      loading.value = true
      const catalogCode = userStore.activeCatalog.CatalogCode
      const customerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : undefined

      const promises = [getAccessibleUsers(catalogCode, customerId), getAccessibleUsersGroups(), props.merch.getSharedFolders(userStore.activeCatalog.CatalogCode)]

      Promise.all(promises)
        .then(async (responses) => {
          for (const userGroup of responses[1].data as AccessibleUserGroupModel[]) {
            indexedAccessibleUsersGroups[userGroup.Id] = userGroup
            if (userGroup.Status) {
              accessibleItems.value.push({
                id: userGroup.Id,
                type: 'group',
                name: userGroup.Name,
                subTitle: userGroup.AccountName,
              })
            }
          }
          for (const user of responses[0].data as AccessibleUserModel[]) {
            indexedAccessibleUsers[user.Id] = user
            if (user.IsAccessible && user.Status) {
              accessibleItems.value.push({
                id: user.Id,
                type: 'user',
                name: `${user.FirstName} ${user.LastName}`,
                subTitle: user.UserName,
              })
            }
          }
          indexedSharedFolders.value = (responses[2].data as SharedFolder[]).reduce((acu, cur) => (acu[cur.Id] = cur) && acu, {})
          buildFolderTree()
          let preselectedIds: number[] = []
          for (let i = 0; i < checkedNodes.value.length; i++) {
            const merchSlide: MerchSlide = props.merch?.merchSlides.value[checkedNodes.value[i].key]
            if (utils.isDefined(merchSlide)) {
              const sharedUsersList = merchSlide.SharedUsers || []
              const sharedUserGroupsList = merchSlide.SharedUsersGroups || []
              const currentSlideSharedFolders = merchSlide.SharedFolders || []

              // check in sharedUsersGroups for preselected groups or inactive groups
              for (let index = 0; index < sharedUserGroupsList.length; index++) {
                const sharedUserGroupId = sharedUserGroupsList[index].Id
                if (!preselectedIds.includes(sharedUserGroupId)) {
                  const sharedUserGroup = indexedAccessibleUsersGroups[sharedUserGroupId]
                  if (utils.isDefined(sharedUserGroup) && sharedUserGroup.Status) {
                    preselectedIds.push(sharedUserGroupId)
                    preSelectedUsers.value.push({
                      id: sharedUserGroup.Id,
                      type: 'group',
                      name: sharedUserGroup.Name,
                      subTitle: sharedUserGroup.AccountName,
                    })
                  }
                  else {
                    if (!inactiveUsersGroupsList.value.includes(sharedUserGroupId)) {
                      inactiveUsersGroupsList.value.push(sharedUserGroupId)
                    }
                  }
                }
              }
              preselectedIds = []
              // check in sharedUsers for preselected users or inactive users
              for (let index = 0; index < sharedUsersList.length; index++) {
                const userId = sharedUsersList[index].Id
                if (!preselectedIds.includes(userId)) {
                  const user = indexedAccessibleUsers[userId]
                  if (utils.isDefined(user) && user.Status && user.IsAccessible) {
                    preselectedIds.push(userId)
                    preSelectedUsers.value.push({
                      id: user.Id,
                      type: 'user',
                      name: `${user.FirstName} ${user.LastName}`,
                      subTitle: user.UserName,
                    })
                  }
                  else {
                    if (!inactiveUsersList.value.includes(userId)) {
                      inactiveUsersList.value.push(userId)
                    }
                  }
                }
              }
              // check in sharedFolders for preselected sharedFolders or inactive sharedFolders
              for (let index = 0; index < currentSlideSharedFolders.length; index++) {
                const sharedFolderId = currentSlideSharedFolders[index].Id
                if (!preSelectedSharedFolderIds.value.includes(sharedFolderId)) {
                  const sharedFolder = indexedSharedFolders.value[sharedFolderId]
                  if (utils.isDefined(sharedFolder) && sharedFolder.Status) {
                    preSelectedSharedFolderIds.value.push(sharedFolderId)
                  }
                  else if (!inactiveSharedFolderList.value.includes(sharedFolderId)) {
                    inactiveSharedFolderList.value.push(sharedFolderId)
                  }
                }
              }
            }
          }
          // initialize the sharedUsers list with the preselected users
          modelValue.value = preSelectedUsers.value
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
        })
        .finally(() => {
          loading.value = false
        })
    }
    currentStepIndex.value++
  }
  else if (currentStepIndex.value === 1) {
    if (props.merch && userStore.activeCatalog) {
      loading.value = true
      const selectedUsersList: IAccessibleItem[] = clone(modelValue.value)
      const selectedUserGroups: sharedUserGroupModel[] = []
      const selectedUsers: sharedUserModel[] = []
      // Filter and create 2 list for user groups and users
      selectedUsersList.forEach((user) => {
        if (utils.isDefined(user.type) && user.type === 'group') {
          const groupUser: sharedUserGroupModel = {
            Id: user.id,
            Name: user.name,
            AccountId: indexedAccessibleUsersGroups[user.id].AccountId,
            AccountName: indexedAccessibleUsersGroups[user.id].AccountName,
          }
          selectedUserGroups.push(groupUser)
        }
        else {
          const userObject: sharedUserModel = {
            Id: user.id,
            FirstName: indexedAccessibleUsers[user.id].FirstName,
            LastName: indexedAccessibleUsers[user.id].LastName,
            UserName: indexedAccessibleUsers[user.id].UserName,
          }
          selectedUsers.push(userObject)
        }
      })
      if (inactiveUsersList.value.length) {
        inactiveUsersList.value.forEach((inactiveUserId) => {
          if (indexedAccessibleUsers[inactiveUserId]) {
            const userObject: sharedUserModel = {
              Id: inactiveUserId,
              FirstName: indexedAccessibleUsers[inactiveUserId].FirstName,
              LastName: indexedAccessibleUsers[inactiveUserId].LastName,
              UserName: indexedAccessibleUsers[inactiveUserId].UserName,
            }
            selectedUsers.push(userObject)
          }
          else {
          // if information for shared user is missing -
          // accessible user if doesnot contain any user then only this can be executed
            selectedUsers.push({
              Id: inactiveUserId,
              FirstName: 'unknown',
              LastName: 'unknown',
              UserName: 'unknown',
            })
          }
        })
      }
      if (inactiveUsersGroupsList.value.length) {
        inactiveUsersGroupsList.value.forEach((inactiveUsersGroupId) => {
          if (indexedAccessibleUsersGroups[inactiveUsersGroupId]) {
            const groupUser: sharedUserGroupModel = {
              Id: inactiveUsersGroupId,
              Name: indexedAccessibleUsersGroups[inactiveUsersGroupId].Name,
              AccountId: indexedAccessibleUsersGroups[inactiveUsersGroupId].AccountId,
              AccountName: indexedAccessibleUsersGroups[inactiveUsersGroupId].AccountName,
            }
            selectedUserGroups.push(groupUser)
          }
          else {
          // if information for shared users Groups is missing -
          // accessible users Group if doesnot contain any user then only this can be executed
            selectedUserGroups.push({
              Id: inactiveUsersGroupId,
              Name: 'unknown',
              AccountId: -1,
              AccountName: 'unknown',
            })
          }
        })
      }

      const selectedSharedFolders = refSharedFolderTree.value?.getCheckedNodes()
      const sharedFolderList: sharedFolderModel[] = []
      if (selectedSharedFolders && selectedSharedFolders.length) {
        selectedSharedFolders.forEach((folder) => {
          const folderObject: sharedFolderModel = {
            FolderName: folder.label,
            Id: Number(folder.key),
            RootFolderId: indexedSharedFolders.value[folder.key].ParentFolderId,
          }
          sharedFolderList.push(folderObject)
        })
      }
      if (inactiveSharedFolderList.value.length) {
        inactiveSharedFolderList.value.forEach((inactiveId) => {
          if (indexedSharedFolders.value[inactiveId]) {
            const folderObject: sharedFolderModel = {
              Id: inactiveId,
              FolderName: indexedSharedFolders.value[inactiveId].Name,
              RootFolderId: indexedSharedFolders.value[inactiveId].ParentFolderId,
            }
            sharedFolderList.push(folderObject)
          }
          else {
          // if information for shared users Groups is missing -
          // accessible users Group if doesnot contain any user then only this can be executed
            sharedFolderList.push({
              Id: inactiveId,
              FolderName: 'unknown',
              RootFolderId: null,
            })
          }
        })
      }

      const selectedSlides: (string | number)[] = checkedNodes.value.map(node => node.key)
      props.merch.shareSlides(userStore.activeCatalog?.CatalogCode, userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null, selectedSlides, selectedUsers, selectedUserGroups, sharedFolderList).then((response) => {
        if (response && response !== '') {
          errorMessage.value = response === 'Tx-ShareSlideFailed' ? t('merch.dialog.sharedSlides.sharedSlidesWithUserFailed') : t('merch.errors.saveFailed')
        }
        else {
          notificationStore.addNotification({ message: t('merch.dialog.sharedSlides.sharedSlidesWithUserSuccessfully'), type: 'Success' })
          close()
        }
      }).catch(() => {
        errorMessage.value = t('merch.dialog.sharedSlides.sharedSlidesWithUserFailed')
      }).finally(() => {
        loading.value = false
      })
    }
  }
}

function buildFolderTree() {
  if (userStore.userProfile) {
    sharedFolderTree.value = buildSharedFolderTree(indexedSharedFolders.value, userStore.userProfile.id)
  }
}
function onAccessibleItemSelected(accessibleItem: IAccessibleItem) {
  preSelectedUsers.value.push(accessibleItem)
}

function onSelectedAccessibleItemSelected(accessibleItem: IAccessibleItem) {
  const preSelectIndex = preSelectedUsers.value.findIndex(user => user.id === accessibleItem.id)
  if (preSelectIndex !== -1) {
    preSelectedUsers.value.splice(preSelectIndex, 1)
  }
}

function onAddAll() {
  if (availableAccessibleItems.value.length) {
    availableAccessibleItems.value.forEach((value) => {
      preSelectedUsers.value.push(value)
    })
  }
}

function onRemoveAll() {
  for (let index = modelValue.value.length; index >= 0; index--) {
    preSelectedUsers.value.splice(index, 1)
  }
}

function open() {
  resetData()

  dialogVisible.value = true
}

function close() {
  resetData()
  dialogVisible.value = false
}
function resetData() {
  currentStepIndex.value = 0
  slideTree.value = []
  checkedNodes.value = []
  sharedFolderTree.value = []
  indexedAccessibleUsersGroups.value = {}
  indexedAccessibleUsers.value = {}
  indexedSharedFolders.value = {}
  preSelectedUsers.value = []
  preSelectedSharedFolderIds.value = []
  inactiveUsersGroupsList.value = []
  inactiveUsersList.value = []
  inactiveSharedFolderList.value = []
  modelValue.value = []
  errorMessage.value = ''
}
defineExpose({
  open,
  close,
  loading,
  errorMessage,
})
</script>
