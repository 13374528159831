import type { AccessibleUserGroupModel, AccessibleUserModel, UserProfileModel } from './model/userModel'
import Net from '@/services/net'

export function getUserProfile(status: ZeroOrOneType = 1) {
  return Net.t1.get<UserProfileModel>(`/profile?status=${status}`)
}

export function getAccessibleUsers(catalogCode: number, customerId?: number | null) {
  let url = `/catalogs/${catalogCode}/accessibleUsers`
  if (customerId) {
    url += `?CustomerId=${customerId}`
  }
  return Net.t1.get<AccessibleUserModel[]>(url)
}

export function getAccessibleUsersGroups() {
  return Net.t1.get<AccessibleUserGroupModel[]>(`/accountgroups`)
}
