<template>
  <div class="overflow-hidden">
    <div class="flex flex-col h-full overflow-hidden">
      <div class="flex flex-row items-center px-8 py-10 border-b bg-card">
        <div class="flex-grow">
          <div
            class="mb-2 text-4xl font-extrabold leading-none tracking-tight"
            v-text="`${userStore.activeCatalog?.Season} ${t('whiteboard.list.title')}`"
          />
          <div
            class="ml-0.5 font-medium text-secondary"
            v-text="t('whiteboard.list.total', filteredWhiteboards.length)"
          />
        </div>
        <div class="flex items-center space-x-3">
          <tx-input
            ref="refFilter" v-model="filter" type="text" clearable faicon="fa-light fa-magnifying-glass"
            :placeholder="t('catalogSelection.filter')" autofocus :rounded="true"
          />
          <tx-button type="confirm" width="100px" height="36px" :text="t('general.refresh')" @click="refresh" />
          <tx-button type="confirm" width="100px" height="36px" :text="t('general.add')" @click="newDialogVisible = true" />
        </div>
        <div />
        <loader v-if="loading" />
      </div>
      <div class="px-4 py-8 space-y-6 overflow-y-auto">
        <div v-if="loading" class="skeleton">
          <whiteboard-item-skeleton v-for="index in 5" :key="index" />
        </div>
        <div v-else class="flex flex-col">
          <div v-if="filteredGroups.recent.length > 0" class="flex">
            <div class="flex-grow pl-4 m-auto font-medium text-md" v-text="t('whiteboard.list.recent')" />
          </div>
          <div v-if="filteredGroups.recent.length > 0" class="flex flex-row flex-wrap">
            <whiteboard-item
              v-for="item in filteredGroups.recent" :id="item.Id" :key="item.Id" :name="item.Name" :menu="menuOptions"
              display-prop="label" value-prop="key" :owner="item.CreatedByName" @click="doOpenBoard"
              @context="(data) => showContextMenu(data.evt, item)" @menu="doMenu"
            />
          </div>
          <div v-if="filteredGroups.my.length > 0" class="flex pt-4">
            <div class="flex-grow pl-4 m-auto font-medium text-md" v-text="t('whiteboard.list.my')" />
          </div>
          <div v-if="filteredGroups.my.length > 0" class="flex flex-row flex-wrap">
            <whiteboard-item
              v-for="item in filteredGroups.my" :id="item.Id" :key="item.Id" :name="item.Name" :owner="item.CreatedByName"
              display-prop="label" value-prop="key" :menu="menuOptions" @click="doOpenBoard"
              @context="(data) => showContextMenu(data.evt, item)" @menu="doMenu"
            />
          </div>
          <div v-if="filteredGroups.other.length > 0" class="flex pt-4">
            <div class="flex-grow pl-4 m-auto font-medium text-md" v-text="t('whiteboard.list.other')" />
          </div>
          <div v-if="filteredGroups.other.length > 0" class="flex flex-row flex-wrap">
            <whiteboard-item
              v-for="item in filteredGroups.other" :id="item.Id" :key="item.Id" :name="item.Name" :menu="menuOptions"
              display-prop="label" value-prop="key" :owner="item.CreatedByName" @click="doOpenBoard"
              @context="(data) => showContextMenu(data.evt, item)" @menu="doMenu"
            />
          </div>
        </div>
        <!-- PrivacyPolicyDialog -->

        <!-- Catalog Registration Dialog -->
      </div>

      <tx-menu ref="menuRef" :options="menuOptions" @click="onContextMenuOptionClick" />

      <tx-dialog
        v-model="newDialogVisible" :title="t('whiteboard.newDialog.title')" show-ok-cancel :ok-state="okState"
        @ok="doCreateNew" @cancel="newDialogVisible = false"
      >
        <tx-input v-model="newName" :label="t('general.name')" @keydown.enter="doCreateNew" />
      </tx-dialog>

      <tx-dialog
        v-model="duplicateDialogVisible" :title="t('whiteboard.duplicateDialog.title')" show-ok-cancel
        :ok-state="okState" @ok="doDuplicate(contextMenuWhiteboard?.Id)" @cancel="duplicateDialogVisible = false"
      >
        <tx-input v-model="newName" :label="t('general.name')" @keydown.enter="doDuplicate(contextMenuWhiteboard?.Id)" />
      </tx-dialog>

      <tx-dialog
        v-model="renameDialogVisible" :title="t('whiteboard.renameDialog.title')" show-ok-cancel
        :ok-state="okState" @ok="doRenameWhiteboard(contextMenuWhiteboard?.Id)" @cancel="renameDialogVisible = false"
      >
        <tx-input v-model="newName" :label="t('general.name')" @keydown.enter="doRenameWhiteboard(contextMenuWhiteboard?.Id)" />
      </tx-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { v4 as guid } from 'uuid'
import { useRoute, useRouter } from 'vue-router'
import { computed, onBeforeMount, ref } from 'vue'
import ReconnectingWebSocket from 'reconnecting-websocket'
import sharedb from 'sharedb/lib/client'
import { useI18n } from 'vue-i18n'
import { isArray } from 'lodash-es'
import { onKeyStroke } from '@vueuse/core'
import WhiteboardItemSkeleton from './components/WhiteboardItemSkeleton.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import WhiteboardItem from '@/modules/whiteboard/components/WhiteboardItem.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type { WhiteboardItemModel } from '@/api/t1/model/whiteboardModel'
import { createWhiteboard, getMyWhiteboards, updateWhiteboardDetails } from '@/api/t1/whiteboard'
import utils from '@/services/utils'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import TxMenu from '@/shared/components/TxMenu.vue'
import Loader from '@/shared/components/Loader.vue'
import { createContainer, getContainer, getMyContainers, updateContainer } from '@/api/t1/container'
import type { ContainerItemDetails } from '@/api/t1/model/containerModel'
import { containerTypeConstants, whiteboardConstants } from '@/models/constants'
import Whiteboard from '@/models/whiteboard'
import appConfig from '@/services/appConfig'

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { t } = useI18n()

const loading = ref(false)
const whiteboards = ref<WhiteboardItemModel[]>([])
const newDialogVisible = ref(false)
const duplicateDialogVisible = ref(false)
const newName = ref('')
const creating = ref(false)
const menuRef = ref<InstanceType<typeof TxMenu>>()
const contextMenuWhiteboard = ref<WhiteboardItemModel>()
const filter = ref('')
const recentWhiteboardsContainer = ref<ContainerItemDetails>()
const refFilter = ref<InstanceType<typeof TxInput> | null>(null)
const renameDialogVisible = ref(false)

const menuOptions: Array<IContextMenuItem> = [{
  key: 'duplicate',
  label: t('whiteboard.duplicateDialog.title'),
  icon: 'fa-plus',
  disabled: false,
  visible: true,
}, {
  key: 'rename',
  label: t('whiteboard.renameDialog.title'),
  icon: 'fa-edit',
  disabled: false,
  visible: true,
}]

onKeyStroke('/', (e) => {
  if ((e.target as HTMLElement)?.nodeName.toLowerCase() !== 'input') {
    e.preventDefault()
    refFilter.value?.focus()
  }
}, { target: document })

function doOpenBoard(id: number) {
  // Update the list of recent boards
  if (recentWhiteboardsContainer.value) {
    let recent = utils.tryParse(recentWhiteboardsContainer.value.Value)
    if (recent && isArray(recent)) {
      recent.unshift(id)
      if (recent.length > 5) {
        recent.pop()
      }
    }
    else {
      recent = [id]
    }
    const recentWhiteboardContainerName = `${whiteboardConstants.recentWhiteboardsContainerName}-${userStore.userProfile.id}`
    updateContainer(userStore.userProfile.id, recentWhiteboardsContainer.value.Id, recentWhiteboardContainerName, JSON.stringify(recent), userStore.activeCatalog!.CatalogCode)
  }
  const params = { ...route.params, whiteboardId: id }
  router.push({ name: 'WhiteboardDetails', params })
}

function doCreateNew() {
  if (userStore.activeCatalog && newName.value.length > 0) {
    creating.value = true
    const newGuid = guid()
    createWhiteboard(userStore.activeCatalog.CatalogCode, newName.value, newGuid)
      .then((res) => {
        doOpenBoard(res.data.Id)
        userStore.doLoadData(['Whiteboards'])
      })
      .catch((err) => {
        console.error('Failed to create board', err)
        notificationStore.addNotification({ message: t('whiteboard.newDialog.error'), type: 'Alert', actions: ['ShowDetails', 'Support'], details: utils.getErrorMessage(err) })
      })
      .finally(() => {
        creating.value = false
        newDialogVisible.value = false
      })
  }
}

function doMenu(e: { item: IContextMenuItem, id: number }) {
  if (e.item.key === 'duplicate') {
    contextMenuWhiteboard.value = whiteboards.value.find(itm => itm.Id === e.id)
    newName.value = ''
    duplicateDialogVisible.value = true
  }
  else if (e.item.key === 'rename') {
    contextMenuWhiteboard.value = whiteboards.value.find(itm => itm.Id === e.id)
    newName.value = contextMenuWhiteboard.value!.Name
    renameDialogVisible.value = true
  }
}

async function onContextMenuOptionClick(option: IContextMenuItem, targetItem: any) {
  if (option.key === 'duplicate') {
    newName.value = ''
    duplicateDialogVisible.value = true
  }
  else if (option.key === 'rename') {
    contextMenuWhiteboard.value = whiteboards.value.find(itm => itm.Id === targetItem.value.Id)
    newName.value = contextMenuWhiteboard.value!.Name
    renameDialogVisible.value = true
  }
}

function showContextMenu(event: MouseEvent, whiteboard?: WhiteboardItemModel) {
  event.preventDefault()
  if (!whiteboard) {
    contextMenuWhiteboard.value = undefined
    menuRef.value?.doClose()
    return
  }
  contextMenuWhiteboard.value = whiteboard
  menuRef.value?.openMenu(event, whiteboard)
}

async function doDuplicate(sourceBoardId) {
  const p = new Promise((resolve, reject) => {
    try {
      const ws = new ReconnectingWebSocket(`${appConfig.ShareDbUrl}/server?t=${encodeURIComponent(localStorage.getItem('tk') || '')}`)
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      const dbConn = new sharedb.Connection(ws)
      let dbDoc = dbConn.get('boards', `${appConfig.T1Env}${sourceBoardId}`)
      dbDoc.fetch((err) => {
        if (err) {
          dbConn.close()
          ws.close()
          return reject(new Error('Unable to fetch source whiteboard'))
        }

        // source data
        const data = dbDoc.data || { version: 0 }
        const newGuid = guid()

        // Create on T1
        createWhiteboard(userStore.activeCatalog!.CatalogCode, newName.value, newGuid).then((res) => {
          const newBoardId = `${appConfig.T1Env}${res.data.Id}`
          dbDoc = dbConn.get('boards', newBoardId)
          data.version = 0
          dbDoc.create(data, (err) => {
            dbConn.close()
            ws.close()
            if (err) {
              return reject(new Error('Unable to create new whiteboard'))
            }
            return resolve(true)
          })
        }).catch((err) => {
          return reject(err)
        })
      })
    }
    catch (error) {
      reject(error)
    }
  })

  creating.value = true
  const res = await utils.tryAsync(p)
  if (res.success) {
    await userStore.doLoadData(['Whiteboards'])
    notificationStore.addNotification({ message: t('whiteboard.duplicateDialog.success'), type: 'Info', actions: ['ShowDetails', 'Support'] })
  }
  else {
    notificationStore.addNotification({ message: t('whiteboard.duplicateDialog.error'), type: 'Alert', actions: ['ShowDetails', 'Support'], details: utils.getErrorMessage(res.error) })
  }
  creating.value = false
  duplicateDialogVisible.value = false
}

function doRenameWhiteboard(whiteboardId) {
  if (userStore.activeCatalog && newName.value.length > 0) {
    creating.value = true
    const newGuid = guid()
    updateWhiteboardDetails(userStore.activeCatalog.CatalogCode, whiteboardId, { Name: newName.value, FluidRelayId: newGuid })
      .then(() => {
        userStore.doLoadData(['Whiteboards'])
        notificationStore.addNotification({ message: t('whiteboard.renameDialog.success'), type: 'Info', actions: ['ShowDetails', 'Support'] })
      })
      .catch((err) => {
        console.error('Failed to rename board', err)
        notificationStore.addNotification({ message: t('whiteboard.renameDialog.error'), type: 'Alert', actions: ['ShowDetails', 'Support'], details: utils.getErrorMessage(err) })
      })
      .finally(() => {
        creating.value = false
        renameDialogVisible.value = false
      })
  }
}

async function refresh() {
  if (userStore.activeCatalog) {
    loading.value = true
    await userStore.doLoadData(['Whiteboards'])

    const myWhiteboards = await (userStore.userProfile.accountDetails.AccountTypeId === 1
      ? appConfig.DB!.whiteboards.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode)
      : appConfig.DB!.whiteboards.where({ CatalogCode: userStore.activeCatalog.CatalogCode, CreatedBy: userStore.userProfile.id })).toArray()

    const sharedWhiteboards: Whiteboard[] = []
    if (userStore.userProfile.accountDetails.AccountTypeId !== 1) {
      await getMyWhiteboards(userStore.activeCatalog.CatalogCode, 'shared')
        .then((resp) => {
          if (resp.data.length) {
            resp.data.forEach(item => sharedWhiteboards.push(new Whiteboard(userStore.activeCatalog!.CatalogCode, item)))
          }
        })
        .catch(e => console.error('Unable to fetch shared whiteboards', e))
    }

    whiteboards.value = myWhiteboards.concat(sharedWhiteboards)
      .sort((a, b) => b.CreatedDate.getTime() - a.CreatedDate.getTime())

    loading.value = false
  }
}

const okState = computed(() => {
  if (creating.value) { return 'loading' }
  if (!newName.value || newName.value.length === 0) { return 'disabled' }
  return 'enabled'
})

const filteredWhiteboards = computed(() => whiteboards.value.filter(itm => itm.Name.toLowerCase().includes(filter.value.toLowerCase()) || itm.CreatedByName.toLowerCase().includes(filter.value.toLowerCase()) || itm.CreatedByEmail.toLowerCase().includes(filter.value.toLowerCase())))

const filteredGroups = computed(() => {
  const res = {
    my: [] as WhiteboardItemModel[],
    recent: [] as WhiteboardItemModel[],
    other: [] as WhiteboardItemModel[],
  }
  let recentBoards: any[] = []
  if (recentWhiteboardsContainer.value) {
    const recent = utils.tryParse(recentWhiteboardsContainer.value.Value)
    if (recent && isArray(recent)) {
      recentBoards = recent
    }
  }

  filteredWhiteboards.value.forEach((itm) => {
    if (itm.CreatedByUserName === userStore.currentUsername) {
      res.my.push(itm)
    }
    else {
      res.other.push(itm)
    }
    if (recentBoards.includes(itm.Id)) { res.recent.push(itm) }
  })
  return res
})

onBeforeMount(async () => {
  refresh()
  // Find the container that has the list of my recent boards , container name will be appended with the userId to keep it unique for all users
  const recentWhiteboardContainerName = `${whiteboardConstants.recentWhiteboardsContainerName}-${userStore.userProfile.id}`
  const containersList = await utils.tryAsync(getMyContainers(userStore.activeCatalog!.CatalogCode, containerTypeConstants.recentWhiteboards))
  if (containersList.success) {
    const container = containersList.result.data.find(f => f.ContainerName === recentWhiteboardContainerName)
    if (!container) {
      // If container doesnt exist, create it
      const res = await utils.tryAsync(createContainer(userStore.userProfile.id, recentWhiteboardContainerName, '[]', userStore.activeCatalog!.CatalogCode, containerTypeConstants.recentWhiteboards))
      if (res.success) { recentWhiteboardsContainer.value = res.result.data }
    }
    else {
      // If container exists, load it
      const res = await utils.tryAsync(getContainer(container.Id))
      if (res.success) { recentWhiteboardsContainer.value = res.result.data }
    }
  }
})
</script>
