<template>
  <nav-layout>
    <div v-if="loading" class="flex flex-col items-center justify-center bg-white">
      <div class="w-[80%] text-center pb-2 text-primary-500 text-md" v-text="progressTitle" />
      <div class="w-[80%] text-left pb-2">
        {{ t('general.loadingCatalogData') }} ({{ totalProgress }}%)
      </div>
      <tx-progress v-model="totalProgress" class="mb-2" width="80%" />
    </div>
    <router-view v-else />
  </nav-layout>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import type { Ref } from 'vue'
import { computed, onMounted, reactive, ref } from 'vue'
import { isArray } from 'lodash-es'
import NavLayout from '@/layouts/NavLayout.vue'
import TxProgress from '@/shared/components/TxProgress.vue'
import CatalogDetails from '@/models/catalogDetails'
import type { Entity } from '@/services/db'
import appConfig from '@/services/appConfig'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'
import { createContainer, getContainer, getMyContainers, updateContainer } from '@/api/t1/container'
import { catalogsConstants } from '@/models/constants'

interface IProgressItem {
  catalogName: string
  val: Ref<number>
}

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const notificationStore = useNotificationStore()

const loading = ref(true)
const progress = ref(0)
const linkedCatalogProgress = reactive<Array<IProgressItem>>([])
let catalogLoadStartTime: number | null = null
let eventName: string = ''

const progressTitle = ref(' ')

onMounted(async () => {
  const lastCall = await appConfig.DB!.singleton.get(`lc-${route.params.catalogCode}-Articles`)
  const isfirstTimeCatalogOpening = !(lastCall && lastCall.dt)
  eventName = isfirstTimeCatalogOpening ? 'First Time Catalog Opening' : 'Consecutive Catalog Opening'
  catalogLoadStartTime = performance.now()
  doLoadCatalog()
})

async function doLoadCatalog() {
  loading.value = true
  const timers: number[] = []
  timers.push(setTimeout(() => { progressTitle.value = t('general.loadingCatalogDataDelay1') }, 10000))
  timers.push(setTimeout(() => { progressTitle.value = t('general.loadingCatalogDataDelay2') }, 30000))
  timers.push(setTimeout(() => { progressTitle.value = t('general.loadingCatalogDataDelay3') }, 100000))
  timers.push(setTimeout(() => { progressTitle.value = t('general.loadingCatalogDataDelay4') }, 300000))

  const catalogCode = Number(route.params.catalogCode)
  const cid = Number(route.params.customerId)
  const rp = Number(route.params.retailPG)
  const wp = Number(route.params.wholesalePG)
  const op = Number(route.params.outletPG)

  try {
    // if the logged in user doesnt have access to previously opened catalog we will throw error and redirect to catalogs list
    const currentCatalog = new CatalogDetails(await appConfig.DB!.getCatalogDetails(catalogCode))
    userStore.setActiveCatalog(currentCatalog)
    await userStore.setLinkedCatalogs(currentCatalog)
    setRecentCatalog(catalogCode)
    await userStore.doInitCatalog(catalogCode, cid, rp, wp, op, p => progress.value = Math.round(p))

    for (const linkedCatalogCode in userStore.linkedCatalogDetails) {
      const linkedCatalogDetails = userStore.linkedCatalogDetails[linkedCatalogCode]
      if (linkedCatalogDetails && linkedCatalogDetails.Status > 0) {
        const progressItem: IProgressItem = { catalogName: linkedCatalogDetails.CatalogName, val: ref(0) }
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        linkedCatalogProgress.push(progressItem)
        const entitiesToLoad: Entity[] = ['Articles', 'Prices', 'DeliveryDates', 'Segmentations']
        await userStore.doLoadData(entitiesToLoad, [linkedCatalogDetails.CatalogCode], false, p => progressItem.val.value = Math.round(p))
      }
    }

    if (userStore.activeCatalog && userStore.activeCatalog.TreeDefinitions && userStore.activeCatalog?.TreeDefinitions[0]) {
      await userStore.doLoadTree(userStore.activeCatalog?.TreeDefinitions[0].Id)
    }
  }
  catch (e) {
    router.replace({ name: 'CatalogsList' })
    const content = e as IWorkerErrorContent
    if (content) {
      notificationStore.addNotification({ message: t('notifications.privilegeError'), type: 'Alert', url: content.url, actions: ['ShowDetails', 'Support'] })
    }
    console.error(e)
  }
  // track catalog opening insights
  if (catalogLoadStartTime !== null) {
    const loadTime = performance.now() - catalogLoadStartTime
    appConfig.setApplicationInsights(eventName, loadTime, catalogCode, userStore.userProfile.userName)
    catalogLoadStartTime = null
  }
  timers.forEach(t => clearTimeout(t))
  loading.value = false
}

async function setRecentCatalog(catalogCode: number) {
  // Find the container that has the list of my recent boards
  const containersList = await utils.tryAsync(getMyContainers())
  if (containersList.success) {
    const container = containersList.result.data.find(f => f.ContainerName === catalogsConstants.recentCatalogsContainerName)
    if (!container) {
      // If container doesn't exist, create it
      await utils.tryAsync(createContainer(userStore.userProfile.id, catalogsConstants.recentCatalogsContainerName, JSON.stringify([catalogCode])))
    }
    else {
      // If container exists, load it
      const res = await utils.tryAsync(getContainer(container.Id))
      if (res.success) {
        let recent = utils.tryParse(res.result.data.Value)
        if (!recent || !isArray(recent)) { recent = [] }
        recent.unshift(catalogCode)
        if (recent.length > 5) { recent.pop() }

        // Update the container
        updateContainer(userStore.userProfile.id, res.result.data.Id, catalogsConstants.recentCatalogsContainerName, JSON.stringify(recent))
      }
    }
  }
}

const totalProgress = computed(() => Math.round((progress.value + linkedCatalogProgress.reduce((acc, obj) => acc + obj.val, 0)) / (userStore.activeCatalog?._AllRelatedCatalogCodes?.length || 1)))
</script>
