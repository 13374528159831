import { fabric } from 'fabric'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { v4 as guid } from 'uuid'
import type Article from '@/models/article'
import type DuneAsset from '@/models/duneAsset'
import appConfig from '@/services/appConfig'
import pngLoading from '@/assets/loading.png'
import pngNoImage from '@/assets/noimg.png'
import { appConstants, privileges, whiteboardConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import { getArticleAssets } from '@/api/t1/article'
import type MyArticle from '@/models/myArticle'

interface IWbModelImageOptions extends fabric.IImageOptions {
  id?: string
  catalogCode: number
  articleId: number
  modelNumber: string
  lock?: boolean
}

export default class WbModelImage extends fabric.Image implements IWbObject {
  public id: string
  public catalogCode: number
  public articleId: number
  public modelNumber: string
  public assets: DuneAsset[]
  public type = whiteboardConstants.objectTypes.modelImage
  public selected = false
  public highlighted = false
  public lock: boolean
  public connectable: boolean
  public imgLoaded: boolean
  public imgLoading: boolean
  private isLoadingFirstTime: boolean = true
  public editableProps: Record<string, IWbObjectProp> = {
    scale: { name: 'size', type: 'scale' },
    lock: { name: 'Lock', type: 'lock' },
    addModelDetails: { name: 'add model details', type: 'addModelDetails' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public actions: Record<string, IWObjectActions> = {
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    bringFront: { action: 'bringFront', label: 'Bring to Front', faicon: 'fa-light fa-bring-front', showInSubMenu: true },
    sendBack: { action: 'sendBack', label: 'Send to Back', faicon: 'fa-light fa-send-back', showInSubMenu: true },
    copyModel: {
      action: 'copyModel',
      label: 'Copy Model',
      faicon: 'fa-light fa-copy',
      showInSubMenu: (() => {
        const userStore = useUserStore()
        return userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && !userStore.currentCustomer
          && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles)
          && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
          && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)
      })(),
    },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public subscriptions: Array<Subscription>

  constructor(element: string | HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, options: IWbModelImageOptions, isLiveObject: boolean = true) {
    super(element, options)
    this.id = options.id || guid()
    this.catalogCode = options.catalogCode
    this.articleId = options.articleId
    this.modelNumber = options.modelNumber
    this.assets = []
    this.lock = options.lock || false
    this.connectable = true
    this.imgLoaded = false
    this.imgLoading = false
    this.subscriptions = []
    if (isLiveObject) {
      const userStore = useUserStore()
      const observable = liveQuery(async () => await appConfig.DB!.articles.where({ CatalogCode: this.catalogCode ? this.catalogCode : userStore.activeCatalog?.CatalogCode, ModelNumber: this.modelNumber }).toArray())
      const subscription = observable.subscribe((modelArticles) => {
        // As discussed with Andre we dont need to set article again when we first time create the object as already we are loading the article
        if (!this.isLoadingFirstTime) {
          if (modelArticles && modelArticles.length && modelArticles.some(article => article.Status === 1)) {
            if (this.opacity !== 1) {
              this.setProp('opacity', { opacity: 1 }, true)
            }
          }
          else if (this.opacity !== 0.5) {
            this.setProp('opacity', { opacity: 0.5 }, true)
          }
        }
        else {
          this.isLoadingFirstTime = false
        }
      })
      this.subscriptions.push(subscription)
    }

    this.setLock(this.lock)

    this.stateProperties = this.stateProperties?.concat(['lock'])

    this.on('selected', () => {
      this.selected = true
    })

    this.on('deselected', () => {
      this.selected = false
    })

    this.on('removed', () => {
      // console.log('unsubscribing from model image observables')
      this.subscriptions.forEach((subscription) => {
        if (subscription && !subscription.closed) {
          subscription.unsubscribe()
        }
      })
    })
  }

  setProp(prop: string, value: any, ignoreHistory: boolean = false) {
    switch (prop) {
      case 'scale':
        if (value.scale <= 100 && this.width && this.height) {
          const scale = value.scale / 100
          this.set('scaleX', scale)
          this.set('scaleY', scale)
        }
        break
      case 'lock':
        this.setLock(value.lock)
        break
      case 'opacity':
        this.set('opacity', value.opacity)
        break
      default:
        console.warn('attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this, ignoreHistory })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'scale':
        result.scale = Math.round((this.scaleX || 1) * 100)
        break
      case 'lock':
        result.lock = this.lock
        break
      default:
        console.warn('attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  highlight() {
    this.highlighted = true
    this.set('stroke', '#2196f3')
    this.set('strokeWidth', 4)
    this.set('strokeDashArray', [20])
    this.canvas?.requestRenderAll()
  }

  dim() {
    this.highlighted = false
    this.set('stroke', undefined)
    this.set('strokeWidth', 0)
    this.set('strokeDashArray', undefined)
    this.canvas?.requestRenderAll()
  }

  midIntersectsWithObject(other: fabric.Object, absolute?: boolean, calculate?: boolean) {
    const points1 = this.getCoords(absolute, calculate)
    const points2 = other.getCoords(absolute, calculate)

    const xDelta = (points1[1].x - points1[0].x) / 4
    const yDelta = (points1[2].y - points1[0].y) / 4
    const increaseInX = points1[0].x + xDelta
    const increaseInY = points1[0].y + yDelta

    for (let i = 0; i < points1.length; i++) {
      const point = points1[i]
      point.x = (point.x >= increaseInX) ? (point.x - xDelta) : (point.x + xDelta)
      point.y = (point.y >= increaseInY) ? (point.y - yDelta) : (point.y + yDelta)
    }

    const intersection = fabric.Intersection.intersectPolygonPolygon(points1, points2)

    return intersection.status === 'Intersection'
      || other.isContainedWithinObject(this, absolute, calculate)
      || this.isContainedWithinObject(other, absolute, calculate)
  }

  setLock(lock: boolean) {
    this.set('lock', lock)
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockRotation', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
  }

  override toObject(propertiesToInclude?: string[]) {
    const props = propertiesToInclude || []
    props.push('id', 'catalogCode', 'modelNumber', 'lock')
    return super.toObject(props)
  }

  static fromObject(object: WbModelImage, callback?: Function) {
    this.loadModelImageByModelNumber(object.modelNumber, object.catalogCode, object.width || 500, object.height || 500, object).then((v) => { callback && callback(v) })
  }

  static async loadModelImageByModelNumber(modelNumber: string, catalogCode: number, width: number, height: number, options: IWbModelImageOptions) {
    const userStore = useUserStore()
    if (!userStore || (!userStore.activeCatalog && !userStore.linkedCatalogDetails[catalogCode])) {
      console.warn('Unable to load model image by modelNumber')
      return null
    }
    const modelArticles = await appConfig.DB!.articles.where({ CatalogCode: catalogCode, Status: 1, ModelNumber: modelNumber }).sortBy('ArticleNumber')
    if (modelArticles.length) {
      return await this.loadModelImage(modelArticles[0], width, height, options)
    }
    else {
      console.warn('Failed to load model image by modelNumber')
      return null
    }
  }

  static loadModelImage(article: Article, width: number, height: number, options?: IWbModelImageOptions) {
    return new Promise<WbModelImage>((resolve) => {
      fabric.util.loadImage(pngLoading, (img) => {
        const userStore = useUserStore()
        const currentOptions = Object.assign((options || { width, height }), { catalogCode: article.CatalogCode, articleId: article.Id, modelNumber: article.ModelNumber })
        const modelImageObject = new WbModelImage(img, currentOptions)
        modelImageObject.articleId = article.Id
        const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog
        modelImageObject.imgLoading = true
        getArticleAssets(catalogDetails.DuneContext, catalogDetails.ContextKey, article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList)
          .then((assets) => {
            modelImageObject.assets = assets
            modelImageObject.canvas?.requestRenderAll()
          })
          .finally(() => {
            modelImageObject.imgLoaded = false
            modelImageObject.imgLoading = false
          })
        resolve(modelImageObject)
      })
    })
  }

  static async getJsonObject(article: MyArticle, width: number, height: number, scaleValue, opt?: IWbModelImageOptions) {
    const userStore = useUserStore()
    const o = Object.assign((opt || { width, height }), { catalogCode: article.CatalogCode, articleId: article.Id, modelNumber: article.ModelNumber })
    const catalogDetails = userStore.linkedCatalogDetails[article.CatalogCode] || userStore.activeCatalog

    const obj = new WbModelImage('', o, false).toObject()
    if (scaleValue.scale <= 100 && width && height) {
      const scale = scaleValue.scale / 100
      obj.scaleX = scale
      obj.scaleY = scale
    }
    if (article._Assets && article._Assets.length > 0 && catalogDetails) {
      obj.src = `${appConfig.AssetsUrl}/assets/content/${article._Assets[0].StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    obj.height = height
    obj.width = width
    return obj
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    super._render(ctx)
    if (!this.imgLoaded && !this.imgLoading) {
      if (this.assets && this.assets.length > 0) {
        this.imgLoading = true
        this.setSrc(WbModelImage.getImageSrc(this.catalogCode, this.width || 500, this.height || 500, this.assets), (img, error) => {
          if (error) {
            this.setSrc(pngNoImage, () => this.canvas?.requestRenderAll())
            console.warn('unable to load WbModelImage', this, error)
          }
          else {
            this.canvas?.requestRenderAll()
          }
          this.imgLoaded = true
          this.imgLoading = false
        })
      }
      else {
        if (this.getSrc() !== pngNoImage) {
          this.setSrc(pngNoImage, () => this.canvas?.requestRenderAll())
        }
      }
    }
    if (this.selected && this.modelNumber) {
      // const userStore = useUserStore()
      ctx.font = '50px Helvetica'
      const measure = ctx.measureText(this.modelNumber)
      // let seasonData = userStore.activeCatalog?.CatalogCode === this.article.CatalogCode
      //   ? userStore.activeCatalog.Season
      //   : userStore.linkedCatalogDetails[this.article.CatalogCode].Season
      // const seasonDataMeasure = ctx.measureText(seasonData)
      ctx.fillStyle = '#1484f8'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, measure.width, 60)
      // ctx.fillRect(this.width! / 2 - seasonDataMeasure.width, -this.height! / 2, seasonDataMeasure.width, 60)
      ctx.fillStyle = '#FFFFFF'
      ctx.fillText(this.modelNumber, -this.width! / 2, -this.height! / 2 + 50)
      // ctx.fillText(seasonData, this.width! / 2 - seasonDataMeasure.width, -this.height! / 2 + 50)
    }
  }

  static getImageSrc(catalogCode: number, width: number, height: number, assets?: DuneAsset[]) {
    let src = pngNoImage
    const userStore = useUserStore()
    const catalogDetails = userStore.linkedCatalogDetails[catalogCode] || userStore.activeCatalog
    if (userStore && catalogDetails && assets && assets.length > 0) {
      // in case of modelImage alway show the first image of the model article
      const asset = assets[0]
      src = `${appConfig.AssetsUrl}/assets/content/${asset.StorageFile}?ContextKey=${encodeURIComponent(catalogDetails.ContextKey)}&f=webp&w=${width}&h=${height}&trim=true`
    }
    return src
  }
}

const f: any = fabric
f.WbModelImage = WbModelImage
