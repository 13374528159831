<template>
  <div class="flex h-12 p-2 rounded-lg bg-grey-light shadow-toolbar">
    <div
      v-for="user in users" :key="user.UserName" v-tooltip="{ text: user.UserName, theme: { placement: 'left' } }"
      class="w-8 h-8 text-lg leading-6 text-center text-white rounded-full border-3 border-grey"
      :style="{ backgroundColor: user.Color }" v-text="getInitials(user)"
    />
    <div
      v-if="userStore.userProfile" v-tooltip="{ text: userStore.userProfile.userName, theme: { placement: 'left' } }"
      class="w-8 h-8 text-lg leading-6 text-center text-white rounded-full bg-primary border-3 border-grey"
      v-text="userStore.userProfile.initials"
    />
    <tx-button
      v-tooltip="t('whiteboard.inviteUsers')" class="mr-1" :class="{ 'pl-1': users.length }" type="icon" :loading="loading"
      faicon="fa-light fa-share-from-square" @click="doInvite"
    />

    <!-- Share User Dialog -->
    <share-user-dialog ref="refShareUserDialog" v-model="selectedItems" @share="onShare" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import type { IPresenceUser } from '../composables/presence'
import type Whiteboard from '../services/whiteboard'
import TxButton from '@/shared/components/TxButton.vue'
import type { IAccessibleItem } from '@/shared/components/ShareUserDialog.vue'
import ShareUserDialog from '@/shared/components/ShareUserDialog.vue'
import type { SharedUserGroupModel, SharedUserModel } from '@/api/t1/model/whiteboardModel'
import { getWhiteboardDetails, shareWhiteboard } from '@/api/t1/whiteboard'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

const props = defineProps<{
  users: IPresenceUser[]
  whiteboardId: number
  whiteboard?: Whiteboard
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()

const loading = ref(false)
const refShareUserDialog = ref<InstanceType<typeof ShareUserDialog>>()
const selectedItems = ref<IAccessibleItem[]>([])

function getInitials(user: IPresenceUser) {
  return user.FirstName.substring(0, 1) + user.LastName.substring(0, 1)
}

function doInvite() {
  selectedItems.value = []
  if (userStore.activeCatalog && props.whiteboardId) {
    loading.value = true
    getWhiteboardDetails(userStore.activeCatalog.CatalogCode, props.whiteboardId)
      .then((res) => {
        res.data.SharedUsersGroups.forEach((userGroup) => {
          selectedItems.value.push({
            id: userGroup.GroupId,
            type: 'group',
            name: userGroup.Name,
            subTitle: userGroup.AccountName,
          })
        })
        res.data.SharedUsers.forEach((user) => {
          selectedItems.value.push({
            id: user.UserId,
            type: 'user',
            name: `${user.FirstName} ${user.LastName}`,
            subTitle: user.UserName,
          })
        })
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        loading.value = false
        refShareUserDialog.value?.open()
      })
  }
  else {
    refShareUserDialog.value?.open()
  }
}

function onShare(items: IAccessibleItem[]) {
  if (userStore.activeCatalog && refShareUserDialog.value && props.whiteboardId) {
    const refShareUserDialogInstance = refShareUserDialog.value
    refShareUserDialogInstance.loading = true
    const sharedUsers: SharedUserModel[] = []
    const sharedUserGroups: SharedUserGroupModel[] = []
    // TODO: Setting read and write to 1 for now.
    items.forEach((item) => {
      if (item.type === 'group') {
        sharedUserGroups.push({
          GroupId: item.id,
          Read: 1,
          Write: 1,
        })
      }
      else if (item.type === 'user') {
        sharedUsers.push({
          UserId: item.id,
          Read: 1,
          Write: 1,
        })
      }
    })
    shareWhiteboard(userStore.activeCatalog.CatalogCode, props.whiteboardId, sharedUsers, sharedUserGroups)
      .then(async () => {
        notificationStore.addNotification({ message: t('shareUserDialog.shareSuccessfully'), type: 'Success' })
        await appConfig.DB!.whiteboards.update([userStore.activeCatalog!.CatalogCode, props.whiteboardId], { SharedUsers: sharedUsers, SharedUsersGroups: sharedUserGroups })
        if (props.whiteboard) {
          props.whiteboard.updateBoardMentionableList(items)
        }
        // userStore.doLoadData(['Whiteboards'])
      })
      .catch((e) => {
        console.error(e)
        refShareUserDialogInstance.errorMessage = t('general.unexpectedError')
      })
      .finally(() => {
        refShareUserDialogInstance.loading = false
        refShareUserDialogInstance.close()
      })
  }
}
</script>
