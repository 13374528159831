<template>
  <tx-dialog
    v-model="visible" :title="t('savedViewDialog.title')" width="450px" show-ok-cancel
    :ok-state="v$.$invalid || !isColumnOrRowSelected ? 'disabled' : 'enabled'" :loading="loading"
    @ok="doCreate()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div class="w-full my-2">
          <tx-input
            v-model.trim="form.name" :label="t('savedViewDialog.name')" required :errors="v$.name.$errors"
            @blur="v$.name.$touch"
          />
        </div>
        <div v-if="userStore.userProfile.accountDetails.AccountTypeId === 1" class="w-full my-2">
          <tx-switch v-model="form.isCatalogLevel" :label="t('savedViewDialog.saveAsCatalogWide')" />
        </div>
        <div class="w-full my-2">
          <tx-select
            v-model="form.parentFolder" :label="t('savedViewDialog.selectFolder')" :data="savedViewFolders"
            value-prop="FolderName" display-prop="DisplayName" :errors="v$.parentFolder.$errors"
            required @blur="v$.parentFolder.$touch"
          />
        </div>
        <div class="w-full my-2">
          <tx-input
            v-model.trim="form.newFolder" :label="t('savedViewDialog.newFolder')" :errors="v$.newFolder.$errors"
            @blur="v$.newFolder.$touch"
          />
        </div>
        <div class="w-full my-2">
          <tx-alert :show="!isColumnOrRowSelected" type="error" :text="t('savedViewDialog.validations.columnOrRowNotSelected')" />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { clone } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { savedViewConstants } from '@/models/constants'
import type SavedView from '@/models/savedView'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'create', name: string, sortOrderList: number[], isCatalogLevel: boolean): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const form = reactive({
  name: '',
  isCatalogLevel: false,
  parentFolder: 'default',
  newFolder: '',
})
const savedViewsList = ref<SavedView[]>([])
const localColumnDivider = ref<string>()
const localRowDivider = ref<string>()

const savedViewFolders = computed(() => {
  const set = new Set()
  const result: { FolderName: string, DisplayName: string, SortOrderList: number[] }[] = []
  let hasDefault = false
  savedViewsList.value.forEach((savedView) => {
    if (form.isCatalogLevel === Boolean(savedView.IsCatalogLevel) && utils.isValidStringValue(savedView.FolderName)) {
      if (savedView.FolderName.includes('default')) {
        hasDefault = true
      }
      if (!set.has(savedView.FolderName)) {
        result.push({ FolderName: savedView.FolderName, DisplayName: savedView.FolderName.replace(savedViewConstants.folderPathSeparator, ' > '), SortOrderList: savedView.SortOrderList })
        set.add(savedView.FolderName)
      }
    }
  })
  if (!hasDefault) {
    result.push({ FolderName: 'default', DisplayName: 'default', SortOrderList: [1, 1] })
  }
  const root = [{ FolderName: 'Root', DisplayName: 'Root', SortOrderList: [1] as number[] }]
  return root.concat(result.sort((a, b) => a.FolderName.toLowerCase().localeCompare(b.FolderName.toLowerCase())))
})

const indexedSavedViewFolder = computed(() => savedViewFolders.value.reduce((acc, curr) => {
  acc[curr.FolderName.toLowerCase()] = curr
  return acc
}, {} as Record<string, { FolderName: string, SortOrderList: number[] }>))

const getSavedViewName = computed(() => {
  let savedViewName = ''
  if (indexedSavedViewFolder.value[form.parentFolder.toLowerCase()]) {
    savedViewName = `${indexedSavedViewFolder.value[form.parentFolder.toLowerCase()].FolderName}${savedViewConstants.folderPathSeparator}`
  }
  if (utils.isValidStringValue(form.newFolder)) {
    savedViewName += `${form.newFolder}${savedViewConstants.folderPathSeparator}`
  }
  savedViewName += form.name
  return savedViewName
})

const indexedSavedViews = computed(() => savedViewsList.value.reduce((acc, curr) => {
  if (form.isCatalogLevel === Boolean(curr.IsCatalogLevel)) {
    acc[curr.Name.toLowerCase()] = curr
  }
  return acc
}, {} as Record<string, SavedView>))

const rules = computed(() => {
  const result = {
    name: { required: withI18nMessage(required), minLength: withI18nMessage(minLength(1)), maxLength: withI18nMessage(maxLength(250)) },
    parentFolder: { required: withI18nMessage(required) },
    newFolder: { maxLength: withI18nMessage(maxLength(250)) },
  }
  if (utils.isValidStringValue(form.name)) {
    const isSavedViewNameExist = () => !utils.isDefined(indexedSavedViews.value[getSavedViewName.value])
    // eslint-disable-next-line dot-notation
    result.name['isSavedViewNameExist'] = helpers.withMessage(t('savedViewDialog.validations.savedViewNameExist'), isSavedViewNameExist)
  }
  if (form.parentFolder === 'Root') {
    // eslint-disable-next-line dot-notation
    result.newFolder['required'] = withI18nMessage(required)
  }
  return result
})
const v$ = useVuelidate(rules, form)

const isColumnOrRowSelected = computed(() => utils.isValidStringValue(localColumnDivider.value) || utils.isValidStringValue(localRowDivider.value))

async function showDialog(columnDivider?: string, rowDivider?: string) {
  reset()
  localColumnDivider.value = columnDivider
  localRowDivider.value = rowDivider

  if (userStore.activeCatalog) {
    savedViewsList.value = await appConfig.DB!.savedViews.where({ CatalogCode: userStore.activeCatalog.CatalogCode }).toArray()
  }
}

// function doCancel() {
//   visible.value = false
//   emit('cancel')
// }

async function doCreate() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }

  loading.value = true
  let folderSortOrderList: number[] = []
  const selectedParentFolder = indexedSavedViewFolder.value[form.parentFolder.toLowerCase()]
  if (selectedParentFolder) {
    folderSortOrderList = clone(selectedParentFolder.SortOrderList)
    if (utils.isValidStringValue(form.newFolder) && selectedParentFolder.FolderName !== 'Root') {
      folderSortOrderList.push(0)
    }
  }

  emit('create', getSavedViewName.value, folderSortOrderList, form.isCatalogLevel)
}

function reset() {
  loading.value = false
  visible.value = true
  form.name = ''
  form.isCatalogLevel = false
  form.parentFolder = 'default'
  form.newFolder = ''
}

defineExpose({
  showDialog,
  loading,
  visible,
  errorMessage,
})
</script>
