import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'
import Admin from '@/modules/admin/Admin.vue'
import Auth from '@/services/auth'
import Browse from '@/modules/browse/Browse.vue'
import CatalogBase from '@/modules/catalogBase/CatalogBase.vue'
import CatalogsList from '@/modules/catalogsList/CatalogsList.vue'
import LoginView from '@/modules/auth/Login.vue'
import Net from '@/services/net'
import SetPassword from '@/modules/auth/SetPassword.vue'
import Merch from '@/modules/merch/Merch.vue'
import Whiteboard from '@/modules/whiteboard/Whiteboard.vue'
import WhiteboardsList from '@/modules/whiteboard/WhiteboardsList.vue'
import Orders from '@/modules/orders/Orders.vue'
import { useNotificationStore } from '@/store/notification'
import Forbidden from '@/modules/admin/errors/Forbidden.vue'
import Articles from '@/modules/admin/catalogData/articles/Articles.vue'
import { privileges } from '@/models/constants'

const { t } = i18n.global

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'CatalogsList' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: SetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/catalogs',
    name: 'CatalogsList',
    component: CatalogsList,
    meta: { requiresAuth: true },
  },
  {
    path: '/catalogs/:catalogCode',
    name: 'OpenCatalog',
    component: CatalogsList,
    meta: { requiresAuth: true },
  },
  {
    path: '/catalogs/:catalogCode/c/:customerId/w/:wholesalePG?/r/:retailPG?/o/:outletPG?',
    component: CatalogBase,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'browse',
        name: 'Catalog',
        component: Browse,
        children: [
          {
            path: 'a/:articleId/:objectId?/:linkedCatalogCode?',
            name: 'CatalogArticleDetails',
            component: Browse,
          },
        ],
      },
      {
        path: 'merch',
        name: 'Merch',
        component: Merch,
        children: [
          {
            path: 'a/:articleId/:objectId?/:linkedCatalogCode?',
            name: 'MerchArticleDetails',
            component: Merch,
          },
        ],
      },
      {
        path: 'whiteboards',
        name: 'Whiteboards',
        component: WhiteboardsList,
      },
      {
        path: 'whiteboards/:whiteboardId',
        name: 'WhiteboardDetails',
        component: Whiteboard,
        children: [
          {
            path: 'a/:articleId/:objectId?/:linkedCatalogCode?',
            name: 'WhiteboardDetailsArticleDetails',
            component: Whiteboard,
          },
        ],
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
        meta: { requiresAuth: true },
      },
      {
        path: 'orders/:orderId',
        name: 'Orderlines',
        component: Orders,
        meta: { requiresAuth: true },
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admin,
        children: [
          {
            path: '403',
            name: 'AdminForbidden',
            component: Forbidden,
          },
          {
            path: 'articles',
            name: 'AdminArticles',
            component: Articles,
            meta: { privileges: privileges.admin.nav.articles },
          },
        ],
        redirect: { name: 'AdminArticles' },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== from.name && to.matched.some(record => record.meta.requiresAuth)) {
    if (!Auth.hasValidToken()) {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
        },
      })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
})

Net.onError((source, err) => {
  const notificationStore = useNotificationStore()

  if (axios.isAxiosError(err) && err.response) {
    if (err.response.status === 401) {
      Auth.setLocalToken('')
      if (router.currentRoute.value.name !== 'Login') {
        router.push({ name: 'Login' })
      }
    }
    else if (err.response.status === 403) {
      const url = (err.config) ? `${(err.config.method || '').toUpperCase()} ${err.config.url}` : ''
      notificationStore.addNotification({ message: t('notifications.privilegeError'), type: 'Alert', url, actions: ['ShowDetails', 'Support'] })
    }
    else {
      notificationStore.addNotification({ message: t('notifications.networkError'), type: 'Alert', details: err.toJSON().toString(), actions: ['ShowDetails', 'Support'] })
    }
  }
})

export default router
