<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header
      :show-header="showHeader" class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header"
      :title="t('articleDetails.actions.editRetailWindow')"
    />

    <!-- BODY -->
    <div class="px-10 mt-4 alerts">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    </div>
    <!-- FORM -->
    <div class="h-full">
      <div class="grid w-full grid-cols-2 px-10 overflow-auto gap-x-6">
        <div v-for="(attribute) in visibleAttributes" :key="attribute.SystemName" class="mb-5 last-of-type:mb-4">
          <form-editor
            v-model="form[attribute.SystemName]"
            :attribute="attribute"
            :form="form"
            :required="attribute.IsRequired"
            :errors="v$[attribute.SystemName]?.$errors"
            :show-label="true"
            :clearable="true"
            :sort-list="false"
            @blur="v$[attribute.SystemName]?.$touch"
            @change="onRetailIntroChange(attribute.SystemName)"
          />
        </div>
        <tx-alert :show="showWarning" type="warning" :text="warningMessage" />
      </div>
    </div>

    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-type="removeShipmentWindow ? 'danger' : 'confirm'" :primary-text="removeShipmentWindow ? t('general.remove') : t('general.update')"
      :primary-disabled="(removeShipmentWindow && form.retailIntroMonthId != null) || (!removeShipmentWindow && (form.retailIntroMonthId == null || form.retailExitMonthId == null))" :primary-loading="loading"
      :secondary-disabled="loading"
      @primary-click="onUpdate" @secondary-click="onCancel"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { isArray, uniqBy } from 'lodash-es'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import type MyArticle from '@/models/myArticle'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import useShipmentWindow from '@/shared/composables/shipmentWindow'
import utils from '@/services/utils'
import { updateArticleShipmentWindowRange } from '@/api/t1/article'
import { useUserStore } from '@/store/userData'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import FormEditor from '@/shared/components/FormEditor.vue'
import { appConstants } from '@/models/constants'
import { AttributeType } from '@/models/catalogAttribute'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'

interface IFormItem {
  retailIntroMonthId: number | null | undefined
  retailExitMonthId: number | null | undefined
  ShipmentWindowRangeId: number
}

// PROPS
interface IProps {
  showHeader?: boolean
  articles: MyArticle[]
  isModel?: boolean
}
const props = withDefaults(defineProps<IProps>(), { showHeader: true, isModel: false })

// EMITS
const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'updated', articles: MyArticle[] | Article[]): void
}>()

// COMPOSABLES
const { t } = useI18n()
const userStore = useUserStore()
const { getShipmentWindows, getSortRetailIntroExitMonths } = useShipmentWindow()
const { errorMessage, hasError } = useErrorMessage()

const form = reactive<IFormItem>({
  retailIntroMonthId: null,
  retailExitMonthId: null,
  ShipmentWindowRangeId: 0,
})

// DATA
const loading = ref(false)
const showWarning = ref(false)
const warningMessage = ref('')
const availableSortedRetailExitMonths = ref< Array<CatalogShipmentWindowRange>>([])
let selectedShipmentWindow: Record<string, any> = {}
const articles = ref<MyArticle[] | Article[]>([])

// COMPUTED
const availableShipmentWindows = computed<Array<CatalogShipmentWindowRange>>(() => getShipmentWindows(articles.value, userStore.activeCatalog!))
const availableSortedRetailIntroMonths = computed<Array<CatalogShipmentWindowRange>>(() => getSortRetailIntroExitMonths(uniqBy(availableShipmentWindows.value, 'RetailIntroMonth') as CatalogShipmentWindowRange[], 'RetailIntroMonth') as CatalogShipmentWindowRange[])
const removeShipmentWindow = computed<boolean>(() => !utils.isDefined(form.retailIntroMonthId) && !utils.isDefined(form.retailExitMonthId)
  && articles.value.some(article => article.ShipmentWindowRangeId != null && article.ShipmentWindowRangeId.toString().trim().length),
)

const visibleAttributes = computed(() => {
  const visibleAttributes: IFormEditorAttribute[] = []
  const retailIntroMonthField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'retailIntroMonthId',
    DisplayName: t('updateArticleRetailWindow.retailIntroMonth'),
    Creatable: true,
    AttributeType: AttributeType.Int,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: availableSortedRetailIntroMonths.value,
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'RetailIntroMonth',
  })
  visibleAttributes.push(retailIntroMonthField)
  const retailExitMonthField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'retailExitMonthId',
    DisplayName: t('updateArticleRetailWindow.retailExitMonth'),
    Creatable: true,
    AttributeType: AttributeType.Int,
    ReadOnly: false,
    IsRequired: true,
    DropDownData: availableSortedRetailExitMonths.value,
    DropDownValueProp: 'Id',
    DropDownValueDisplayProp: 'RetailExitMonth',
  })
  visibleAttributes.push(retailExitMonthField)
  return visibleAttributes
})

const rules = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
  })
  return result
})
const v$ = useVuelidate(rules, form)

// WATCH
watch(() => props.articles, init)

// LIFECYCLE HOOKS
onMounted(() => init())

// METHODS
async function init() {
  availableSortedRetailExitMonths.value = []
  form.retailIntroMonthId = null
  form.retailExitMonthId = null
  loading.value = false
  errorMessage.value = ''
  showWarning.value = false
  warningMessage.value = ''
  if (props.isModel) {
    const queryCriterion: Array<[number, number, string]> = [[userStore.activeCatalog!.CatalogCode, 1, props.articles[0].ModelNumber]]
    for (let i = 1; i < props.articles.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, 1, props.articles[i].ModelNumber])
    }
    const res = await appConfig.DB!.articles.where('[CatalogCode+Status+ModelNumber]').anyOf(queryCriterion).toArray()
    if (res && isArray(res) && res[0]) {
      articles.value = res
    }
  }
  else {
    articles.value = props.articles
  }
  if (articles.value.length) {
    prefillForm()
  }
}

function prefillForm() {
  // pre fill the form
  let haveCommonIntroMonths = true
  let haveCommonExitMonths = true
  const commonRetailIntroMonth = articles.value[0].RetailIntroMonth
  const commonRetailExitMonth = articles.value[0].RetailExitMonth
  for (let i = 1; i < articles.value.length; i++) {
    if (!utils.haveEqualStringValue(commonRetailIntroMonth, articles.value[i].RetailIntroMonth)) {
      haveCommonIntroMonths = false
    }
    if (!utils.haveEqualStringValue(commonRetailExitMonth, articles.value[i].RetailExitMonth)) {
      haveCommonExitMonths = false
    }
    if (!haveCommonIntroMonths) {
      break
    }
  }
  if (haveCommonIntroMonths) {
    const shipmentWindowsMatchedWithCommonIntroDate = availableShipmentWindows.value.filter(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.RetailIntroMonth, commonRetailIntroMonth))
    if (shipmentWindowsMatchedWithCommonIntroDate.length) {
      form.retailIntroMonthId = shipmentWindowsMatchedWithCommonIntroDate[0].Id
      availableSortedRetailExitMonths.value = getSortRetailIntroExitMonths(uniqBy(shipmentWindowsMatchedWithCommonIntroDate, 'RetailExitMonth') as CatalogShipmentWindowRange[], 'RetailExitMonth') as CatalogShipmentWindowRange[]
      if (haveCommonExitMonths) {
        const shipmentWindowForExitMonth = shipmentWindowsMatchedWithCommonIntroDate.find(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.RetailExitMonth, commonRetailExitMonth))
        if (utils.isDefined(shipmentWindowForExitMonth)) {
          form.retailExitMonthId = shipmentWindowForExitMonth.Id
        }
      }
    }
  }

  // when update for 1 article if retail intro and exit exist on article but could not find valid shipment window to assign show error (like old implementation)
  if (articles.value.length === 1 && utils.isDefined(articles.value[0].RetailIntroMonth) && utils.isDefined(articles.value[0].RetailExitMonth)
    && (form.retailIntroMonthId == null || form.retailExitMonthId == null)) {
    showWarning.value = true
    warningMessage.value = t('updateArticleRetailWindow.assignedRetailWindowIsNotValid', { intro: articles.value[0].RetailIntroMonth, exit: articles.value[0].RetailExitMonth })
  }
}

function onRetailIntroChange(context: string) {
  v$[context]?.$touch()
  if (context === 'retailIntroMonthId') {
    form.retailExitMonthId = null
    const selectedRetailIntroMonth = availableShipmentWindows.value.filter(s => s.Id === form.retailIntroMonthId)
    const availableRetailExitMonths = availableShipmentWindows.value.filter(availableShipmentWindow => utils.haveEqualStringValue(availableShipmentWindow.RetailIntroMonth, selectedRetailIntroMonth[0]?.RetailIntroMonth))
    availableSortedRetailExitMonths.value = getSortRetailIntroExitMonths(uniqBy(availableRetailExitMonths, 'RetailExitMonth') as CatalogShipmentWindowRange[], 'RetailExitMonth') as CatalogShipmentWindowRange[]
  }
  else {
    const selectedRetailIntroMonth = availableShipmentWindows.value.filter(s => s.Id === form.retailIntroMonthId)
    const selectedRetailExitMonth = availableShipmentWindows.value.filter(s => s.Id === form.retailExitMonthId)
    const result: CatalogShipmentWindowRange[] = availableShipmentWindows.value.filter(s => s.RetailIntroMonth === selectedRetailIntroMonth[0]?.RetailIntroMonth && s.RetailExitMonth === selectedRetailExitMonth[0]?.RetailExitMonth)
    if (result) {
      form.ShipmentWindowRangeId = result[0].Id
      selectedShipmentWindow = result[0]
    }
  }
}

function onCancel() {
  emit('cancel')
}

async function onUpdate() {
  if (userStore.activeCatalog && articles.value.length > 0) {
    loading.value = true
    const promises: Promise<any>[] = []
    const updatedArticles: (Article | MyArticle)[] = []
    const failedArticles: string[] = []
    articles.value.forEach((article) => {
      promises.push(
        updateArticleShipmentWindowRange(userStore.activeCatalog!.CatalogCode, article.Id, form.ShipmentWindowRangeId || '')
          .then(() => {
            if (utils.isDefined(selectedShipmentWindow)) {
              article.ShipmentWindowRangeId = selectedShipmentWindow.Id
              article.ShipmentStartDate = selectedShipmentWindow.ShipmentStartDate
              article.ShipmentEndDate = selectedShipmentWindow.ShipmentEndDate
              article.RetailIntroMonth = selectedShipmentWindow.RetailIntroMonth
              article.RetailExitMonth = selectedShipmentWindow.RetailExitMonth
              article.Period = selectedShipmentWindow.Period
            }
            else {
              article.ShipmentWindowRangeId = null
              article.ShipmentStartDate = null
              article.ShipmentEndDate = null
              article.RetailIntroMonth = ''
              article.RetailExitMonth = ''
              article.Period = ''
            }
            updatedArticles.push(article)
          })
          .catch(() => failedArticles.push(article.ArticleNumber)),
      )
    })
    Promise.all(promises)
      .then(async () => {
        if (updatedArticles.length) {
          await userStore.doLoadData(['Articles'])
          emit('updated', updatedArticles)
        }
        if (failedArticles.length) {
          errorMessage.value = t('updateArticleRetailWindow.failedSaveRetailWindows', { articles: failedArticles.join(', ') })
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => loading.value = false)
  }
}
</script>
