import { isArray } from 'lodash-es'
import MbArticleImage from './articleImage'
import MbTextBox from './textBox'
import MbArticleDetails from './articleDetails'
import MbImage from './image'
import MbRectangle from './rectangle'
import MbCircle from './circle'
import MbTriangle from './triangle'
import MbLine from './line'
import MbGroup from './group'
import type { sharedFolderModel, sharedUserGroupModel, sharedUserModel } from '@/api/t1/model/merchModel'
import { getSlideDetails } from '@/api/t1/merch'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'
import type UserProfile from '@/models/userProfile'
import appConfig from '@/services/appConfig'

class MerchSlide {
  Id: number
  BackgroundColor: string
  CatalogCode: number
  CreatedByEmail: string
  CreatedByName: string
  CreatedByUserName: string
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date
  FolderId: string
  FolderName: string
  FolderIdSortOrder: number
  ForceUpdate: number
  ImageSize: number
  SlideId: string
  SlideName: string
  SortOrder: number
  SlideSize: string
  SlideUpdatedDate: Date
  SlideCreatedDate: Date
  CustomerId: number | null
  LinkedArticles: number[]
  ArticleList: number[]
  FlagIndicators?: Record<string, boolean>
  SharedFolders: sharedFolderModel[]
  SharedUsers: sharedUserModel[]
  SharedUsersGroups: sharedUserGroupModel[]
  isDownloaded: boolean
  isDirty: boolean
  isSyncedWithServer: boolean // it indicates slide created locally only not yet synced with server , if slide is deleted no need to delete from server
  objects: any[]
  isSortOrderChanged: boolean
  isCanvasFullyRendered: boolean
  unavailableArticleCount: number
  addARIndicator: boolean = false
  addGHLIndicator: boolean = false
  addBAndTIndicator: boolean = false

  constructor(obj: any, formData?: {
    activeCatalogCode: number
    folderId: string
    folderName: string
    folderSortOrder: number
    slideName: string
    userProfile: UserProfile
    customerId: number | null
    slideSize: string
    imageScaleFactor: number
    sortOrder: number
    templateObjects: any[]
    articleList: number[]
  }) {
    this.isSortOrderChanged = false
    this.isCanvasFullyRendered = false
    this.unavailableArticleCount = 0
    if (obj) {
      this.Id = obj.Id
      this.CatalogCode = obj.CatalogCode
      this.BackgroundColor = obj.BackgroundColor
      this.FolderId = obj.FolderId
      this.FolderIdSortOrder = obj.FolderIdSortOrder
      this.FolderName = obj.FolderName
      this.ForceUpdate = obj.ForceUpdate
      this.SlideId = obj.SlideId
      this.SlideName = obj.SlideName
      this.ImageSize = obj.ImageSize || merchConstants.slideImageDefaultScaleFactors.large
      this.SortOrder = obj.SortOrder

      this.SlideSize = obj.SlideSize
      this.SlideUpdatedDate = obj.SlideUpdatedDate
      this.SlideCreatedDate = obj.SlideCreatedDate
      this.LinkedArticles = obj.LinkedArticles
      this.CustomerId = obj.CustomerId
      this.SharedFolders = obj.SharedFolders
      this.SharedUsers = obj.SharedUsers
      this.SharedUsersGroups = obj.SharedUsersGroups
      this.CreatedByEmail = obj.CreatedByEmail
      this.CreatedByName = obj.CreatedByName
      this.CreatedByUserName = obj.CreatedByUserName
      this.Status = obj.Status
      this.CreatedBy = obj.CreatedBy
      this.UpdatedBy = obj.UpdatedBy
      this.CreatedDate = new Date(obj.CreatedDate)
      this.UpdatedDate = new Date(obj.UpdatedDate)
      this.objects = []
      this.isDirty = false
      this.isDownloaded = false
      this.isSyncedWithServer = true
      try {
        this.ArticleList = obj.ArticleList ? JSON.parse(obj.ArticleList) : obj.ArticleList
      }
      catch (error) {
        console.error(`Invalid articlesList ${obj.SlideName} -> ${obj.ArticleList}`, error)
        this.ArticleList = []
      }
      try {
        this.FlagIndicators = JSON.parse(obj.FlagIndicators)

        this.addARIndicator = this.FlagIndicators?.ARIndicator ? this.FlagIndicators.ARIndicator : this.addARIndicator
        this.addGHLIndicator = this.FlagIndicators?.GHLIndicator ? this.FlagIndicators.GHLIndicator : this.addGHLIndicator
        this.addBAndTIndicator = this.FlagIndicators?.BAndTIndicator ? this.FlagIndicators.BAndTIndicator : this.addBAndTIndicator
      }
      catch (error) {
        console.log('unable to parse flagIndicators')
        this.FlagIndicators = {}
      }
    }
    else {
      const dateString = new Date()
      this.Id = -1
      this.CatalogCode = formData!.activeCatalogCode
      this.BackgroundColor = 'rgba(255, 255, 255, 1)'
      this.FolderId = formData!.folderId
      this.FolderIdSortOrder = formData!.folderSortOrder
      this.FolderName = formData!.folderName
      this.ForceUpdate = 0
      this.SlideId = utils.randomId()
      this.SlideName = formData!.slideName
      this.ImageSize = formData!.imageScaleFactor || merchConstants.slideImageDefaultScaleFactors.large
      this.SortOrder = formData!.sortOrder

      this.SlideSize = formData!.slideSize
      this.SlideUpdatedDate = dateString
      this.SlideCreatedDate = dateString
      this.LinkedArticles = []
      this.CustomerId = formData!.customerId
      this.SharedFolders = []
      this.SharedUsers = []
      this.SharedUsersGroups = []
      this.CreatedByEmail = formData!.userProfile.email
      this.CreatedByName = formData!.userProfile.firstName
      this.CreatedByUserName = formData!.userProfile.userName
      this.Status = 1
      this.CreatedBy = formData!.userProfile.id
      this.UpdatedBy = formData!.userProfile.id
      this.CreatedDate = dateString
      this.UpdatedDate = dateString
      this.objects = formData!.templateObjects
      this.ArticleList = formData!.articleList
      this.isDirty = true
      this.isDownloaded = true
      this.isSyncedWithServer = false
      // this.addARIndicator = addARIndicator
      // this.addGHLIndicator = addGHLIndicator
      // this.addBAndTIndicator = addBAndTIndicator
    }
  }

  async updateUnavailableArticleCount() {
    // using the articleslist update the  count after checking in db
    this.unavailableArticleCount = 0
    if (this.ArticleList.length !== 0) {
      const queryCriterion: Array<[number, number]> = []
      this.ArticleList.forEach((id) => {
        queryCriterion.push([+this.CatalogCode, id])
      })
      this.unavailableArticleCount = await appConfig.DB!.articles.where('[CatalogCode+Id]').anyOf(queryCriterion).filter(article => article.Status === 0).count()
    }
  }

  async getSlideData(catalogCode: number) {
    if (this.isDownloaded) {
      return Promise.resolve(this.objects)
    }
    else {
      return await getSlideDetails(catalogCode, this.SlideId).then((response) => {
        let parsedSlideData = JSON.parse(response.data.Content)
        if (typeof parsedSlideData === 'string') { // handle the case where user does not take the latest update and create slide and upload it in server which make the double stringify content
          parsedSlideData = JSON.parse(parsedSlideData)
        }
        this.objects = parsedSlideData.slidesData
        this.objects.forEach((object) => {
          if (object.type === merchConstants.objectTypes.articleImage) {
            if (object.articleId != null && !this.ArticleList.includes(object.articleId)) {
              this.ArticleList.push(object.articleId)
            }
          }
          else if (object.type === merchConstants.objectTypes.group) {
            const groupObjects = object.objects || object._objects
            groupObjects.forEach((groupedObject) => {
              if (groupedObject.type === merchConstants.objectTypes.articleImage && groupedObject.articleId != null && !this.ArticleList.includes(groupedObject.articleId)) {
                this.ArticleList.push(groupedObject.articleId)
              }
            })
          }
        })
        this.isDownloaded = true
        return parsedSlideData.slidesData
      }).catch(() => {
        return 'TxError'
      })
    }
  }

  async createObjects(objects: any[], showFavorites: boolean = false, groupSpecs?: Record<string, any>) {
    const promises: Promise<void | number>[] = []
    const indexesToBeRemoved: number[] = []
    const createdObjects: fabric.Object[] = Array(objects.length).fill({})
    for (let index = 0; index < objects.length; index++) {
      const obj = objects[index]
      let newObj: fabric.Object | fabric.Object[] | null = null
      // let resource
      switch (obj.type) {
        case 'articleImage':
          if (obj.articleId !== null && obj.articleId!.toString().trim() !== '') {
            newObj = await MbArticleImage.loadArticleImageById(obj.articleId, obj.objectId, obj, this.ImageSize, showFavorites)
            if (newObj) {
              await this.updateArticleList(obj.articleId)
            }
          }
          break

        case 'articleDetails':
          if (utils.isDefined(obj.articleId) && obj.customOptions.articleProps) {
            newObj = await MbArticleDetails.loadArticleDetailsById(obj.articleId!, obj.objectId, obj.isRequest, obj, this.ImageSize)
          }
          else {
            console.error('Invalid article details object', obj)
          }
          break

        case 'image':
          newObj = await MbImage.loadFromUrl(obj.data, obj)
          break

          // case 'resource':
          //   resource = merch.catalogData.resources.indexedResources[obj.resourceId]
          //   if(util.isDefined(resource)) {
          //     newObj = MerchObjects.Resource.loadResourceIcon(resource, obj)
          //   } else {
          //     //load the resource not available
          //     newObj = MerchObjects.Resource.loadUnavailableResource(obj)
          //   }
          //   break
        case 'rectangle':
          newObj = new MbRectangle(obj)
          break

        case 'triangle':
          newObj = new MbTriangle(obj)
          break

        case 'circle':
          newObj = new MbCircle(obj)
          break

        case 'line':
          newObj = new MbLine(obj)
          break

        case 'textbox':
          newObj = new MbTextBox(`${obj.text}`, obj)
          break

        case 'group': {
          const groupObjects = await this.createObjects(obj.objects, showFavorites, { left: obj.left, top: obj.top, angle: obj.angle, scaleX: obj.scaleX, scaleY: obj.scaleY, locked: obj.locked })
          if (groupObjects.length !== 0) {
            newObj = groupObjects[0]
          }
          break
        }

        // case 'hotspot':
        //   obj.selectable = merch.hotspotObjectsSelectable
        //   obj.visible = merch.showHotspots || merch.hotspotObjectsSelectable
        //   obj.locked = !merch.hotspotObjectsSelectable
        //   newObj = new MerchObjects.Hotspot(obj, false)
        //   break
        // case 'discussionOutline': // do not draw discussionOutline from slides object
        //   newObj = new MerchObjects.DiscussionOutline(obj)
        //   break
        default:
          // if(obj.type !== 'discussionOutline' && obj.type !== 'discussionIcon' ) {
          //   console.warn('Unknown canvas object', obj)
          // }
      }
      if (newObj == null) {
        indexesToBeRemoved.push(index)
      }
      else if (newObj instanceof Promise) {
        const p = newObj.then((oItm) => {
          createdObjects[index] = oItm
        })
        promises.push(p)
      }
      else if (!isArray(newObj)) {
        createdObjects[index] = newObj
      }
      else {
        console.warn('should never happen', newObj)
      }
    }
    return await Promise.all(promises)
      .then(() => {
        // remove empty objects
        // sort list descending
        indexesToBeRemoved.sort((a, b) => (b - a))
        indexesToBeRemoved.forEach((index) => {
          createdObjects.splice(index, 1)
        })
        if (groupSpecs) {
          const group = new MbGroup(createdObjects, groupSpecs)
          return [group] as fabric.Object[]
        }
        else {
          return createdObjects
        }
      })
      // .catch(err => console.log(err))
  }

  async updateArticleList(articleId: number) {
    if (!this.ArticleList.includes(articleId)) {
      this.ArticleList.push(articleId)
      const article = await appConfig.DB!.articles.get({ CatalogCode: this.CatalogCode, Id: articleId })
      if (article && article.Status === 0) {
        this.unavailableArticleCount++
      }
    }
  }

  async reAssignArticleList(articleIdList: number[]) {
    this.ArticleList = articleIdList
    await this.updateUnavailableArticleCount()
  }
}
export default MerchSlide
