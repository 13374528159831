<template>
  <div class="w-full h-full">
    <div class="w-full border-b h-1/2">
      <div class="flex border-b bg-gray-50 shrink grow-0">
        <tx-input
          v-model="myFilter" rounded faicon="fa-light fa-magnifying-glass" clearable
          :placeholder="t('savedViewDialog.myViews')" class="mx-2 my-4"
        />
        <tx-button
          v-tooltip="t('general.create')" width="24px"
          height="24px" icon-size="15px" class="my-6 mr-2" type="icon" faicon="fa-light fa-plus" @click="openCreateSavedViewDialog"
        />
      </div>
      <div style="height: calc(50vh - 100px)" class="overflow-y-auto">
        <tx-tree
          ref="refMyTree" class="tree" :data="myTreeData" :filter="myFilter"
          @node-click="onTreeItemClick" @action-click="onTreeActionClick"
        />
      </div>
    </div>

    <div class="w-full h-1/2">
      <div class="flex border-b bg-gray-50 shrink grow-0">
        <tx-input
          v-model="catalogFilter" rounded faicon="fa-light fa-magnifying-glass" clearable
          :placeholder="t('savedViewDialog.predefinedViews')" class="mx-2 my-4"
        />
      </div>
      <div style="height: calc(50vh - 120px)" class="overflow-y-auto">
        <tx-tree
          ref="refCatalogTree" class="tree" :data="catalogTreeData" :filter="catalogFilter"
          @node-click="onTreeItemClick" @action-click="onTreeActionClick"
        />
      </div>
    </div>

    <create-saved-view-dialog ref="createSavedViewDialogRef" @create="onCreateSavedView" />

    <tx-dialog
      v-model="editDialogVisible" :title="selectedSavedViewForAction && selectedSavedViewForAction.children.length > 0 ? t('savedViewDialog.editFolder') : t('savedViewDialog.editSavedView')"
      show-ok-cancel :ok-state="editing ? 'loading' : isCreateEnabled ? 'enabled' : 'disabled'"
      @ok="onEditSavedView(selectedSavedViewForAction!)"
    >
      <div class="w-full h-full">
        <div class="w-full my-2">
          <tx-input v-model.trim="newNameForEdit" :label="t('savedViewDialog.name')" required />
        </div>
      </div>
    </tx-dialog>

    <tx-dialog
      v-model="deleteDialogVisible" :title="t('general.warning')" :ok-state="deleting ? 'loading' : 'enabled'"
      show-ok-cancel @ok="onDeleteSavedView(selectedSavedViewForAction!)"
    >
      <div class="text-xl">
        {{ selectedSavedViewForAction && selectedSavedViewForAction.children.length > 0
          ? t('savedViewDialog.deleteFolderSavedViewConfirmation')
          : t('savedViewDialog.deleteSavedViewConfirmation', { name: selectedSavedViewForAction?.label })
        }}
      </div>
    </tx-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import type { Observable, Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import type { Ref } from 'vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import CreateSavedViewDialog from './CreateSavedViewDialog.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxTree from '@/shared/components/TxTree.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import type { CreateUpdateSavedViewModel, UpdateSavedViewsBulkModel, UpdateSavedViewsStatusModel } from '@/api/t1/model/savedViewModel'
import { createSavedView, updateSavedViewsBulk, updateSavedViewsStatus } from '@/api/t1/savedView'
import { savedViewConstants } from '@/models/constants'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import type SavedView from '@/models/savedView'

const props = defineProps<{
  savedViewModel: CreateUpdateSavedViewModel
}>()

const emit = defineEmits<{
  (e: 'treeItemClick', evt?: ITreeNode)
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()

const myFilter = ref('')
const catalogFilter = ref('')
const refMyTree = ref<InstanceType<typeof TxTree>>()
const myTreeData = ref<ITreeNode[]>([])
const refCatalogTree = ref<InstanceType<typeof TxTree>>()
const catalogTreeData = ref<ITreeNode[]>([])
const createSavedViewDialogRef = ref<InstanceType<typeof CreateSavedViewDialog>>()
const selectedSavedViewForAction = ref<ITreeNode>()
const editDialogVisible = ref(false)
const editing = ref(false)
const newNameForEdit = ref('')
const deleteDialogVisible = ref(false)
const deleting = ref(false)

let mySavedViewsSub: Subscription | undefined
let catalogSavedViewsSub: Subscription | undefined

function openCreateSavedViewDialog() {
  createSavedViewDialogRef.value?.showDialog(props.savedViewModel.ColumnDivider, props.savedViewModel.RowDivider)
}

async function onTreeItemClick(e?: ITreeNode) {
  emit('treeItemClick', e)
}

function onTreeActionClick(node: ITreeNode, action: TreeNodeAction) {
  selectedSavedViewForAction.value = node
  newNameForEdit.value = ''
  editDialogVisible.value = false
  deleteDialogVisible.value = false
  if (action === 'Edit') {
    newNameForEdit.value = node.label
    editDialogVisible.value = true
  }
  else if (action === 'Delete') {
    deleteDialogVisible.value = true
  }
}

function buildSavedViewTree(treeData: Ref<ITreeNode[]>, savedViews: Observable<SavedView[]>, isCatalogLevel: boolean) {
  return savedViews.subscribe((res) => {
    treeData.value = []
    const savedViewsWithoutFolder: ITreeNode[] = []
    res.forEach((savedView) => {
      const path = savedView.Name.split(savedViewConstants.folderPathSeparator)
      const savedViewName = path.pop() || ''
      const folderPath = path
      const savedViewSortOrder = savedView.SortOrderList.slice(savedView.SortOrderList.length - 1)[0]
      const foldersSortOrderList = savedView.SortOrderList.slice(0, savedView.SortOrderList.length - 1)
      const target = createFolderTrailAndGetTargetNode(treeData.value, folderPath, foldersSortOrderList, isCatalogLevel, false)
      const node: ITreeNode = {
        key: savedView.Id,
        label: savedViewName,
        sortOrder: savedViewSortOrder,
        checked: false,
        expanded: false,
        path: [],
        children: [],
        actions: (!isCatalogLevel || userStore.userProfile.accountDetails.AccountTypeId === 1) ? ['Edit', 'Delete'] : [],
      }
      if (Array.isArray(target)) { // root (saved view with no parent folder)
        utils.insertSorted(node, savedViewsWithoutFolder, (a, b) => utils.comparer(a, b, ['sortOrder', 'key']))
      }
      else { // folder
        node.parent = target
        utils.insertSorted(node, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'key']))
      }
    })

    if (savedViewsWithoutFolder.length) {
      let defaultFolder = treeData.value.find(node => node.key.toString().toLowerCase() === 'default')
      if (!defaultFolder) {
        defaultFolder = {
          key: `default`,
          label: 'default',
          sortOrder: 0,
          checked: false,
          expanded: false,
          path: [],
          children: [],
          actions: (!isCatalogLevel || userStore.userProfile.accountDetails.AccountTypeId === 1) ? ['Delete'] : [],
        }
        treeData.value.unshift(defaultFolder)
      }
      defaultFolder.children = defaultFolder.children.concat([...savedViewsWithoutFolder.map(s => ({ ...s, parent: defaultFolder }))])
        .sort((a, b) => utils.comparer(a, b, ['sortOrder', 'key']))
    }
  })
}

/**
 * @description iterate over folderPath, create folders that has not been yet created and return the deepest folder in the hierarchy
 * @param {Array} target where the saved view need to be added
 * @param {Array} folderPath array of string denoting folder hierarchy
 * @param {Array| null} foldersSortOrderList list of sort order for each folder
 * @param {boolean} isCatalogLevel is catalog level
 * @param {boolean} isNewSavedViewAdded is newly saved view added
 * @param {number} index current index while processing folderPath (use internally)
 * @return {Array | object}  location where the saved view needs to be added
 */
function createFolderTrailAndGetTargetNode(target: ITreeNode[] | ITreeNode, folderPath: string[], foldersSortOrderList: number[], isCatalogLevel: boolean, isNewSavedViewAdded = false, index = 0) {
  if (utils.isDefined(folderPath[index])) { // iterate till folderPath[index] is undefined (iterate folder path till reach to end)
    const currentFolderPath = folderPath.slice(0, index + 1)
    const currentFolderKey = currentFolderPath.join(savedViewConstants.folderPathSeparator)
    const currentTarget = !Array.isArray(target) ? target.children : target
    let subFolder = currentTarget.find(folder => folder.key.toString().toLowerCase() === currentFolderKey.toLowerCase())
    if (!utils.isDefined(subFolder)) {
      const siblings = !Array.isArray(target) ? target.children : target
      let currentFolderSortOrder = 0
      const fallbackSortOrdersList: number[] = []
      fallbackSortOrdersList.length = index + 1
      fallbackSortOrdersList.fill(0)
      const currentSortOrderList = utils.isDefined(foldersSortOrderList) ? foldersSortOrderList.slice(0, index + 1) : fallbackSortOrdersList
      if (!isNewSavedViewAdded) {
        currentFolderSortOrder = utils.isDefined(foldersSortOrderList) ? foldersSortOrderList[index] : Math.ceil(Math.max(...siblings.map(sibling => sibling.sortOrder), 0) + 1)
      }
      else { // if newly added saved view add it at the end
        currentFolderSortOrder = Math.ceil(Math.max(...siblings.map(sibling => sibling.sortOrder), 0) + 1)
        foldersSortOrderList[index] = currentFolderSortOrder
        currentSortOrderList.splice(currentSortOrderList.length - 1, 1, currentFolderSortOrder)
      }

      subFolder = {
        key: currentFolderKey,
        label: folderPath[index],
        sortOrder: currentFolderSortOrder,
        checked: false,
        expanded: false,
        path: [],
        children: [],
        actions: (!isCatalogLevel || userStore.userProfile.accountDetails.AccountTypeId === 1)
          ? (folderPath[index].toLowerCase() === 'default' ? ['Delete'] : ['Edit', 'Delete'])
          : [],
      }
      if (!Array.isArray(target)) {
        subFolder.parent = target
        utils.insertSorted(subFolder, target.children, (a, b) => utils.comparer(a, b, ['sortOrder', 'key']))
      }
      else {
        utils.insertSorted(subFolder, target, (a, b) => utils.comparer(a, b, ['sortOrder', 'key']))
      }
    }
    return createFolderTrailAndGetTargetNode(subFolder, folderPath, foldersSortOrderList, isCatalogLevel, isNewSavedViewAdded, ++index)
  }
  return target
}

function onCreateSavedView(name: string, sortOrderList: number[], isCatalogLevel: boolean) {
  const path = name.split(savedViewConstants.folderPathSeparator)
  const savedViewName = path.pop() || ''
  const folderPath = path
  const savedViewSortOrder = sortOrderList.slice(sortOrderList.length - 1)[0]
  const treeData = isCatalogLevel === true ? catalogTreeData : myTreeData
  let target = createFolderTrailAndGetTargetNode(treeData.value, folderPath, sortOrderList, false, true)
  const node: ITreeNode = {
    key: name,
    label: savedViewName,
    sortOrder: savedViewSortOrder,
    checked: false,
    expanded: false,
    path: [],
    children: [],
    actions: ['Edit', 'Delete'],
  }
  if (Array.isArray(target)) { // root (saved view with no parent folder)
    target = treeData.value.find(node => node.key.toString().toLowerCase() === 'default') as ITreeNode
    if (!target) {
      target = {
        key: 'default',
        label: 'default',
        sortOrder: 0,
        checked: false,
        expanded: false,
        path: [],
        children: [],
        actions: ['Delete'],
      }
      treeData.value.unshift(target)
    }
  }
  node.sortOrder = Math.ceil(Math.max(...target.children.map(child => child.sortOrder), 0) + 1)
  sortOrderList.splice(sortOrderList.length - 1, 1, node.sortOrder) // replace predefined sort order with actual sort order for saved view
  // sortOrderList.push(node.sortOrder)
  node.parent = target
  target.children.push(node)

  const payload = Object.assign({}, props.savedViewModel, { Name: name, IsCatalogLevel: isCatalogLevel, SortOrderList: JSON.stringify(sortOrderList) })

  if (userStore.activeCatalog && createSavedViewDialogRef.value) {
    createSavedView(userStore.activeCatalog.CatalogCode, payload)
      .then(async (res) => {
        createSavedViewDialogRef.value!.visible = false
        node.key = res.data.Id
        await userStore.doLoadData(['SavedViews'])
        notificationStore.addNotification({ message: t('savedViewDialog.createSuccessfully'), type: 'Success' })
      })
      .catch((e) => {
        console.error(e)
        createSavedViewDialogRef.value!.errorMessage = t('general.unexpectedError')
      })
      .finally(() => createSavedViewDialogRef.value!.loading = false)
  }
}

async function onEditSavedView(savedViewToEdit: ITreeNode) {
  if (userStore.activeCatalog) {
    const requestObject: UpdateSavedViewsBulkModel[] = []
    const treeNodes: ITreeNode[] = []
    if (savedViewToEdit.children.length > 0) {
      savedViewToEdit.children.forEach((node) => {
        if (node.children.length === 0) {
          treeNodes.push(node)
        }
        getChildNodes(treeNodes, node.children)
      })
      const level = getTreeLevel(savedViewToEdit)
      const savedViews = await appConfig.DB!.savedViews.where('Id').anyOf(treeNodes.map(x => Number(x.key))).toArray()
      savedViews.forEach((s) => {
        const path = s.FolderName.split(savedViewConstants.folderPathSeparator)
        if (path[level] !== newNameForEdit.value) {
          path[level] = newNameForEdit.value
          const newFolderName = path.join(savedViewConstants.folderPathSeparator)
          requestObject.push({
            Id: s.Id,
            Name: s.Name.replace(s.FolderName, newFolderName),
            SortOrderList: JSON.stringify(s.SortOrderList),
          })
        }
      })
    }
    else {
      const savedView = await appConfig.DB!.savedViews.get({ Id: Number(savedViewToEdit.key) })
      if (savedView) {
        const path = savedView.Name.split(savedViewConstants.folderPathSeparator)
        path.pop()
        path.push(newNameForEdit.value)
        requestObject.push({
          Id: savedView.Id,
          Name: path.join(savedViewConstants.folderPathSeparator),
          SortOrderList: JSON.stringify(savedView.SortOrderList),
        })
        treeNodes.push(savedViewToEdit)
      }
    }
    if (requestObject.length) {
      editing.value = true
      updateSavedViewsBulk(userStore.activeCatalog.CatalogCode, requestObject)
        .then(async () => {
          await userStore.doLoadData(['SavedViews'])
          const requestMap = requestObject.reduce((acc, curr) => {
            acc[curr.Id] = curr
            return acc
          }, {} as Record<number, UpdateSavedViewsBulkModel>)
          treeNodes.forEach((treeNode) => {
            const key = Number(treeNode.key)
            if (requestMap.hasOwnProperty(key) && utils.isDefined(requestMap[key])) {
              const path = requestMap[key].Name.split(savedViewConstants.folderPathSeparator)
              treeNode.label = path.pop() || ''
              if (utils.isDefined(treeNode.parent)) {
                treeNode.parent.key = path.join(savedViewConstants.folderPathSeparator)
                treeNode.parent.label = path.pop() || ''
              }
            }
          })
          notificationStore.addNotification({ message: t('savedViewDialog.editSuccessfully'), type: 'Success' })
        })
        .catch((e) => {
          console.error(e)
          notificationStore.addNotification({ message: t('general.unexpectedError'), type: 'Warning' })
        })
        .finally(() => {
          editDialogVisible.value = false
          editing.value = false
        })
    }
  }
}

function onDeleteSavedView(savedViewToDelete: ITreeNode) {
  if (userStore.activeCatalog) {
    deleting.value = true
    const requestObject: UpdateSavedViewsStatusModel[] = []
    if (savedViewToDelete.children.length > 0) {
      const list: ITreeNode[] = []
      savedViewToDelete.children.forEach((node) => {
        if (node.children.length === 0) {
          list.push(node)
        }
        getChildNodes(list, node.children)
      })
      list.forEach(node => requestObject.push({ Id: Number(node.key), Status: 0 }))
    }
    else {
      requestObject.push({ Id: Number(savedViewToDelete.key), Status: 0 })
    }

    updateSavedViewsStatus(userStore.activeCatalog.CatalogCode, requestObject)
      .then(async () => {
        await userStore.doLoadData(['SavedViews'])
        if (savedViewToDelete.parent) {
          const index = savedViewToDelete.parent.children.findIndex(s => s.key === savedViewToDelete.key)
          if (index >= 0) {
            savedViewToDelete.parent.children.splice(index, 1)
          }
        }
        else {
          let index = myTreeData.value.findIndex(s => s.key === savedViewToDelete.key)
          if (index >= 0) {
            myTreeData.value.splice(index, 1)
          }
          else {
            index = catalogTreeData.value.findIndex(s => s.key === savedViewToDelete.key)
            if (index >= 0) {
              catalogTreeData.value.splice(index, 1)
            }
          }
        }
        notificationStore.addNotification({ message: t('savedViewDialog.deleteSuccessfully'), type: 'Success' })
      })
      .catch((e) => {
        console.error(e)
        notificationStore.addNotification({ message: t('general.unexpectedError'), type: 'Warning' })
      })
      .finally(() => {
        deleteDialogVisible.value = false
        deleting.value = false
      })
  }
}

function getChildNodes(list: ITreeNode[], nodes: ITreeNode[]) {
  if (nodes.length > 0) {
    nodes.forEach((node) => {
      if (node.children.length === 0) {
        list.push(node)
      }
      getChildNodes(list, node.children)
    })
  }
}

function getTreeLevel(node: ITreeNode) {
  let level = 0
  let parent = node.parent
  while (utils.isDefined(parent)) {
    level++
    parent = parent.parent
  }
  return level
}

const isCreateEnabled = computed(() => {
  return utils.isValidStringValue(newNameForEdit)
})

onMounted(() => {
  if (userStore.activeCatalog && userStore.userProfile) {
    if (mySavedViewsSub && !mySavedViewsSub.closed) { mySavedViewsSub.unsubscribe() }
    const mySavedViews = liveQuery(async () => await appConfig.DB!.savedViews.where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedBy: userStore.userProfile.id, IsCatalogLevel: 0, Status: 1 }).toArray())
    mySavedViewsSub = buildSavedViewTree(myTreeData, mySavedViews, false)

    if (catalogSavedViewsSub && !catalogSavedViewsSub.closed) { catalogSavedViewsSub.unsubscribe() }
    const catalogSavedViews = liveQuery(async () => await appConfig.DB!.savedViews.where({ CatalogCode: userStore.activeCatalog!.CatalogCode, IsCatalogLevel: 1, Status: 1 }).toArray())
    catalogSavedViewsSub = buildSavedViewTree(catalogTreeData, catalogSavedViews, true)
  }
})

onUnmounted(() => {
  if (mySavedViewsSub && !mySavedViewsSub.closed) { mySavedViewsSub.unsubscribe() }
  if (catalogSavedViewsSub && !catalogSavedViewsSub.closed) { catalogSavedViewsSub.unsubscribe() }
})

defineExpose({
  myTree: refMyTree,
  catalogTree: refCatalogTree,
})
</script>
