<template>
  <div class="flex justify-between p-1.5">
    <div class="flex items-center">
      <tx-button
        v-tooltip="t('general.export')" type="icon" class="p-2 mx-1" width="40px" height="40px"
        faicon="fa-light fa-file-export" @click="doExport()"
      />
      <tx-button
        v-for="(action, index) in actions" v-show="action.visible" :key="index" v-tooltip="action.label" type="icon" class="p-2 mx-1" width="40px" height="40px"
        :faicon="action.icon" :disabled="!action.enabled" @click="action.onClick"
      />
      <div class="px-2 py-1 ml-2 text-xs font-medium rounded-sm bg-primary text-on-primary">
        {{ t('dataTable.xOfYSelected', { x: n(selectedCount), y: n(totalCount) }) }}
      </div>
      <div
        v-show="filteredCount < totalCount"
        class="px-2 py-1 ml-2 text-xs font-medium rounded-sm bg-primary text-on-primary"
      >
        {{ t('dataTable.xFiltered', { x: n(filteredCount) }) }}
      </div>
    </div>
    <div class="flex">
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-filter"
        @click="doFilter()"
      />
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-table"
        @click="doColumnChooser()"
      />
      <tx-button
        v-if="showStaticActions" type="icon" class="p-2 mx-1" width="40px" height="40px" faicon="fa-light fa-rotate-right"
        @click="doRefresh()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import TxButton from '../TxButton.vue'
import type { ITxDataTableListAction } from './TxDataTable.types'

defineProps<{
  actions: ITxDataTableListAction[]
  totalCount: number
  selectedCount: number
  filteredCount: number
  showStaticActions?: boolean
}>()

const emit = defineEmits<{
  (e: 'refresh'): void
  (e: 'columnChooser'): void
  (e: 'export'): void
  (e: 'filter'): void
}>()

const { t, n } = useI18n()

function doRefresh() {
  emit('refresh')
}

function doColumnChooser() {
  emit('columnChooser')
}

function doExport() {
  emit('export')
}

function doFilter() {
  emit('filter')
}
</script>
