import type { AccessibleCatalogModel, AccountDetailsModel, CatalogPermissionModel, NotificationSubscribeTypeModel, UserProfileModel, UserRoleModel } from '@/api/t1/model/userModel'
import utils from '@/services/utils'

// change it to UserProfile
export default class UserProfile {
  id: number
  accountId: number
  accountName: string
  firstName: string
  lastName: string
  email: string
  phone: string | null
  countryCode: string | null
  country: string | null
  city: string | null
  designation: string | null
  department: string | null
  userName: string
  gender: string | null
  dob: Date | null
  nationality: string | null
  address: string | null
  website: string | null
  restrictFullRange: number | null
  status: number
  createdBy: number
  updatedBy: number
  createdDate: Date
  updatedDate: Date

  settings: string | null
  accountDetails: AccountDetailsModel
  accessibleCatalogs: AccessibleCatalogModel[]
  notificationSubscribeTypes: NotificationSubscribeTypeModel[]
  roles: UserRoleModel[]

  permissions: Map<string, CatalogPermissionModel>
  catalogCode: number

  get initials() {
    return this.firstName.substring(0, 1) + this.lastName.substring(0, 1)
  }

  constructor(userProfileModel: UserProfileModel, catalogCode: number) {
    this.id = userProfileModel.Id
    this.accountId = userProfileModel.AccountId
    this.accountName = userProfileModel.AccountName
    this.firstName = userProfileModel.FirstName
    this.lastName = userProfileModel.LastName
    this.email = userProfileModel.Email
    this.phone = userProfileModel.Phone
    this.countryCode = userProfileModel.CountryCode
    this.country = userProfileModel.Country
    this.city = userProfileModel.City
    this.designation = userProfileModel.Designation
    this.department = userProfileModel.Department
    this.userName = userProfileModel.UserName
    this.gender = userProfileModel.Gender
    this.dob = userProfileModel.DOB
    this.nationality = userProfileModel.Nationality
    this.address = userProfileModel.Address
    this.website = userProfileModel.Website
    this.restrictFullRange = userProfileModel.RestrictFullRange
    this.status = userProfileModel.Status
    this.createdBy = userProfileModel.CreatedBy
    this.updatedBy = userProfileModel.UpdatedBy
    this.createdDate = userProfileModel.CreatedDate
    this.updatedDate = userProfileModel.UpdatedDate

    this.settings = userProfileModel.Settings
    this.accountDetails = userProfileModel.AccountDetails
    this.accessibleCatalogs = userProfileModel.AccessibleCatalogs
    this.notificationSubscribeTypes = userProfileModel.NotificationSubscribeTypes
    this.roles = userProfileModel.Roles

    this.permissions = new Map<string, CatalogPermissionModel>(userProfileModel.Permissions.map(p => [p.RouteName, p]))
    this.catalogCode = catalogCode
  }

  isValidPrivilege(key: string) {
    const permission = this.permissions.get(key)
    return utils.isDefined(permission) && (permission.Catalogs.length === 0 || permission.Catalogs.includes(this.catalogCode))
  }
}
