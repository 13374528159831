<template>
  <!-- ROOT -->
  <div class="flex flex-row h-full overflow-hidden flex-nowrap">
    <!-- LEFT SECTION -->
    <aside class="left-section w-[370px] flex-none overflow-hidden border-r border-gray-300 py-9 ">
      <order-info ref="orderInfoRef" class="px-6" :indexed-locations="indexedLocations" @updated="orderInfoUpdated" />
    </aside>

    <!-- RIGHT SECTION -->
    <main class="relative flex flex-col flex-auto overflow-hidden flex-nowrap">
      <!-- TOOLBAR -->
      <orderline-toolbar
        class="flex items-center flex-none overflow-x-auto h-25" :current-order="currentOrder!" :is-loading="isLoading" :is-loading-data="isLoadingData"
        :is-order-editable-excluding-process-status="isCurrentOrdersEditableExcludingProcessStatus" :is-order-editable="isCurrentOrderEditable" :selected-orderlines="selectedRows"
        @action-click="onToolbarActionClick"
      />
      <!-- ALERTS  -->
      <transition
        enter-from-class="-translate-y-full opacity-0" leave-to-class="-translate-y-full opacity-0"
        enter-active-class="transition-all ease-out" leave-active-class="transition-all ease-in" enter-to-class="translate-y-0 opacity-1" leave-from-class="translate-y-0 opacity-1"
      >
        <tx-alert :show="infoBannerVisibility" :text="infoBannerMessage" :type="bannerType" />
      </transition>

      <!-- TABLE -->
      <div class="relative flex flex-col flex-auto h-0 min-w-full overflow-hidden shrink-0">
        <!-- HEADER -->
        <div
          ref="headerRef"
          class="sticky top-0 z-10 flex flex-col overflow-hidden grow-0" :style="{ minHeight: `${isFilterVisible ? 131 : 46}px` }"
        >
          <!-- Column Resizing Handel -->
          <div v-show="resizingCol != null" class="absolute top-0 bottom-0 w-1 h-full bg-dark-grey z-splitter" :style="{ left: `${resizingColLeft}px` }" @mouseup.stop="doStopResizingColumn" />

          <!-- Column and filter container -->
          <div :style="{ minWidth: `${fullWidth}px` }">
            <!-- COLUMNS -->
            <div
              class="h-[46px] m-h-[46px] font-medium bg-gray-100 border-y border-gray-300 flex flex-row items-center"
              :style="{ minWidth: `${fullWidth}px` }" @mousemove="doResizeColumn" @mouseup="doStopResizingColumn"
            >
              <!-- Header Checkbox -->
              <div class="sticky left-0 p-3 bg-gray-100 w-9">
                <tx-checkbox id="tx-datatable-select-all" :model-value="selectAll" @change="doSelectAll" />
              </div>

              <!-- Header empty space to compensate for icons, VAS and article image -->
              <div :style="{ width: `${constants.staticColumnWidth.infoIconColumnWidth}px` }">
                &nbsp;
              </div>
              <div :style="{ width: `${constants.staticColumnWidth.warnIconColumnWidth}px` }">
                &nbsp;
              </div>
              <div v-if="containsValidVas" :style="{ width: `${constants.staticColumnWidth.vasColumnWidth}px` }">
                &nbsp;
              </div>
              <div :style="{ width: `${constants.staticColumnWidth.imageColumnWidth}px` }">
                &nbsp;
              </div>

              <!-- Header Cell -->
              <div
                v-for="column in visibleColumns" :key="column.property"
                class="flex flex-row items-center justify-start py-2 pl-2 overflow-hidden text-sm font-medium text-left align-middle bg-gray-100 cursor-pointer"
                :style="{ width: `${column.width}px` }" @mouseup="onColumnHeaderMouseUp(column, $event)"
              >
                <div class="overflow-hidden text-ellipsis whitespace-nowrap" :title="indexedFields.get(column.property)?.title">
                  {{ indexedFields.get(column.property)?.title }}
                </div>
                <!-- Header Sort Icon -->
                <div v-show="column.property !== 'Quantities'" class="pl-1">
                  <font-awesome-icon v-if="!sortCriteria.has(column.property)" icon="fa-light fa-sort" class="text-disabled" />
                  <font-awesome-icon v-else-if="sortCriteria.get(column.property)" icon="fa-light fa-sort-up" />
                  <font-awesome-icon v-else icon="fa-light fa-sort-down" />
                </div>
                <!-- Header resize handle -->
                <div class="grow h-4 min-w-[4px]" />
                <div v-show="column.property !== 'Quantities'" class="box-border w-1 h-4 border-gray-300 border-x cursor-ew-resize" @mousedown.stop="event => doStartResizingColumn(column, event)" />
              </div>
            </div>

            <!-- FILTER -->
            <orderline-filter
              v-model:filter-criteria="filterCriteria" v-model:visible="isFilterVisible" :visible-columns="visibleColumns" :full-width="fullWidth"
              :static-columns-width="constants.staticColumnWidth" :contains-valid-vas="containsValidVas"
              @close="resetFilterCriteria(false)" @open-advance-filter="openAdvancedFilter"
            />
          </div>
        </div>

        <!-- BODY -->
        <div v-bind="containerProps" class="relative flex-grow overflow-hidden">
          <!-- TODO: check vertical scroller ,adding scroll to this make the vertical scroller to be pushed to end of the table -->
          <!-- <div v-bind="containerProps" class="relative flex-grow overflow-x-hidden overflow-y-auto"> -->
          <div v-bind="wrapperProps" class="min-h-full">
            <!-- Loading Progress Bar -->
            <tx-indeterminate-progress v-if="isLoadingData" :style="{ minWidth: `${fullWidth}px`, height: '5px' }" />

            <!-- Data Row -->
            <div
              v-for="item in list " :key="item.index"
              class="h-20 bg-white border max-h-20 border-b-gray-200 border-x-transparent border-t-transparent" :style="{ minWidth: `${fullWidth}px` }"
              :class="[{ 'bg-yellow-50': item.data.selected }, !item.data.orderline.Status ? 'bg-[#f4433659]' : '']"
            >
              <div v-show="updatingArticleId !== item.data.orderline.Article.Id" class="flex flex-row items-center h-full">
                <!-- Row check box -->
                <div class="sticky left-0 p-3 my-1 w-9">
                  <tx-checkbox v-model="item.data.selected" @change="triggerSelectionChanged" />
                </div>
                <div :style="{ width: `${constants.staticColumnWidth.infoIconColumnWidth}px` }" />
                <div
                  v-tooltip="{ text: !indexedOrderlineStats[item.data.orderline.Article.Id].status ? indexedOrderlineStats[item.data.orderline.Article.Id].reasons.join(', ') : null, theme: { placement: 'right' } }" :style="{ width: `${constants.staticColumnWidth.warnIconColumnWidth}px` }"
                  class="font-bold text-red-500"
                >
                  <span :class="[!indexedOrderlineStats[item.data.orderline.Article.Id].status ? `after:content-['!']` : '']" />
                </div>
                <div v-if="containsValidVas" :style="{ width: `${constants.staticColumnWidth.vasColumnWidth}px` }" />
                <image-loader
                  class="!m-0 cursor-pointer"
                  :style="{ width: `${constants.staticColumnWidth.imageColumnWidth}px`, height: `${constants.staticColumnWidth.imageColumnWidth}px` }"
                  :context="userStore.activeCatalog!.DuneContext" :context-key="userStore.activeCatalog!.ContextKey"
                  :article-number="item.data.orderline.Article.ArticleNumber" :size="50"
                  @click="onArticleClick(item.data.orderline.Article)"
                />

                <!-- Data Cell -->
                <orderline-field-viewer
                  v-for="column in visibleColumns" :key="column.property" :crd-stats="indexedOrderlineStats[item.data.orderline.Article.Id].crdStats"
                  :column="column" :current-order="currentOrder!" :orderline="item.data.orderline"
                  :visible-crd-list="visibleCrdList" @crd-click="onCrdClick"
                />
              </div>

              <orderline-quantity-form
                v-show="updatingArticleId === item.data.orderline.Article.Id" :orderline="item.data.orderline" :indexed-locations="indexedLocations"
                :is-order-editable="isCurrentOrderEditable" :is-orderline-editable="indexedOrderlineStats[item.data.orderline.Article.Id].status" :is-crd-editable="isClickedCrdEditable"
                :visible="updatingArticleId === item.data.orderline.Article.Id"
                :active-catalog="userStore.activeCatalog!" :crd-id="updatingCrdId!"
                @article-click="onArticleClick" @exit-editing="exitEditQuantities"
              />
            </div>
          </div>

          <!-- <grand-total class="sticky bottom-0 bg-gray-100 h-15 z-dialog-overlay" :style="{ width: fullWidth + 'px' }"
                       :current-order="currentOrder!" :visible-columns="visibleColumns" :static-columns-width="constants.staticColumnWidth"
                       :contains-valid-vas=containsValidVas /> -->
        </div>

        <div ref="grandTotalContainerRef" class="flex-none overflow-x-auto bg-gray-100 h-15 scroll-smooth" @scroll="doSyncScroll">
          <grand-total
            class="h-full" :style="{ width: `${fullWidth}px` }"
            :current-order="currentOrder!" :visible-columns="visibleColumns" :static-columns-width="constants.staticColumnWidth"
            :contains-valid-vas="containsValidVas"
          />
        </div>
      </div>
    </main>

    <!-- ADVANCED FILTER -->
    <tx-data-table-advance-filter ref="advanceFilterElRef" :filter-criteria="filterCriteria" @ok="doAdvanceFilter" />

    <!-- COLUMN CHOOSER DIALOG -->
    <tx-data-table-column-chooser ref="columnPickerElRef" @ok="doUpdateColumns" />

    <!-- FILL ORDER -->
    <fill-order-dialog v-model="showFillOrderDialog" :current-order="currentOrder!" :indexed-articles="indexedArticles" @closed="fillOrderDialogClosed" />

    <!-- DIRTY ORDER DIALOG -->
    <tx-dialog
      v-model="showDirtyOrderDialog" :show-ok-cancel-discard="true" :title="t('orderlines.dirtyOrderDialog.title')" confirm-text="general.save"
      width="450px" height="250px" :loading="saveDirtyOrderLoading" @ok="saveDirtyOrder" @cancel="cancelToDiscardDirtyOrder" @discard="discardDirtyChanges"
    >
      <div class="whitespace-pre-line">
        {{ saveDirtyOrderDialogMessage }}
      </div>
    </tx-dialog>

    <!-- ALERTS DIALOG -->
    <tx-dialog v-model="showAlertDialog" :show-ok="true" :title="alertDialogTitle" confirm-text="general.ok" width="450px" height="250px" @ok="showAlertDialog = false" @closed="onAlertDialogClosed">
      <div class="whitespace-pre-line">
        {{ alertDialogMessage }}
      </div>
    </tx-dialog>

    <!-- PROMPT DIALOG -->
    <tx-dialog
      v-model="showPromptDialog" :show-ok-cancel="true" :title="promptDialogTitle" confirm-text="general.ok" width="450px" height="250px"
      :loading="promptDialogLoading" @ok="promptDialogUserConfirm" @cancel="promptDialogUserCancel"
    >
      <div class="whitespace-pre-line">
        {{ promptDialogDescription }}
      </div>
    </tx-dialog>

    <!-- ARTICLE DETAILS -->
    <tx-drawer
      v-model="isArticleDetailsOpen" :width="enableNewStyleDetails ? '95%' : '1024px'" right
      @closed="closeArticleDetails"
    >
      <model-details
        v-if="enableNewStyleDetails && clickedArticle" :article="clickedArticle" :read-only="true"
        @close="closeArticleDetails" @updated="onArticleUpdated" @change="onArticleClick"
      />
      <article-details
        v-else-if="clickedArticle" :article="clickedArticle"
        :read-only="true" @updated="onArticleUpdated" @change="onArticleClick"
      />
    </tx-drawer>
  </div>
</template>

<script lang='ts' setup>
import pick from 'lodash-es/pick'
import { cloneDeep } from 'lodash-es'
import { computed, inject, ref, toRef, watch } from 'vue'
import { onClickOutside, useThrottleFn, useVirtualList } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import ArticleDetails from '../articleDetails/ArticleDetails.vue'
import ModelDetails from '../articleDetails/ModelDetails.vue'
import FillOrderDialog from './components/orderline/FillOrderDialog.vue'
import GrandTotal from './components/orderline/GrandTotal.vue'
import OrderInfo from './components/orderline/OrderInfo.vue'
import OrderlineFieldViewer from './components/orderline/OrderlineFieldViewer.vue'
import OrderlineFilter from './components/orderline/OrderlineFilter.vue'
import OrderlineQuantityForm from './components/orderline/OrderlineQuantityForm.vue'
import OrderlineToolbar from './components/orderline/OrderlineToolbar.vue'
import type { IOrderlineCrd, IOrderlineFields, IOrderlineVisibleColumn, IStaticColumnWidth, OrderlineToolbarActions } from './Orders.types'
import { checkIfOrderIsEditable } from './services/orderFactory'
import appConfig from '@/services/appConfig'
import type CustomerLocation from '@/models/CustomerLocation'
import ImageLoader from '@/shared/components/ImageLoader.vue'
import type MyArticle from '@/models/myArticle'
import type Order from '@/models/order'
import type Orderline from '@/models/orderline'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxDataTableAdvanceFilter from '@/shared/components/txDataTable/TxDataTableAdvanceFilter.vue'
import TxDataTableColumnChooser from '@/shared/components/txDataTable/TxDataTableColumnChooser.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import TxIndeterminateProgress from '@/shared/components/TxIndeterminateProgress.vue'
import utils from '@/services/utils'
import type { ArticleCrd } from '@/models/articleDeliveryDate'
import { AttributeType } from '@/models/catalogAttribute'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'
import type { IOrderDetailsModel, IOrderModel, ISellerVASModel, IUpdateOrderPayload } from '@/api/t1/model/orderModel'
import { appConstants } from '@/models/constants'
import { fetchOrderlines } from '@/api/t1/order'
import { generateComparer } from '@/services/dataTableFactory'
import { getCatalogDetails } from '@/api/t1/catalog'
import { useDynamicPopper } from '@/shared/composables/popper'
import { useNotificationStore } from '@/store/notification'
import { useOrdersStore } from '@/store/orders'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  indexedArticles: Record<number, MyArticle>
  indexedLocations: Record<number, CustomerLocation>
  indexedVAS: Record<number, ISellerVASModel>
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'reopenOrder', orders: Array<Order>): void
}>()

const userStore = useUserStore()
const ordersStore = useOrdersStore()
const notificationsStore = useNotificationStore()
const { t } = useI18n()
const { addNewlyCreatedOrderToOrdersList, addOrderToSubmittingOrderReferenceList, updateOrderInOrdersList } = inject('ordersProvide', {
  addNewlyCreatedOrderToOrdersList: (_order: Order) => null,
  addOrderToSubmittingOrderReferenceList: (_orders: Array<Order>) => null,
  updateOrderInOrdersList: (_currentOrder: Order, _updateOrderPayload: IUpdateOrderPayload | IOrderModel | Partial<Order>) => null,
})

const isLoading = ref(false)
const isLoadingData = ref(false)
const showDirtyOrderDialog = ref(false)
const saveDirtyOrderLoading = ref(false)
const saveDirtyOrderDialogMessage = ref(t('orderlines.dirtyOrderDialog.description'))
const showAlertDialog = ref(false)
const alertDialogTitle = ref('')
const alertDialogMessage = ref('')
const updatingArticleId = ref<number | null>(null)
const updatingCrdId = ref<number | null>(null)
const isClickedCrdEditable = ref(true)
const orderInfoRef = ref<typeof OrderInfo | null>(null)
let orderInfoFormModel: Record<'Alias' | 'CustomerReference', any> = {} as Record<'Alias' | 'CustomerReference', any> // hold the form data when order info properties changes
const headerRef = ref<HTMLElement | null>(null)
const grandTotalContainerRef = ref<HTMLElement | null>(null)
const actionsElementRef = ref<HTMLInputElement | null>(null)
const currentOrder = toRef(ordersStore, 'currentOrder') // TODO: no need to convert it to ref
const indexedOrderlineStats = ref({} as Record<number, { status: boolean, reasons: Array<string>, crdStats: Record<number, { status: boolean, reasons: Array<string> }> }>)
const staticFields: Array<IOrderlineFields> = getOrderlineStaticFields()
const visibleColumns = ref<Array<IOrderlineVisibleColumn>>([])
const actionsPopper = useDynamicPopper(actionsElementRef, 'bottom-end')

const columnPickerElRef = ref<InstanceType<typeof TxDataTableColumnChooser> | undefined>()
const advanceFilterElRef = ref<InstanceType<typeof TxDataTableAdvanceFilter> | undefined>()

const isArticleDetailsOpen = ref(false)
const clickedArticle = ref<MyArticle | null>(null)

// RESIZE COLUMN
const resizingCol = ref<IOrderlineVisibleColumn>()
const resizingColLeft = ref(0)
let isResizingColMoved = false
let resizingColStartX = 0
let resizingColStartLeft = 0

// FILTER
const isFilterVisible = ref(false)
const filterCriteria = ref<Record<string, FilterCriteria>>({})
const debouncedFilterCriteria = ref<Record<string, FilterCriteria>>({})
let filterTimeoutToken: number

// FILL ORDER
const showFillOrderDialog = ref(false)

const selectedRows = ref<Array<Orderline>>([])

const sortCriteria = ref(new Map<string, boolean>())

const infoBannerVisibility = ref(false)
const infoBannerMessage = ref('')
const bannerType = ref<'info' | 'error' | 'success' | 'warning' | 'primary'>('info')

const isCurrentOrderEditable = ref(true)
// eslint-disable-next-line unused-imports/no-unused-vars
let isCurrentOrderInactive = false
// eslint-disable-next-line unused-imports/no-unused-vars
let haveOwnershipForCurrentOrder = true
// eslint-disable-next-line unused-imports/no-unused-vars
let doesCustomerLocationExist = true

const showPromptDialog = ref(false)
const promptDialogLoading = ref(false)
const promptDialogTitle = ref('')
const promptDialogDescription = ref('')
let deferredPromptDialogClosureResolve: null | ((value: void | PromiseLike<void>) => void) = null
let deferredPromptDialogClosureReject: null | ((value: void | any) => void) = null
let promptAction: null | 'sendOrders' = null

let deferredDirtyOrderClosureResolve: null | ((value: void | PromiseLike<void>) => void) = null
let deferredDirtyOrderClosureReject: null | ((value: void | any) => void) = null

const constants = {
  defaultColumnWidth: 150,
  staticColumnWidth: {
    selectAllColumnWidth: 36,
    infoIconColumnWidth: 15,
    warnIconColumnWidth: 15,
    imageColumnWidth: 50,
    vasColumnWidth: 30,
    actionsColumnWidth: 56,
  } as IStaticColumnWidth,
}

const dynamicFields = computed(() => {
  const propertiesToIgnore = ['_WholesalePrice', '_RetailPrice', '_OutletPrice', '_DeliveryDates', '_Segmentations', '_FavoriteTags', '_Seasons']
  const dynamicFields: Array<IOrderlineFields> = Object.values(userStore.myAttributes!).reduce((acu, cur) => {
    if (!propertiesToIgnore.includes(cur.SystemName)) {
      acu.push({ property: cur.SystemName, title: cur.DisplayName, type: cur.AttributeType, isArticleProperty: true, filterLookup: cur.FilterLookup })
    }
    return acu
  }, [] as Array<IOrderlineFields>)
  return dynamicFields
})

const indexedFields = computed(() => {
  const indexedFields = new Map<string, IOrderlineFields>()
  staticFields.forEach((column) => {
    indexedFields.set(column.property, column)
  })
  dynamicFields.value.forEach((column) => {
    indexedFields.set(column.property, column)
  })
  return indexedFields
})

function dataSortGetPropertyValueFunction(record: Orderline, property: string) {
  let recordValue = record[property]
  if (indexedFields.value.get(property)!.isArticleProperty) {
    recordValue = record.Article[property]
  }
  else if (property === 'CurrentPrice') {
    recordValue = utils.thousandsSeparator(Number.parseFloat(record[property].toFixed(2)))
  }
  else if (property === 'TotalPrice') {
    recordValue = utils.thousandsSeparator(Number.parseFloat((record.TotalQuantity * record.CurrentPrice).toFixed(2)))
  }
  return recordValue
}

// const orderlines = computed(() => Object.values(currentOrder?.value?.IndexedOrderlines || {}))

const isCurrentOrdersEditableExcludingProcessStatus = computed(() => !!currentOrder?.value?.Status && currentOrder?.value?.CreatedBy === userStore.userProfile.id
  && props.indexedLocations.hasOwnProperty(currentOrder?.value?.LocationId) && !!props.indexedLocations[currentOrder?.value?.LocationId].Status,
)

const orderlinesWithSelection = computed(() => {
  return Object.values(currentOrder?.value?.IndexedOrderlines || {}).map(orderline => ({ selected: false, orderline }))
})

const sortedFilteredOrderlines = computed(() => {
  let sortedFilteredOrderlines = orderlinesWithSelection.value
  if (debouncedFilterCriteria.value) {
    sortedFilteredOrderlines = sortedFilteredOrderlines.filter(sortedFilteredOrderline => recordMatchesFilter(sortedFilteredOrderline.orderline, debouncedFilterCriteria.value))
  }
  if (sortCriteria.value.size > 0) {
    const orderedSortCriteria = Array.from(sortCriteria.value, ([property, isAscending]) => generateComparer(property, isAscending, indexedFields.value.get(property)!.type, dataSortGetPropertyValueFunction))
    sortedFilteredOrderlines.sort((a, b) => compareRecordSortOrder(a.orderline, b.orderline, orderedSortCriteria))
  }
  return sortedFilteredOrderlines
})
const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  sortedFilteredOrderlines,
  {
    itemHeight: 80,
  },
)

const visibleCrdList = computed(() => {
  const visibleCrdList: Array<IOrderlineCrd> = []
  const addedCrdIdList: Array<number> = []
  orderlinesWithSelection.value.forEach(({ orderline }) => {
    for (const crdId in orderline.IndexedCRD) {
      const crdIdNumber = Number(crdId)
      if (orderline.IndexedCRD[crdId].TotalQuantity > 0 && !addedCrdIdList.includes(crdIdNumber)) {
        addedCrdIdList.push(crdIdNumber)
        visibleCrdList.push({ ...userStore.activeCatalog!._IndexedCatalogCRD[crdId], formattedCrd: utils.formatDateUTC(userStore.activeCatalog!._IndexedCatalogCRD[crdId].CustomerRequiredDate) })
      }
    }
  })
  for (const crdId in userStore.activeCatalog!._IndexedCatalogCRD) {
    const catalogCrd = userStore.activeCatalog!._IndexedCatalogCRD[crdId]
    if (catalogCrd.Status && !addedCrdIdList.includes(catalogCrd.Id)) {
      addedCrdIdList.push(catalogCrd.Id)
      visibleCrdList.push({ ...catalogCrd, formattedCrd: utils.formatDateUTC(catalogCrd.CustomerRequiredDate) })
    }
  }
  return visibleCrdList
})

const selectAll = computed(() => {
  return orderlinesWithSelection.value.length > 0 && orderlinesWithSelection.value.every(o => o.selected)
})

const containsValidVas = computed(() => Object.values(props.indexedVAS).some(vas => vas.Status))

const fullWidth = computed(() => {
  return visibleColumns.value.reduce((acu, cur) => acu + cur.width, 0) + constants.staticColumnWidth.selectAllColumnWidth + constants.staticColumnWidth.imageColumnWidth
    + constants.staticColumnWidth.infoIconColumnWidth + constants.staticColumnWidth.warnIconColumnWidth + /* constants.staticColumnWidth.actionsColumnWidth + */ (containsValidVas.value ? constants.staticColumnWidth.vasColumnWidth : 0)
})

// TODO: once stock catalog implemented add the checks
// const stockCatalogModificationRestrictedForFullRange = computed(() => userStore.activeCatalog!.IsStockApply && userStore.currentCustomer == null)

const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)

async function initOrderlines() {
  isLoadingData.value = true
  visibleColumns.value = staticFields.map((field, i) => ({
    property: field.property,
    title: field.title,
    type: field.type,
    isArticleProperty: field.isArticleProperty,
    filterLookup: field.filterLookup,
    order: i,
    width: field.property !== 'Quantities' ? constants.defaultColumnWidth : visibleCrdList.value.length * (100 /** crd width */ + 0 /** margin between crds */),
  }))
  // init indexedOrderlineStats in case of orderline details opens after create order
  indexedOrderlineStats.value = {}
  indexedOrderlineStats.value = getIndexedOrderlinesStats(Object.values(currentOrder.value!.IndexedOrderlines ?? {}))
  await reloadOrderlines(props.indexedArticles, props.indexedVAS)
  isLoadingData.value = false
}

async function doRefresh() {
  try {
    await checkForDirtyOrder()
    isLoadingData.value = true
    await reloadOrderlines(props.indexedArticles, props.indexedVAS)
    isLoadingData.value = false
  }
  catch (error) {
    console.error(error)
  }
}

async function reloadOrderlines(indexedArticles: Record<number, MyArticle>, indexedVAS: Record<number, ISellerVASModel>) {
  if (currentOrder.value!.Id! !== -1) { // while creating new order we assign -1 as orderId
    exitEditQuantities()
    currentOrder.value!.resetOrderlines()
    let orderlinesResponse: null | IOrderDetailsModel = null
    try {
      const getOrderlinesResponse = await fetchOrderlines(userStore.activeCatalog!.CatalogCode, userStore.currentCustomer?.CustomerId, currentOrder.value!.Id!)
      orderlinesResponse = getOrderlinesResponse.data
    }
    catch (error) {
      console.warn(`unable to fetch orderlines!, \n ${error}`)
    }
    if (orderlinesResponse != null) {
      currentOrder.value!.loadOrderlines(orderlinesResponse, indexedArticles, indexedVAS)
      indexedOrderlineStats.value = {}
      indexedOrderlineStats.value = getIndexedOrderlinesStats(Object.values(currentOrder.value!.IndexedOrderlines ?? {}))
    }
  }
}

function orderInfoUpdated(orderDetailsFormModel: Record<'Alias' | 'CustomerReference', any>) {
  orderInfoFormModel = orderDetailsFormModel
}

function removeSelectedOrderlines() {
  selectedRows.value.forEach((orderline: Orderline) => {
    delete currentOrder.value!.IndexedOrderlines![orderline.Article.Id]
  })
  selectedRows.value = []
  currentOrder.value!.setIsOrderlinesDirty(true)
}

async function saveOrder() {
  if (currentOrder.value?.IsOrderlinesDirty) {
    // set orderInfoRef's form isDirty to false in case user save newly created order and updated one of the form item as well
    orderInfoRef.value?.resetOrderInfoForm()
    try {
      await currentOrder.value.saveDraft(null)
      notificationsStore.addNotification({ message: t('orderlines.saveOrder.success'), type: 'Success' })
    }
    catch (error) {
      console.error(error)
      notificationsStore.addNotification({ message: t('orderlines.saveOrder.failed'), type: 'Alert' })
    }
  }
  else {
    notificationsStore.addNotification({ message: t('orderlines.saveOrder.noChangesToSave'), type: 'Info' })
  }
}

async function sendOrder() {
  try {
    isLoading.value = true
    // set orderInfoRef's form isDirty to false in case user send newly created order and updated one of the form item as well
    orderInfoRef.value?.resetOrderInfoForm()
    const catalogDetailsResponse = await getCatalogDetails(userStore.activeCatalog!.CatalogCode)
    if (!(catalogDetailsResponse.data.ReceiveOrders === 1 || catalogDetailsResponse.data.ReceiveOrders === true || catalogDetailsResponse.data.ReceiveOrders === 'true')) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.receiveOrderClosedMessage')
      return
    }
    // TODO: once work on implementing configurations update the createSendOrderRequestObject argument
    const result = currentOrder.value!.createSendOrderRequestObject(props.indexedArticles, [], {}, 0, containsValidVas.value, false, false, 0)
    // if(result.outOfStockArticleSizes.length) {
    //   // TODO: show stocks report once implement stocks
    //    return
    // }
    if (result.invalidArticles.length) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.invalidArticlesMessage')
      return
    }
    if (result.articleDroppedInSizeCurve.length) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.articleSizeDroppedMessage', { sizeList: result.articleDroppedInSizeCurve })
      return
    }
    if (!result.validMOQ && !userStore.activeCatalog!.Config.PassiveMOQ) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.MOQCriteriaFailedMessage')
      return
    }
    if (!result.validDeliveryDates) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.deliveryDateCriteriaFailedMessage')
      return
    }
    if (!result.validMultipleOfQuantity) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.multipleOfQuantityFailedMessage')
      return
    }
    // if (!result.validMSR && !isEmpty(result.MSRTracker)) {
    //   // TODO: implement msr report once worked on configurations
    //   return
    // }
    if (!result.requestObj.Orderlines.length) {
      showAlertDialog.value = true
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.noOrderlineMessage')
      return
    }

    if (result.containsArticleWithZeroQuantity) {
      promptDialogDescription.value = t('orders.sendOrder.alerts.sendOrderWithZeroQuantity')
    }
    if (!result.validMOQ && userStore.activeCatalog!.Config.PassiveMOQ) {
      const MOQFailedOrderlineList = `\n${result.MOQFailedOrderlineList.join(', ')}`
      promptDialogDescription.value += (promptDialogDescription.value.length ? '\n\n' : '') + t('orders.sendOrder.alerts.invalidMOQWarning', { MOQFailedOrderlineList })
    }
    if (userStore.activeCatalog!.Config.ArticlesMustHave && Object.keys(userStore.activeCatalog!.Config.ArticlesMustHave).length && result.missingArticlesMustHaveList.length) {
      const missingArticlesMustHaveList = `\n${result.missingArticlesMustHaveList.join(', ')}`
      promptDialogDescription.value += (promptDialogDescription.value.length ? '\n\n' : '') + t('orders.sendOrder.alerts.invalidArticlesMustHaveWarning', { missingArticlesMustHaveList })
    }

    let promise = Promise.resolve()
    if (promptDialogDescription.value.length) {
      promptAction = 'sendOrders'
      promptDialogTitle.value = t('general.alert')
      promptDialogDescription.value += `\n\n${t('orders.sendOrder.alerts.submitOrder')}`
      showPromptDialog.value = true
      promise = new Promise((resolve, reject) => {
        deferredPromptDialogClosureResolve = resolve
        deferredPromptDialogClosureReject = reject
      })
    }

    try {
      await promise
      try {
        await currentOrder.value!.sendOrder(result.requestObj)
        // if user directly send a newly created order (at this time it wont be part of orders list and it wont be tracked)
        addNewlyCreatedOrderToOrdersList(currentOrder.value!)
        // update the processing status on order
        updateOrderInOrdersList(currentOrder.value!, pick(currentOrder.value!, ['OrderProcessStatusId', 'OrderProcessStatus']))
        // start tracking
        addOrderToSubmittingOrderReferenceList([currentOrder.value!])
        notificationsStore.addNotification({ message: t('orders.sendOrder.successAlert.orderSendSuccessful', { orderReference: currentOrder.value!.OrderReference }), type: 'Success' })
      }
      catch (error) {
        notificationsStore.addNotification({ message: t('orders.sendOrder.failedAlerts.genericMessage', { orderReference: currentOrder.value!.OrderReference }), type: 'Alert', details: utils.getErrorMessage(error) })
      }
    }
    catch (error) {
      console.error(error)
    }
    finally {
      resetPromptDialogValues()
    }
  }
  catch (error: any) {
    console.error(error)
    showAlertDialog.value = true
    if (error.message && error.message.toString().toLowerCase() === 'network error') {
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.noInternetMessage')
    }
    else {
      alertDialogTitle.value = t('orders.sendOrder.failedAlerts.title')
      alertDialogMessage.value = t('orders.sendOrder.failedAlerts.unexpectedErrorMessage')
    }
  }
  finally {
    isLoading.value = false
  }
}

function reopenOrder() {
  emit('reopenOrder', [currentOrder.value!])
}

function doShowFillOrderDialog() {
  showFillOrderDialog.value = true
}

function fillOrderDialogClosed(createdOrderlines?: Array<Orderline>) {
  showFillOrderDialog.value = false
  if (createdOrderlines && createdOrderlines.length) {
    indexedOrderlineStats.value = Object.assign(indexedOrderlineStats.value, getIndexedOrderlinesStats(createdOrderlines))
  }
}

function doToggleFilter() {
  resetFilterCriteria(false)
  isFilterVisible.value = !isFilterVisible.value
}

function resetFilterCriteria(keepExistingCriteria: boolean) {
  if (!keepExistingCriteria) {
    filterCriteria.value = {}
  }

  visibleColumns.value.forEach((visibleColumn) => {
    if (!keepExistingCriteria || !filterCriteria.value.hasOwnProperty(visibleColumn.property)) {
      filterCriteria.value[visibleColumn.property] = new FilterCriteria({ attribute: visibleColumn.property, exclude: false, mode: FilterCriteria.attributeTypeToFilterMode(visibleColumn.type, visibleColumn.filterLookup) })
    }
  })

  if (keepExistingCriteria) {
    const attributeSystemNameList = Object.getOwnPropertyNames(filterCriteria.value)
    attributeSystemNameList.forEach((attributeSystemName) => {
      if (!visibleColumns.value.find(visibleColumns => visibleColumns.property === attributeSystemName)) {
        delete filterCriteria.value[attributeSystemName]
      }
    })
  }
}

function openAdvancedFilter(column: IOrderlineVisibleColumn) {
  advanceFilterElRef.value?.show(column)
}

function recordMatchesFilter(data: any, filterCriteria: Record<string, FilterCriteria>) {
  return Object.getOwnPropertyNames(filterCriteria).every((prop) => {
    const criteria = filterCriteria[prop]

    if (!criteria) {
      return true
    }
    const isStaticField = !indexedFields.value.get(criteria.attribute)!.isArticleProperty
    const dataToApplyFilter = isStaticField ? data : data.Article

    if (criteria.filterBlank) {
      // check if the attribute value is blank (null, undefined, or empty string)
      const isBlankValue = dataToApplyFilter[criteria.attribute] == null || dataToApplyFilter[criteria.attribute] === ''
      if (!criteria.exclude) {
        // filter all the blanks
        return !!isBlankValue
      }
      else if (criteria.exclude) {
        // if filterBlank and exclude enabled then we need to show the records having on blank value and discard the blank values
        return !isBlankValue
      }
    }

    const hasValue = criteria.hasValue()
    if (!hasValue) {
      return true
    }
    if (isStaticField) {
      return isCriteriaMatchOrderlineStaticData(dataToApplyFilter, criteria)
    }
    else {
      return criteria.matchesRecord(dataToApplyFilter)
    }
  })
}

function isCriteriaMatchOrderlineStaticData(data: Orderline, criteria: FilterCriteria) {
  let recordValue = data[criteria.attribute]
  if (criteria.attribute === 'CurrentPrice') {
    recordValue = utils.thousandsSeparator(Number.parseFloat(data[criteria.attribute].toFixed(2)))
  }
  else if (criteria.attribute === 'TotalPrice') {
    recordValue = utils.thousandsSeparator(Number.parseFloat((data.TotalQuantity * data.CurrentPrice).toFixed(2)))
  }
  else if (criteria.attribute === 'Quantities') {
    return true
  }
  if (criteria.mode === FilterCriteriaMode.multiString) {
    if (!utils.isDefined(criteria.multipleVals)) {
      return false
    }
    if (utils.isDefined(recordValue) && recordValue.toString().trim().length > 0) {
      return criteria.exclude !== (criteria.multipleVals.findIndex(multipleValCurrentValue => multipleValCurrentValue != null && recordValue.toString().toLowerCase().includes(multipleValCurrentValue.toString().toLowerCase())) >= 0)
    }
    else {
      return criteria.exclude !== (criteria.multipleVals.includes(null))
    }
  }
  else {
    return utils.isDefined(criteria.numberVal) && criteria.exclude !== (recordValue.toString().toLowerCase().includes(criteria.numberVal.toString().toLowerCase()))
  }
}

function doAdvanceFilter(columnFilterObject: Record<string, { filterValues: string, filterBlank: boolean, excludeMode: boolean }>) {
  Object.keys(columnFilterObject).forEach((property) => {
    const filterObject = columnFilterObject[property]
    const mode = FilterCriteriaMode.multiString
    const filterValues = filterObject.filterValues.split('\n').map(val => val.trim())

    const filterCriteriaItem = new FilterCriteria({
      attribute: property,
      mode,
      multipleVals: filterObject.filterBlank ? undefined : filterValues,
      exclude: filterObject.excludeMode.valueOf(),
      filterBlank: filterObject.filterBlank.valueOf(),
      isAdvancedFilter: true,
    })
    filterCriteria.value[property] = filterCriteriaItem
  })
  scrollTo(0)
}

// toolbar functions
function onToolbarActionClick(action: IToolbarActionButton<OrderlineToolbarActions>) {
  switch (action.id) {
    case 'save':
      saveOrder()
      break
    case 'send':
      sendOrder()
      break
    case 'reopen':
      reopenOrder()
      break
    case 'fillOrder':
      doShowFillOrderDialog()
      break
    case 'columnPicker':
      showColumnPicker()
      break
    case 'filter':
      doToggleFilter()
      break
    case 'close':
      closeOrderline()
      break
    case 'refresh':
      doRefresh()
      break
    case 'removeOrderlines':
      removeSelectedOrderlines()
      break
    default:
      break
  }
}

function getOrderlineStaticFields() {
  return [
    { property: 'CurrentPrice', title: t('orders.field.unitPrice'), type: AttributeType.Decimal, isArticleProperty: false, filterLookup: new Map() },
    { property: 'TotalQuantity', title: t('orders.field.totalQuantity'), type: AttributeType.Int, isArticleProperty: false, filterLookup: new Map() },
    { property: 'TotalPrice', title: t('orders.field.totalPrice'), type: AttributeType.Decimal, isArticleProperty: false, filterLookup: new Map() },
    { property: 'Quantities', title: t('orders.field.quantities'), type: AttributeType.Int, isArticleProperty: false, filterLookup: new Map() },
  ] as Array<IOrderlineFields>
}

function doSelectAll(selectedModelValue: TxBooleanish) {
  const isSelected = selectedModelValue === true || selectedModelValue === 'true' || selectedModelValue === 1
  sortedFilteredOrderlines.value.forEach(data => data.selected = isSelected)
  triggerSelectionChanged()
}

function triggerSelectionChanged() {
  selectedRows.value = orderlinesWithSelection.value.reduce((acc, cur) => {
    if (cur.selected) {
      acc.push(cur.orderline)
    }
    return acc
  }, [] as Array<Orderline>)
  // emits('selection-changed', selectedRows.value)
}

function compareRecordSortOrder(first: Orderline, second: Orderline, sortCriteriaFunctionList: ((a: Orderline, b: Orderline) => number)[]) {
  return sortCriteriaFunctionList.reduce((acu, currentComparer) => acu || currentComparer(first, second), 0)
}

function onColumnHeaderMouseUp(column: IOrderlineVisibleColumn, event: MouseEvent) {
  if (column.property !== 'Quantities') {
    doSort(column, event)
  }
}

function doSort(column: IOrderlineVisibleColumn, event: MouseEvent) {
  if (resizingCol.value) {
    return
  }

  // clear sort criteria if user did not hold control key while click and either it was sort on multiple colum or sort was on different column
  if (!event.ctrlKey && (sortCriteria.value.size > 1 || !sortCriteria.value.has(column.property))) {
    sortCriteria.value.clear()
  }

  if (sortCriteria.value.has(column.property)) {
    sortCriteria.value.set(column.property, !sortCriteria.value.get(column.property))
  }
  else {
    sortCriteria.value.set(column.property, true)
  }
  scrollTo(0)
}

// function onActionClick(action) {
//   action.onClick()
//   actionsPopper.hide()
// }

function onCrdClick(articleId: number, crdId: number) {
  updatingArticleId.value = articleId
  updatingCrdId.value = crdId
  isClickedCrdEditable.value = indexedOrderlineStats.value && indexedOrderlineStats.value[articleId]
  && indexedOrderlineStats.value[articleId].crdStats && indexedOrderlineStats.value[articleId].crdStats[crdId]
    ? indexedOrderlineStats.value[articleId].crdStats[crdId].status
    : false
  // if (containerProps.ref.value != null) {
  //   containerProps.ref.value.scrollLeft = 0
  // }
  if (grandTotalContainerRef.value != null) {
    grandTotalContainerRef.value.scrollLeft = 0
  }
}

function exitEditQuantities() {
  updatingArticleId.value = null
  updatingCrdId.value = null
  isClickedCrdEditable.value = true
}

onClickOutside(actionsElementRef, () => {
  actionsPopper.hide()
})

function doSyncScroll() {
  headerRef.value?.scrollTo({ left: grandTotalContainerRef.value?.scrollLeft })
  containerProps.ref.value?.scrollTo({ left: grandTotalContainerRef.value?.scrollLeft })
  // headerRef.value?.scrollTo({ left: containerProps.ref.value?.scrollLeft })
  // grandTotalContainerRef.value?.scrollTo({ left: containerProps.ref.value?.scrollLeft })
}

function showColumnPicker() {
  columnPickerElRef.value?.show(dynamicFields.value, visibleColumns.value.reduce((acu, cur) => {
    // only consider dynamic fields (isArticleProperty = false) in selected columns
    if (cur.isArticleProperty) {
      acu.push(indexedFields.value.get(cur.property)!)
    }
    return acu
  }, [] as Array<IOrderlineFields>))
}

async function closeOrderline() {
  try {
    await checkForDirtyOrder()
    emit('close')
  }
  catch (error) {
    console.error(error)
  }
}

function doUpdateColumns(columns: Array<IOrderlineVisibleColumn | IOrderlineFields>) {
  // Remove column that are no longer selected part from static columns
  const newColumns: Array<IOrderlineVisibleColumn> = []
  columns.forEach((column, index) => {
    const existingCol = visibleColumns.value.find(visibleColumn => visibleColumn.property === column.property)
    const newColumn: IOrderlineVisibleColumn = {
      property: column.property,
      title: column.title,
      type: column.type,
      isArticleProperty: column.isArticleProperty,
      filterLookup: column.filterLookup,
      order: index,
      width: existingCol ? existingCol.width : constants.defaultColumnWidth,
    }
    newColumns.push(newColumn)
  })
  visibleColumns.value = newColumns.concat(visibleColumns.value.filter(visibleColumn => !visibleColumn.isArticleProperty))

  // If the filter panel is open then we need to update the filter criteria
  if (isFilterVisible.value) {
    resetFilterCriteria(true)
  }
}

// RESIZING COLUMN
function doStartResizingColumn(col: IOrderlineVisibleColumn, e: MouseEvent) {
  resizingColStartX = e.clientX
  const bcr = (e.target as HTMLElement).parentElement?.parentElement?.getBoundingClientRect()
  resizingColLeft.value = e.clientX - (bcr?.left || 0)
  resizingColStartLeft = resizingColLeft.value
  resizingCol.value = col
}

const doResizeColumn = useThrottleFn((e: MouseEvent) => {
  if (resizingCol.value && e.currentTarget) {
    const movementOnX = e.clientX - resizingColStartX
    const newColLeft = resizingColLeft.value + movementOnX
    const newWidth = resizingCol.value.width + newColLeft - resizingColStartLeft
    if (newWidth < 75) { return }
    resizingColLeft.value += movementOnX
    resizingColStartX = e.clientX
    isResizingColMoved = true
  }
}, 1)

function doStopResizingColumn(e: MouseEvent) {
  if (isResizingColMoved && resizingCol.value) {
    const newWidth = resizingCol.value.width + resizingColLeft.value - resizingColStartLeft
    resizingCol.value.width = Math.max(newWidth, 75)
    e.stopPropagation()
  }
  resizingCol.value = undefined
  isResizingColMoved = false
}

function checkForDirtyOrder(_retry = false) {
  const promise = new Promise((resolve, reject) => {
    deferredDirtyOrderClosureResolve = resolve
    deferredDirtyOrderClosureReject = reject
  })

  if (currentOrder.value?.IsDirty || currentOrder.value?.IsOrderlinesDirty) {
    showDirtyOrderDialog.value = true
  }
  else {
    deferredDirtyOrderClosureResolve!()
  }
  return promise.finally(() => {
    resetDirtyDialogValues()
  })
}

function resetDirtyDialogValues() {
  showDirtyOrderDialog.value = false
  saveDirtyOrderDialogMessage.value = t('orderlines.dirtyOrderDialog.description')
}

function cancelToDiscardDirtyOrder() {
  deferredDirtyOrderClosureReject!(new Error('Operation canceled by user'))
}

function discardDirtyChanges() {
  deferredDirtyOrderClosureResolve!()
}

async function saveDirtyOrder() {
  saveDirtyOrderLoading.value = true
  try {
    // set orderInfoRef's form isDirty to false in case user save newly created order and updated one of the form item as well
    orderInfoRef.value?.resetOrderInfoForm()
    // if orderline is dirty
    if (currentOrder.value?.IsOrderlinesDirty) {
      // if there are changes on order info, save them with saveDraft API
      const response = await currentOrder.value.saveDraft(currentOrder.value?.IsDirty ? orderInfoFormModel : null)
      // if there are changes on order info, update order with changes in orders list
      if (currentOrder.value?.IsDirty) {
        updateOrderInOrdersList(currentOrder.value, response!.data)
      }
      notificationsStore.addNotification({ message: t('orderlines.updateOrder.success'), type: 'Success' })
    }
    else if (currentOrder.value?.IsDirty) { // if only order head is dirty then call the update order head function
      await orderInfoRef.value?.doUpdateOrderDetails()
    }

    deferredDirtyOrderClosureResolve!()
  }
  catch (error) {
    saveDirtyOrderDialogMessage.value = t('orderlines.dirtyOrderDialog.retry')
    console.error(error)
  }
  finally {
    saveDirtyOrderLoading.value = false
  }
}

function initOrderBannerStats() {
  // reset values
  isCurrentOrderEditable.value = true
  isCurrentOrderInactive = false
  haveOwnershipForCurrentOrder = true
  doesCustomerLocationExist = true

  infoBannerVisibility.value = false
  infoBannerMessage.value = ''
  bannerType.value = 'info'

  const orderStatus = checkIfOrderIsEditable(currentOrder.value!, userStore.userProfile, props.indexedLocations)

  if (!orderStatus.isOrderEditable) {
    isCurrentOrderEditable.value = false
    if (orderStatus.noneEditableProcessStatus) {
      infoBannerVisibility.value = true
      infoBannerMessage.value = ''
      switch (currentOrder.value?.OrderProcessStatusId) {
        case 2:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.submitted')
          bannerType.value = 'info'
          break
        case 3:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.disapproved')
          bannerType.value = 'error'
          break
        case 4:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.approved')
          bannerType.value = 'success'
          break
        case 6:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.locked')
          bannerType.value = 'warning'
          break
        case 7:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.submitting')
          bannerType.value = 'warning'
          break
        case 8:
          infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.confirmed')
          bannerType.value = 'success'
          break
      }
    }

    if (orderStatus.isOrderInactive) {
      isCurrentOrderInactive = true
      infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.inactiveOrder')
      infoBannerVisibility.value = true
      bannerType.value = 'warning'
    }
    else {
      isCurrentOrderInactive = false
    }

    if (!orderStatus.haveOrderOwnership) {
      haveOwnershipForCurrentOrder = false
      infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.doesNotHaveOwnership')
      infoBannerVisibility.value = true
      bannerType.value = 'warning'
    }
    else {
      haveOwnershipForCurrentOrder = true
    }

    if (!orderStatus.doesCustomerLocationExist) {
      doesCustomerLocationExist = false
      infoBannerMessage.value = t('orders.alerts.orderCanNotBeUpdated.inactiveLocation')
      infoBannerVisibility.value = true
      bannerType.value = 'warning'
    }
    else {
      doesCustomerLocationExist = true
    }
  }
}

function getIndexedOrderlinesStats(orderlines: Array<Orderline>) {
  const indexedOrderlineStats: Record<number, { status: boolean, reasons: Array<string>, crdStats: Record<number, { status: boolean, reasons: Array<string> }> }> = {}
  let haveValidCrd = false
  orderlines.forEach((orderline) => {
    const articleId = orderline.Article.Id
    if (!indexedOrderlineStats.hasOwnProperty(articleId)) {
      const articleStats = getOrderlineStats(orderline)
      indexedOrderlineStats[articleId] = { ...articleStats, crdStats: {} }
    }
    const indexedArticleCrd = orderline.Article._DeliveryDates.reduce((acu, cur) => (acu[cur.CrdId] = cur) && acu, {} as Record<number, ArticleCrd>)
    visibleCrdList.value.forEach((visibleCrd) => {
      if (!indexedOrderlineStats[articleId].crdStats.hasOwnProperty(visibleCrd.Id)) {
        indexedOrderlineStats[articleId].crdStats[visibleCrd.Id] = getOrderlineCrdStats(orderline, visibleCrd, indexedArticleCrd)
        if (indexedOrderlineStats[articleId].crdStats[visibleCrd.Id].status) {
          // at least 1 valid crd
          haveValidCrd = true
        }
      }
    })
    if (!haveValidCrd) {
      indexedOrderlineStats[articleId].status = false
      indexedOrderlineStats[articleId].reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.unavailableCRD'))
    }
  })
  return indexedOrderlineStats
}

function getOrderlineStats(orderline: Orderline) {
  const stats = {
    status: true,
    reasons: [] as Array<string>,
  }
  if (orderline.DecodedStatus != null) {
    for (const key in orderline.DecodedStatus) {
      if (orderline.DecodedStatus[key]) {
        switch (key) {
          case 'invalidArticle':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveArticle'))
            break
          case 'invalidCatalogOrCustomerSegmentation':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.invalidCatalogOrCustomerSegmentation'))
            break
          case 'invalidPriceGroup':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.invalidPriceGroup'))
            break
          case 'invalidArticleSeg':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.invalidArticleSeg'))
            break
          case 'allocationCriteriaField':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.allocationCriteriaField'))
            break
          case 'invalidSegmentation':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.invalidSegmentation'))
            break
          case 'customerUnlinked':
            stats.status = false
            stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.customerUnlinked'))
            break
        }
      }
    }
  }
  else {
    if (props.indexedArticles[orderline.Article.Id].Status !== 1) {
      stats.status = false
      stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveArticle'))
    }
  }
  if (!props.indexedArticles[orderline.Article.Id]._Sizes.some(articleSize => articleSize.Status)) {
    stats.status = false
    stats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.orderlineWithoutSize'))
  }
  return stats
}

function getOrderlineCrdStats(orderline: Orderline, visibleCrd: IOrderlineCrd, indexedArticleCrd: Record<number, ArticleCrd>) {
  const crdStats: { status: boolean, reasons: Array<string> } = {
    status: true,
    reasons: [] as Array<string>,
  }
  const decodedStatus = orderline.IndexedCRD.hasOwnProperty(visibleCrd.Id) ? orderline.IndexedCRD[visibleCrd.Id].DecodedStatus : null
  if (decodedStatus != null) {
    for (const key in decodedStatus) {
      if (decodedStatus[key]) {
        switch (key) {
          case 'invalidCRD':
            crdStats.status = false
            crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveCatalogCRD'))
            break
          case 'invalidArticleCRD':
            crdStats.status = false
            crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveArticleCRD'))
            break
          case 'unAvailableCRD':
            crdStats.status = false
            crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.unavailableCRD'))
            break
        }
      }
    }
  }
  else {
    if (!visibleCrd.Status) {
      crdStats.status = false
      crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveCatalogCRD'))
    }
    if (!indexedArticleCrd.hasOwnProperty(visibleCrd.Id) || !indexedArticleCrd[visibleCrd.Id].Status) {
      crdStats.status = false
      crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.inactiveArticleCRD'))
    }
    else if (indexedArticleCrd.hasOwnProperty(visibleCrd.Id) && indexedArticleCrd[visibleCrd.Id].Status) {
      const nowInMilliseconds = Date.now()
      const availabilityFrom = indexedArticleCrd[visibleCrd.Id].AvailabilityFrom
      const availabilityTo = indexedArticleCrd[visibleCrd.Id].AvailabilityTo
      if (!visibleCrd.Availability || (availabilityFrom != null && nowInMilliseconds < availabilityFrom.getTime()) || (availabilityTo != null && nowInMilliseconds > availabilityTo.getTime())) {
        crdStats.status = false
        crdStats.reasons.push(t('orderlines.alerts.orderlineCanNotBeUpdated.unavailableCRD'))
      }
    }
  }
  return crdStats
}

function onAlertDialogClosed() {
  showAlertDialog.value = false
  alertDialogTitle.value = ''
  alertDialogMessage.value = ''
}

function resetPromptDialogValues() {
  showPromptDialog.value = false
  promptDialogTitle.value = ''
  promptDialogDescription.value = ''
  promptAction = null
}

function promptDialogUserConfirm() {
  if (promptAction === 'sendOrders') {
    deferredPromptDialogClosureResolve!()
  }
}

function promptDialogUserCancel() {
  if (promptAction === 'sendOrders') {
    deferredPromptDialogClosureReject!(new Error('Operation canceled by user'))
  }
}

function onArticleUpdated(key: string, value: any) {
  if (clickedArticle.value && clickedArticle.value.hasOwnProperty(key)) {
    clickedArticle.value[key] = value
  }
}

async function onArticleClick(article: MyArticle) {
  try {
    if (article.CatalogCode === userStore.activeCatalog!.CatalogCode || userStore.linkedCatalogDetails[article.CatalogCode]) {
      const articles = await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [article.Id], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      clickedArticle.value = articles[0]
      isArticleDetailsOpen.value = true
    }
    else {
      clickedArticle.value = article
      isArticleDetailsOpen.value = true
    }
  }
  catch (error) {
    console.error(error)
    notificationsStore.addNotification({ message: t('orderlines.alerts.unableToLoadArticleDetails'), type: 'Alert' })
  }
}

function closeArticleDetails() {
  isArticleDetailsOpen.value = false
  clickedArticle.value = null
}

watch([() => currentOrder.value, () => currentOrder.value?.OrderProcessStatusId, () => currentOrder.value?.Status], () => {
  if (currentOrder.value != null) {
    initOrderBannerStats()
  }
}, { immediate: true })

watch(filterCriteria, (criteria) => {
  clearTimeout(filterTimeoutToken)
  filterTimeoutToken = setTimeout(() => {
    debouncedFilterCriteria.value = cloneDeep(criteria)
  }, appConstants.debounce.input)
}, { deep: true })

initOrderlines()
</script>
