<template>
  <div class="w-full h-full overflow-auto mt-4">
    <div v-if="!items.length" class="text-lg">
      <p>{{ t('general.emptyList') }}</p>
    </div>
    <tx-collapse v-else v-model="activeItems" class="px-4">
      <tx-collapse-item
        v-for="(item) in items" :key="item.Id" :title-class="`tracking-[1px] font-semibold text-lg ${(item.Status === 0 ? 'opacity-50' : '')}`"
        :title="`${t(item.RequestTypeTranslator)}: ${item.Content.ArticleNumber}`" :value="item.Id"
      >
        <div class="border rounded shadow">
          <div
            class="p-2 cursor-pointer hover:border hover:border-primary hover:rounded"
            :class="{ 'border border-primary rounded': item.ObjectId === selectedObjectId, 'opacity-50': item.Status === 0 }"
            @click="onRequestArticleSelected(item)"
          >
            <div class="flex font-semibold justify-between border-b">
              <div class="flex items-center">
                <div class="mr-4">
                  <div v-tooltip="{ text: utils.formatDateTime(item.CreatedDate), theme: { placement: 'right' } }">
                    <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.CreatedDate)">
                      {{ timeAgo }}
                    </use-time-ago>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center flex-grow">
                <tx-chip :text="requestConstants.requestStatesReverse[item.State]" :type="getChipType(item)" />
              </div>
              <div class="flex flex-col justify-end items-start">
                <div class="flex items-left">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.createdBy') }}: {{ item.CreatedByUserName }}
                    </div>
                  </div>
                </div>
                <div v-if="item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.reject || item.State === requestConstants.requestStates.confirm" class="flex items-start">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.updatedBy') }}: {{ item.UpdatedByUserName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="item.Content" class="pt-2 border-b">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.modelOrArticleAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <template v-for="(value, key) in item.Content" :key="key">
                  <div v-if="userStore.myAttributes && userStore.myAttributes[key]" class="mb-5 last-of-type:mb-4">
                    <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ userStore.myAttributes[key].DisplayName }}
                    </div>
                    <div class="pl-1 font-semibold leading-3 w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ utils.getAttributeTypeSpecificValue(userStore.myAttributes[key], value, userStore.priceGroups, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated) }}
                    </div>
                  </div>
                </template>
              </div>
            </div> -->

            <div class="pt-2">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.requestAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div v-for="attribute in getAvailableRequestAttributes(item.RequestSource)" :key="attribute.AttributeSystemName" class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ attribute.AttributeDisplayName }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ item.RequestAttributes && item.RequestAttributes[attribute.AttributeSystemName] && item.RequestAttributes[attribute.AttributeSystemName].length ? item.RequestAttributes[attribute.AttributeSystemName][0] : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex space-x-2 justify-end border-t p-1">
            <template v-if="item.CreatedBy === userStore.userProfile.id && (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new) && hasUpdateStatusRequestPrivilege">
              <!-- <tx-button
                v-if="item.Status === 0" faicon="fa-light fa-rotate-right"
                :text="t('activeOrDeactiveArticle.activate')" @click="onActivateOrDeActivateRequest(item, 1)"
              /> -->
              <tx-button
                v-if="item.Status === 1" faicon="fa-light fa-trash-can"
                type="danger" :text="t('general.delete')" @click="onActivateOrDeActivateRequest(item, 0)"
              />
            </template>
            <tx-button
              v-if="item.Status === 1 && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve) && hasRejectRequestPrivilege"
              type="cancel" faicon="fa-light fa-thumbs-down" :text="t('articleDetails.actions.reject')" @click="openApproveRejectDialog(item, 2)"
            />
            <tx-button
              v-if="item.Status === 1 && (item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject) && hasApproveRequestPrivilege"
              faicon="fa-light fa-thumbs-up" :text="t('articleDetails.actions.approve')" @click="openApproveRejectDialog(item, 1)"
            />
            <tx-button
              v-if="item.Status === 1 && item.State === requestConstants.requestStates.approve && hasConfirmRequestPrivilege"
              :text="t('articleDetails.actions.confirm')" @click="onConfirmRequest(item)"
            />
          </div>
        </div>
      </tx-collapse-item>
    </tx-collapse>
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'
import { useUserStore } from '@/store/userData'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import { useNotificationStore } from '@/store/notification'
import TxCollapse from '@/shared/components/txCollapse/txCollapse.vue'
import TxCollapseItem from '@/shared/components/txCollapse/txCollapseItem.vue'
import TxChip from '@/shared/components/TxChip.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type RequestModel from '@/models/request'
import { privileges, requestConstants } from '@/models/constants'
import utils from '@/services/utils'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import type CatalogRequestAttribute from '@/models/catalogRequestAttribute'
import { updateRequestStatuses } from '@/api/t1/request'
import appConfig from '@/services/appConfig'

const props = defineProps<{
  items: RequestModel[]
  selectedRequestId?: number
  selectedObjectId?: string
}>()

const emit = defineEmits<{
  (e: 'itemSelected', requestId: number, objectId: string): void
  (e: 'confirm', item: RequestModel): void
  (e: 'refreshRequestDetails'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const confirmDialog = useConfirmDialog()
const notificationsStore = useNotificationStore()

const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()

const activeItems = ref<Array<number>>([])
if (props.selectedRequestId) {
  activeItems.value.push(props.selectedRequestId)
}

const hasUpdateStatusRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.updateStatus))
const hasRejectRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest))
const hasApproveRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.approveRequest))
const hasConfirmRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest))

watch(() => props.selectedRequestId, (requestId) => {
  activeItems.value = []
  if (requestId) {
    activeItems.value.push(requestId)
  }
})

function getAvailableRequestAttributes(requestSource: number) {
  let requestAttributes: CatalogRequestAttribute[] = []
  if (userStore.activeCatalog) {
    requestAttributes = userStore.activeCatalog.RequestAttributeList.filter(a => a.Status > 0)
    if (requestSource === requestConstants.requestSources.new || requestSource === requestConstants.requestSources.carryover) {
      if (userStore.activeCatalog.Config.RequestForm.carryoverModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
    else if (requestSource === requestConstants.requestSources.similarStyle) {
      if (userStore.activeCatalog.Config.RequestForm.copyModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
  }
  return requestAttributes
}

watch(() => props.selectedRequestId, (requestId) => {
  if (requestId) {
    activeItems.value = []
    activeItems.value.push(requestId)
  }
})

function getChipType(item: RequestModel) {
  return item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.confirm
    ? 'success'
    : item.State === requestConstants.requestStates.reject
      ? 'error'
      : 'primary'
}

async function onActivateOrDeActivateRequest(request: RequestModel, status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: request.Content.ArticleNumber })

    await confirmDialog(title, message, async () => {
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, [{ Id: request.Id, Status: status }])
      await appConfig.DB!.requests.update([userStore.activeCatalog!.CatalogCode, request.Id], { Status: status })
      await userStore.doLoadData(['Requests'])
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      emit('refreshRequestDetails')
    })
  }
}

function openApproveRejectDialog(request: RequestModel, actionType: number) {
  showApproveRejectDialog.value?.showDialog([request], actionType)
}

function onConfirmRequest(request: RequestModel) {
  emit('confirm', request)
}

function onRequestArticleSelected(request: RequestModel) {
  emit('itemSelected', request.Id, request.ObjectId)
}

function onApproveReject() {
  emit('refreshRequestDetails')
}
</script>
