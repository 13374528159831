import type { IMerchTemplate } from './IMerchTemplate'

// import standard from './standard'
import techSheet from './techSheet'

// TODO: Might need to load them dynamically

const templates: Record<number, IMerchTemplate> = {}
// templates[standard.id] = standard
templates[techSheet.id] = techSheet

export default templates
