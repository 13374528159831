<template>
  <div class="flex flex-col h-full overflow-hidden">
    <div class="flex-grow overflow-hidden">
      <aside-content id="catalog" v-model:opened="asideOpened">
        <template #aside>
          <div class="flex flex-col w-full overflow-hidden">
            <div class="h-16 min-h-[64px] text-center border-b bg-gray-50 flex flex-col items-center">
              <tx-tabs v-model="selectedTab" :tabs="tabs" class="m-auto" />
            </div>
            <categories v-if="selectedTab === 'tree'" ref="categoriesRef" @tree-item-click="onCategoriesTreeItemClick" />
            <saved-views v-if="selectedTab === 'views'" :saved-view-model="getSavedViewModel" @tree-item-click="onSavedViewTreeItemClick" />
          </div>
        </template>
        <!-- Toolbar -->
        <div>
          <articles-list-toolbar
            module-name="Browse" :aside-open="asideOpened" :destination="articlesHeader"
            :select-all="selectAllCheck" :select-all-indeterminate="selectAllIndeterminate"
            :enable-browse-by="true" :browse-by-details-label="browseByDetailsLabel" :buttons="toolbarButtons"
            @select-all-change="onSelectAll" @aside-click="asideOpened = !asideOpened" @filter="onFilter"
            @clear-all-filters="onClearFilter" @clear-filter="onClearFilter" @add-click="toolbarAddButtonClicked"
            @exit-browse-by-details="exitBrowseByDetails" @open-custom-sort="openCustomSort"
          />
        </div>
        <articles-list
          v-if="!isShowingBucketsListView" ref="articlesListRef" :category-criteria="categoryCriteria" :filter-criteria="filterCriteria" :browse-by-details-criteria="browseByDetailsCriteria"
          :thumbnail-size="getThumbnailSize" :column-attribute="getGroupByCol" :row-attribute="getGroupByRow"
          @selection-changed="onSelectionChanged" @data-changed="onArticlesListDataChanged"
          @article-click="onArticleClick" @context-menu-item-click="articleListContextMenuItemClicked"
        />

        <buckets-list
          v-else ref="bucketsListRef" :category-criteria="categoryCriteria" :filter-criteria="filterCriteria"
          :thumbnail-size="getBucketThumbnailSize" :column-attribute="getGroupByCol" :row-attribute="getGroupByRow"
          @selection-changed="onBucketSelectionChanged" @data-changed="onBucketsListDataChanged"
          @on-context-item-updated="onBucketContextItemUpdated" @context-menu-item-click="bucketListContextMenuItemClicked"
          @bucket-click="onBucketClick"
        />

        <tx-drawer v-model="filterOpen" width="700px" right>
          <articles-filter
            :criteria="filterCriteria" :selected-filter="selectedFilter" class="pt-8 px-15" @cancel="filterOpen = false"
            @confirm="onFilterConfirm"
          />
        </tx-drawer>

        <tx-drawer
          v-model="articleDetailsOpen" :width="enableNewStyleDetails ? '95%' : '1024px'" right
          @closed="closeArticleDetails"
        >
          <model-details
            v-if="enableNewStyleDetails && clickedArticle" :article="clickedArticle" @close="closeArticleDetails" @change-article-selection="onChangeArticle"
            @change="onArticleClick" @updated="onArticleUpdated" @on-edit-fav="onArticleDetailsEditFav"
          />
          <article-details
            v-else-if="clickedArticle" :article="clickedArticle"
            @change="onArticleClick" @updated="onArticleUpdated" @on-edit-fav="onArticleDetailsEditFav"
          />
        </tx-drawer>

        <tx-drawer v-model="isUpdateArticleDrawerVisible" width="700px" right @closed="closeUpdateDrawer">
          <active-or-deactive-article
            v-if="(updateActionType === ActionBarActionType.activate || updateActionType === ActionBarActionType.deactivate)"
            :is-model="browseByStore.isBrowseByModel" :articles="selectedArticles" :type="updateActionType" @cancel="closeUpdateDrawer"
            @updated="onArticlesUpdated"
          />
          <update-article
            v-else-if="updateActionType === ActionBarActionType.updateArticles" :articles="selectedArticles"
            :is-model="browseByStore.isBrowseByModel" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <update-article-segmentation
            v-else-if="updateActionType === ActionBarActionType.updateArticleSegmentation"
            :articles="selectedArticles" :is-model="browseByStore.isBrowseByModel" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <update-article-retail-window
            v-else-if="updateActionType === ActionBarActionType.updateRetailWindow"
            :articles="selectedArticles" :is-model="browseByStore.isBrowseByModel"
            @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <assign-article-resource
            v-else-if="updateActionType === ActionBarActionType.assignArticleResource"
            :articles="selectedArticles" :is-model="browseByStore.isBrowseByModel" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <update-prices
            v-else-if="updateActionType === ActionBarActionType.updatePrices" :articles="selectedArticles"
            @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <manage-article-state
            v-else-if="updateActionType === ActionBarActionType.manageArticleState" :is-model="browseByStore.isBrowseByModel"
            :articles="selectedArticles" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <update-parent-model
            v-else-if="updateActionType === ActionBarActionType.editParentModel"
            :articles="selectedArticles" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <manage-bucket-attributes
            v-else-if="updateActionType === ActionBarActionType.updateBucketAttributes"
            :targeted-buckets="selectedBuckets.length ? selectedBuckets : [contextBucket!]" :filter-criteria="filterCriteria"
            @cancel="closeUpdateDrawer" @update-completed="closeUpdateDrawer"
          />
          <update-size-scale
            v-else-if="updateActionType === ActionBarActionType.editSizes" :articles="selectedArticles"
            @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
          <assort-articles
            v-if="updateActionType === ActionBarActionType.assortArticles"
            :is-model="browseByStore.isBrowseByModel" :articles="selectedArticles" @cancel="closeUpdateDrawer" @updated="onArticlesUpdated"
          />
        </tx-drawer>

        <!-- Action buttons -->
        <action-bar :active="selectedArticles.length > 0" :buttons="actionBarButtons" />
        <favorites-editor-dialog ref="favoritesDlg" />
        <save-image-dialog ref="saveImageDlg" />
      </aside-content>

      <!-- STYLE CREATE -->
      <tx-drawer v-model="isAddArticleDrawerVisible" width="90%" right @closed="onAddArticleDrawerClosed">
        <add-articles
          :action-type="styleCreateAction" :context-article="contextArticle" :is-add-request="isRequestToAdd"
          :selected-articles="selectedArticles" :visible="isAddArticleDrawerVisible" @close="onAddArticleDrawerClosed"
        />
      </tx-drawer>

      <tx-drawer v-model="showAssignColor" width="700px" right>
        <assign-color
          :show-colorway="isAddArticlePanelVisible" :context-article="contextArticle!"
          @back="onAddArticlePanelClosed" @updated="onUpdateAssignColor" @cancel="onClosedAssignColor"
        />
      </tx-drawer>

      <tx-drawer v-model="showAssortArticleForm" width="700px" right>
        <assort-articles v-if="contextArticle" :articles="[contextArticle]" @updated="onAssortArticle" @cancel="onAssortArticleClosed" />
      </tx-drawer>

      <custom-sort-dialog v-model="showCustomSortDialog" @custom-sort="onCustomSort" />
      <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />
    </div>
    <status-bar />
  </div>
</template>

<script lang="ts" setup>
import { isArray, isEmpty, toInteger } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import UpdateSizeScale from '../articleDetails/components/UpdateSizeScale.vue'
import UpdateParentModel from '../articleDetails/components/UpdateParentModel.vue'
import ManageArticleState from '../articleDetails/components/ManageArticleState.vue'
import AssignColor from '../articleDetails/components/AssignColor.vue'
import ActiveOrDeactiveArticle from '../articleDetails/components/ActiveOrDeactiveArticle.vue'
import UpdateArticleSegmentation from '../articleDetails/components/UpdateArticleSegmentation.vue'
import AssignArticleResource from '../articleDetails/components/AssignArticleResource.vue'
import AssortArticles from '../articleDetails/components/AssortArticles.vue'
import UpdatePrices from '../articleDetails/components/UpdatePrices.vue'
import ManageBucketAttributes from './components/ManageBucketAttributes.vue'
import useBrowseBy from './composables/browseBy'
import AddArticles from './components/addArticles'
import SavedViews from './components/SavedViews.vue'
import Categories from './components/Categories.vue'
import SaveImageDialog from './components/SaveImageDialog.vue'
import AsideContent from '@/shared/components/AsideContent.vue'
import StatusBar from '@/shared/components/StatusBar.vue'
import ArticlesList from '@/shared/components/ArticlesList.vue'
import ArticleDetails from '@/modules/articleDetails/ArticleDetails.vue'
import ModelDetails from '@/modules/articleDetails/ModelDetails.vue'
import ArticlesListToolbar from '@/shared/components/articlesListToolbar'
import BucketsList from '@/shared/components/buckets/BucketsList.vue'
import CustomSortDialog from '@/shared/components/articlesListToolbar/CustomSortDialog.vue'
import ActionBar from '@/shared/components/ActionBar.vue'
import ArticlesFilter from '@/shared/components/ArticlesFilter.vue'
import UpdateArticleRetailWindow from '@/shared/components/UpdateArticleRetailWindow.vue'
import FavoritesEditorDialog from '@/shared/components/favorites/FavoritesEditorDialog.vue'
import UpdateArticle from '@/modules/articleDetails/components/UpdateArticle.vue'
import TxDrawer from '@/shared/components/TxDrawer.vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import type MyArticle from '@/models/myArticle'
import { FilterCriteria, FilterCriteriaMode, toFilterCriteria } from '@/models/filterCriteria'
import type { ISavedViewFilter } from '@/models/savedView'
import type { CreateUpdateSavedViewModel } from '@/api/t1/model/savedViewModel'
import * as sBar from '@/store/status'
import { ArticlesListToolbarActions, appConstants, privileges, requestConstants, thumbnailConstants } from '@/models/constants'
import utils from '@/services/utils'
import appConfig from '@/services/appConfig'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import useDisableModule from '@/shared/composables/disableModule'
import useArticleDetails from '@/shared/composables/articleDetails'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import { useBrowseByStore } from '@/store/browseBy'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import useEventsBus from '@/shared/composables/eventBus'
import type Article from '@/models/article'
import { updateRequestStatuses } from '@/api/t1/request'
import type { UpdateRequestStatusModel } from '@/api/t1/model/requestModel'

enum ActionBarActionType {
  deSelect = 'deSelect',
  copyArticle = 'copyArticle',
  saveImage = 'saveImage',
  editFav = 'editFav',
  deactivate = 'deactivate',
  deactivateRequest = 'deactivateRequest',
  activate = 'activate',
  activateRequest = 'activateRequest',
  updateArticles = 'updateArticles',
  updateArticleSegmentation = 'updateArticleSegmentation',
  updateRetailWindow = 'updateRetailWindow',
  assignArticleResource = 'assignArticleResource',
  carryOverArticles = 'carryOverArticles',
  updatePrices = 'updatePrices',
  manageArticleState = 'manageArticleState',
  editParentModel = 'editParentModel',
  updateBucketAttributes = 'updateBucketAttributes',
  editSizes = 'editSizes',
  assortArticles = 'assortArticles',
  approveRequests = 'approveRequests',
  rejectRequests = 'rejectRequests',
  confirmRequests = 'confirmRequests',
}

const { t } = useI18n()
const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const notificationsStore = useNotificationStore()
const confirmDialog = useConfirmDialog()
const { emitter } = useEventsBus()

const { filterableAttributesByAttributeSystemName } = useFilterableAttributes()
const { isResourcesModuleEnabled } = useDisableModule()
const { articleDetailsOpen, clickedArticle, onArticleUpdated, onArticleClick, onChangeArticle, openArticleDetails, closeArticleDetails } = useArticleDetails('Catalog')

const articlesListRef = ref<typeof ArticlesList>()
const categoriesRef = ref<InstanceType<typeof Categories>>()
const favoritesDlg = ref<InstanceType<typeof FavoritesEditorDialog>>()
const saveImageDlg = ref<InstanceType<typeof SaveImageDialog>>()
const filterOpen = ref(false)
const isUpdateArticleDrawerVisible = ref<boolean>(false)
const articlesHeader = ref({ type: 'path', value: {} } as IToolbarHeader)
const selectedArticlesArticlesListView = ref([] as MyArticle[])
const selectAllCheck = ref(false)
const selectAllIndeterminate = ref(false)
const asideOpened = ref(false)
const categoryCriteria = ref<FilterCriteria[]>([])
const filterCriteria = ref<FilterCriteria[]>([])
const selectedFilter = ref<FilterCriteria>()
const route = useRoute()
const router = useRouter()
let listedArticles = [] as MyArticle[]
const tabs: ITabItem[] = [{ key: 'tree', label: 'general.categories', visible: true }, { key: 'views', label: 'general.savedViews', visible: true }]
const selectedTab = ref('tree')
const updateActionType = ref<ActionBarActionType>()
const isAddArticleDrawerVisible = ref(false)
let styleCreateAction: StyleCreateActionType | undefined
let contextArticle: MyArticle | undefined
let isRequestToAdd = false
const contextBucket = ref<IAttributeBucket | undefined>(undefined)
const isAddArticlePanelVisible = ref(false)
const showAssignColor = ref(false)
const showCustomSortDialog = ref(false)
const showAssortArticleForm = ref(false)
const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
let approveRejectActionType: number = 1

const { activeBucketAttributes, browseBy, browseByDetailsCriteria, browseByDetailsLabel, bucketsListRef, exitBrowseByDetails, getAndInitBucketsAttributesValue, isBrowseByAttribute, isShowingBucketsListView, onBucketClick, onBucketsListDataChanged, selectedBuckets, selectedArticlesBucketsListView, setBrowseByUsingPropertyName, visibleBucketsCount } = useBrowseBy(filterCriteria)

const selectedArticles = computed({
  get: () => {
    if (!isShowingBucketsListView.value) {
      return selectedArticlesArticlesListView.value
    }
    else {
      return selectedArticlesBucketsListView.value
    }
  },
  set: (value) => {
    if (!isShowingBucketsListView.value) {
      selectedArticlesArticlesListView.value = value
    }
    else {
      selectedArticlesBucketsListView.value = value
    }
  },
})

const selectedArticlesInfo = computed(() => {
  const selectedArticlesInfo = {
    containsArticles: false,
    containsActiveArticles: false,
    containsInactiveArticles: false,
    containsLinkedArticles: false,
    containsArticlesWithoutRetailWindowUpdateAllowed: false,
    containsPlaceholder: false,
    belongsToSameModel: true,
    containsChildInactiveArticles: false,
    containsGloballyDroppedArticles: false,
    containsRequestArticles: false,
    containsActiveRequests: false,
    containsInactiveRequests: false,
  }
  const firstArticleModelNumber = selectedArticles.value.length ? selectedArticles.value[0].ModelNumber : null
  selectedArticles.value.forEach((selectedArticle) => {
    if (selectedArticle._IsRequestArticle) {
      selectedArticlesInfo.containsRequestArticles = true
      if (selectedArticle.Status === 1) {
        selectedArticlesInfo.containsActiveRequests = true
      }
      else {
        selectedArticlesInfo.containsInactiveRequests = true
      }
    }
    else {
      selectedArticlesInfo.containsArticles = true
      if (selectedArticle.Status === 1) {
        selectedArticlesInfo.containsActiveArticles = true
      }
      else {
        selectedArticlesInfo.containsInactiveArticles = true
        if (userStore.activeCatalog!.DataSourceTypeId === appConstants.catalogTypes.inherited) {
          selectedArticlesInfo.containsChildInactiveArticles = true
          selectedArticlesInfo.containsGloballyDroppedArticles = selectedArticle.Status === 3
        }
      }
      if (selectedArticle.CatalogCode !== userStore.activeCatalog!.CatalogCode) {
        selectedArticlesInfo.containsLinkedArticles = true
      }
      if (!selectedArticle.AllowRetailWindowUpdate) {
        selectedArticlesInfo.containsArticlesWithoutRetailWindowUpdateAllowed = true
      }
      if (firstArticleModelNumber !== selectedArticle.ModelNumber) {
        selectedArticlesInfo.belongsToSameModel = false
      }
      if (selectedArticle.ColorId == null || selectedArticle.ColorId === 0) {
        selectedArticlesInfo.containsPlaceholder = true
      }
    }
  })
  return selectedArticlesInfo
})

// browse toolbar action should be added here
const toolbarButtons = computed<IToolbarActionButton<ArticlesListToolbarActions>[]>(() => {
  const toolbarButtons: IToolbarActionButton<ArticlesListToolbarActions>[] = [
    {
      id: ArticlesListToolbarActions.getBucketsAttributeValue,
      icon: 'fa-magnifying-glass',
      label: t('general.getBucketsValue'),
      disabled: browseByStore.indexedBucketAttributesValue != null,
      visible: filterCriteria.value.length > 0 && isShowingBucketsListView.value && activeBucketAttributes.value.length > 0,
      onClick: getBucketsAttributeValueCallback,
    },
  ]
  return toolbarButtons
})

const actionBarButtons = computed<IActionBarButton[]>(() => {
  const actionBarButtons: IActionBarButton[] = []
  if (selectedArticles.value.length) {
    // DESELECT & COPY ARTICLES
    actionBarButtons.push({ id: ActionBarActionType.deSelect, icon: 'fa-light fa-rectangle-xmark', label: 'catalog.actionBar.deselect', disabled: false, onClick: () => { onSelectAll(false) } })

    // when browse by model show Copy Models action and copy the model number instead of article number
    if (!browseByStore.isBrowseByModel) {
      actionBarButtons.push({ id: ActionBarActionType.copyArticle, icon: 'fa-light fa-copy', label: 'catalog.actionBar.copy', disabled: false, onClick: onCopyArt })
    }
    else {
      actionBarButtons.push({ id: ActionBarActionType.copyArticle, icon: 'fa-light fa-copy', label: 'catalog.actionBar.copy', disabled: false, onClick: onCopyModels })
    }

    // SAVE IMAGES
    if (userStore.activeCatalog?.Config.AllowDownloadImages && selectedArticlesInfo.value.containsRequestArticles === false) {
      actionBarButtons.push({ id: ActionBarActionType.saveImage, icon: 'fa-light fa-download', label: 'catalog.actionBar.saveImages', disabled: false, onClick: onSaveImage })
    }

    // all selected articles must belongs to current catalog (based on previous implementation)
    if (selectedArticlesInfo.value.containsLinkedArticles === false && selectedArticlesInfo.value.containsRequestArticles === false && !selectedArticlesInfo.value.containsRequestArticles) {
      // UPDATE ARTICLES allow updating inactive articles
      if (userStore.userProfile.isValidPrivilege(privileges.article.update) && !selectedArticlesInfo.value.containsChildInactiveArticles && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        actionBarButtons.push({ id: ActionBarActionType.updateArticles, icon: 'fa-light fa-edit', label: 'catalog.actionBar.editArticles', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.updateArticles) } })
      }
      // all selected articles must be active
      if (selectedArticlesInfo.value.containsInactiveArticles === false) {
        // DEACTIVATE ARTICLES
        if (userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
          actionBarButtons.push({ id: ActionBarActionType.deactivate, icon: 'fa-light fa-trash-can', label: 'activeOrDeactiveArticle.deactivate', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.deactivate) } })
        }

        // UPDATE FAVORITES
        if (userStore.userProfile.isValidPrivilege(privileges.favorite.createUpdate)) {
          actionBarButtons.push({ id: ActionBarActionType.editFav, icon: 'fa-light fa-star', label: 'catalog.actionBar.editFav', disabled: false, onClick: onEditFav })
        }

        if (userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
          actionBarButtons.push({ id: ActionBarActionType.manageArticleState, icon: 'fa-light fa-edit', label: 'articleDetails.actions.manageArticleState', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.manageArticleState) } })
        }

        // UPDATE SEGMENTATIONS
        if (userStore.userProfile.isValidPrivilege(privileges.article.updateSegmentation) && userStore.userProfile.accountDetails.AccountTypeId === 1 && userStore.userProfile.accountDetails.AccountId !== userStore.activeCatalog!.AccountId) {
          actionBarButtons.push({ id: ActionBarActionType.updateArticleSegmentation, icon: 'fa-light fa-edit', label: 'articleDetails.actions.editSegmentations', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.updateArticleSegmentation) } })
        }

        // UPDATE RETAIL WINDOW
        if (userStore.userProfile.isValidPrivilege(privileges.article.updateRetailWindow) && userStore.activeCatalog?.IsShippingWindowApply && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)
          && (userStore.activeCatalog.DataSourceTypeId === appConstants.catalogTypes.master || !selectedArticlesInfo.value.containsArticlesWithoutRetailWindowUpdateAllowed)
        ) {
          actionBarButtons.push({ id: ActionBarActionType.updateRetailWindow, icon: 'fa-light fa-edit', label: 'articleDetails.actions.editRetailWindow', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.updateRetailWindow) } })
        }

        // UPDATE Prices
        if ((userStore.userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices) || userStore.userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices)) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
          actionBarButtons.push({ id: ActionBarActionType.updatePrices, icon: 'fa-light fa-edit', label: 'catalog.actionBar.editPrices', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.updatePrices) } })
        }
        // ASSIGN RESOURCES
        if (isResourcesModuleEnabled.value && (userStore.userProfile.isValidPrivilege(privileges.resource.create) || userStore.userProfile.isValidPrivilege(privileges.resource.assign)) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
          actionBarButtons.push({ id: ActionBarActionType.assignArticleResource, icon: 'fa-light fa-images', label: 'articleDetails.actions.assignResources', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.assignArticleResource) } })
        }
        // Update Parent Model
        if (userStore.userProfile.isValidPrivilege(privileges.article.updateParentModel) && !selectedArticlesInfo.value.containsLinkedArticles && (userStore.myAttributes && utils.isDefined(userStore.myAttributes.parent_model_type)) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
          actionBarButtons.push({ id: ActionBarActionType.editParentModel, icon: 'fa-light fa-edit', label: 'articleDetails.actions.editParentModel', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.editParentModel) } })
        }
      }
      else if (!selectedArticlesInfo.value.containsActiveArticles) { // all selected articles must be inactive/unassorted
        if (userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited) {
          // ACTIVATE ARTICLES : user must have relevant privilege all selected articles must be inactive, belongs to current catalog (based on previous implementation)
          if (userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
            actionBarButtons.push({ id: ActionBarActionType.activate, icon: 'fa-light fa-rotate-right', label: 'activeOrDeactiveArticle.activate', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.activate) } })
          }
        }
        else {
          // ASSORT ARTICLES : user must have relevant privileges all selected articles must be unassorted, belongs to current catalog (based on previous implementation)
          if (userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles) && !selectedArticlesInfo.value.containsGloballyDroppedArticles && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
            actionBarButtons.push({ id: ActionBarActionType.assortArticles, icon: 'fa-light fa-plus', label: 'assortArticles.title', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.assortArticles) } })
          }
        }
      }
      // EDIT SIZES allowed for inactive articles
      if (userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale) && userStore.sellerDetails?.IsSizeScaleByStyle && userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScaleBulk) && !selectedArticlesInfo.value.containsChildInactiveArticles && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
        actionBarButtons.push({ id: ActionBarActionType.editSizes, icon: 'fa-light fa-edit', label: 'articleDetails.actions.editSizes', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.editSizes) } })
      }
    }
    else if (userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && !userStore.currentCustomer && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles) && userStore.userProfile.isValidPrivilege(privileges.article.importArticles) && selectedArticlesInfo.value.containsRequestArticles === false
      && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) { // catalog must be ,aster, user must have relevant privilege, at least 1 selected article must be a linked article
      // add button here with disabled = false to avoid flicker on selection change, the button will be enabled if non of the selected articles belong to current catalog and all belong to same catalog (at watch "selected articles")
      actionBarButtons.push(reactive({ id: ActionBarActionType.carryOverArticles, icon: 'fa-light fa-plus', label: 'catalog.actionBar.carryOverArticles', disabled: true, onClick: () => openAddArticleDrawer('carryoverArticle') }))
    }

    // UPDATE BUCKET ATTRIBUTES
    if (isShowingBucketsListView.value && activeBucketAttributes.value.length && filterCriteria.value.length && (userStore.userProfile.accountDetails.AccountTypeId === 1 || userStore.userProfile.accountDetails.AccountId === userStore.activeCatalog!.AccountId)) {
      actionBarButtons.push({ id: ActionBarActionType.updateBucketAttributes, icon: 'fa-light fa-edit', label: 'catalog.actionBar.updateBucketAttributes', disabled: false, onClick: () => { openUpdateDrawer(ActionBarActionType.updateBucketAttributes) } })
    }

    // request actions
    if (selectedArticlesInfo.value.containsRequestArticles && !selectedArticlesInfo.value.containsArticles) {
      if (selectedArticlesInfo.value.containsInactiveRequests === false) {
        if (userStore.userProfile.isValidPrivilege(privileges.request.updateStatus)) {
          actionBarButtons.push({ id: ActionBarActionType.deactivateRequest, icon: 'fa-light fa-trash-can', label: 'activeOrDeactiveArticle.deactivate', disabled: !selectedArticles.value.every(selectedArticle => selectedArticle.Created_By === userStore.userProfile.id && (selectedArticle._RequestState === requestConstants.requestStates.draft || selectedArticle._RequestState === requestConstants.requestStates.new)), onClick: () => { onActivateOrDeActivateRequest(ActionBarActionType.deactivateRequest) } })
        }
        if (userStore.userProfile.isValidPrivilege(privileges.request.approveRequest)) {
          actionBarButtons.push({ id: ActionBarActionType.approveRequests, icon: 'fa-light fa-thumbs-up', label: 'requests.approve', disabled: !selectedArticles.value.every(selectedArticle => selectedArticle._RequestState === requestConstants.requestStates.new || selectedArticle._RequestState === requestConstants.requestStates.reject), onClick: () => openApproveRejectDialog(1) })
        }
        if (userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest)) {
          actionBarButtons.push({ id: ActionBarActionType.rejectRequests, icon: 'fa-light fa-thumbs-down', label: 'requests.reject', disabled: !selectedArticles.value.every(selectedArticle => selectedArticle._RequestState === requestConstants.requestStates.new || selectedArticle._RequestState === requestConstants.requestStates.approve), onClick: () => openApproveRejectDialog(2) })
        }
      }
      // else if (!selectedArticlesInfo.value.containsActiveRequests) {
      //   actionBarButtons.push({ id: ActionBarActionType.activateRequest, icon: 'fa-light fa-rotate-right', label: 'activeOrDeactiveArticle.activate', disabled: !selectedArticles.value.every(selectedArticle => selectedArticle.Created_By === userStore.userProfile.id && (selectedArticle._RequestState === requestConstants.requestStates.draft || selectedArticle._RequestState === requestConstants.requestStates.new)), onClick: () => { onActivateOrDeActivateRequest(ActionBarActionType.activateRequest) } })
      // }
      // actionBarButtons.push(reactive({ id: ActionBarActionType.confirmRequests, icon: 'fa-light fa-plus', label: 'catalog.actionBar.confirmRequests', disabled: true, onClick: () => openConfirmRequest }))
    }
  }
  return actionBarButtons
})

const enableNewStyleDetails = computed(() => userStore.activeCatalog?.Config.EnableNewStyleDetails || false)

const getThumbnailSize = computed(() => userStore.thumbnailSize)
const getBucketThumbnailSize = computed(() => browseByStore.thumbnailSize)
const getGroupByCol = computed(() => userStore.groupByCol)
const getGroupByRow = computed(() => userStore.groupByRow)
const getSavedViewModel = computed(() => {
  const result: CreateUpdateSavedViewModel = {
    Name: '',
    IsCatalogLevel: false,
    RowDivider: userStore.groupByRow,
    ColumnDivider: userStore.groupByCol,
    ImageSize: thumbnailConstants.thumbnailSizeNumberToStrEnum[userStore.thumbnailSize],
    ColumnWidth: null,
    ImageType: 'firstImage',
    BrowseByAttribute: null,
    SeasonFilterApplied: false,
    SortOrderList: null,
    CustomSort: null,
    FavoriteTags: null,
    Filter: null,
    Json: null,
    Version: 3,
  }
  let hasFilter = false
  const favoriteTags: number[] = []
  const filter: Record<string, ISavedViewFilter> = {}
  if (userStore.activeCatalog && filterCriteria.value && filterCriteria.value.length > 0) {
    filterCriteria.value.forEach((criteria: FilterCriteria) => {
      if (criteria.mode === FilterCriteriaMode.multiString && criteria.attribute === '_FavoriteTags' && criteria.multipleVals) {
        favoriteTags.push(...criteria.multipleVals.map(val => Number(val)))
      }
      else {
        hasFilter = true
        filter[criteria.attribute] = criteria.toSavedViewFilter(userStore.activeCatalog!)
      }
    })
  }
  if (favoriteTags.length) {
    result.FavoriteTags = JSON.stringify(favoriteTags)
  }
  if (hasFilter) {
    result.Filter = JSON.stringify(filter)
  }
  // set browse by
  if (utils.isDefined(browseBy.value)) {
    let browseByValue = browseBy.value.key
    if (!isBrowseByAttribute.value) {
      browseByValue = browseBy.value.key === 'browseByModel' ? 'ModelNumber' : 'ArticleNumber'
    }
    result.BrowseByAttribute = browseByValue
  }
  // set image size
  if (!isShowingBucketsListView.value) {
    result.ImageSize = thumbnailConstants.thumbnailSizeNumberToStrEnum[userStore.thumbnailSize]
  }
  else {
    result.ImageSize = thumbnailConstants.thumbnailSizeNumberToStrEnum[getBucketThumbnailSize.value]
  }
  return result
})

async function onCategoriesTreeItemClick(e: ITreeNode | undefined) {
  // If needed, more memory can be saved by traversing the tree to find the path instead of storing it in each leaf
  if (utils.isDefined(e)) {
    browseByDetailsCriteria.value = []
    filterCriteria.value = []

    console.log('Categories Tree node clicked', e.key)
    router.push({ ...router.currentRoute.value, query: { t: e.key } })
    if (!userStore.activeCatalog) { return }

    categoryCriteria.value = e.path.map(p => new FilterCriteria({ attribute: p.a, multipleVals: [(utils.isDefined(p.v) ? p.v.toLowerCase() : null)], mode: FilterCriteriaMode.multiString, exclude: false }))

    const header = e.path.map(a => a.v || '(Others)')
    articlesHeader.value.type = 'path'
    articlesHeader.value.name = undefined
    articlesHeader.value.value = header

    utils.setTitle(userStore.activeCatalog.CatalogName, e.path.map(p => p.v).join(' | '))
  }
}

async function onSavedViewTreeItemClick(e: ITreeNode | undefined) {
  if (utils.isDefined(e) && e.children.length === 0 && userStore.activeCatalog) {
    console.log('Saved View Tree node clicked', e.key)
    const savedView = await appConfig.DB!.savedViews.get({ Id: e.key })
    if (utils.isDefined(savedView)) {
      router.push({ ...router.currentRoute.value, query: { v: savedView.Id } })
      browseByDetailsCriteria.value = []
      categoryCriteria.value = []
      filterCriteria.value = []
      if (utils.isValidStringValue(savedView.ColumnDivider) || utils.isValidStringValue(savedView.RowDivider)) {
        userStore.setGroupBy(savedView.ColumnDivider, savedView.RowDivider)
      }
      if (utils.isValidStringValue(savedView.BrowseByAttribute)) {
        setBrowseByUsingPropertyName(savedView.BrowseByAttribute!.toString())
      }
      if (utils.isValidStringValue(savedView.ImageSize) && thumbnailConstants.thumbnailSizeStrToNumberEnum[savedView.ImageSize]) {
        if (!isBrowseByAttribute.value) {
          userStore.setThumbnailSize(thumbnailConstants.thumbnailSizeStrToNumberEnum[savedView.ImageSize])
        }
        else {
          browseByStore.thumbnailSize = thumbnailConstants.thumbnailSizeStrToNumberEnum[savedView.ImageSize]
        }
      }
      if (savedView.FavoriteTags.length) {
        filterCriteria.value.push(new FilterCriteria({ attribute: '_FavoriteTags', multipleVals: savedView.FavoriteTags, mode: FilterCriteriaMode.multiString, exclude: false }))
      }
      if (!isEmpty(savedView.Filter)) {
        for (const attribute in savedView.Filter) {
          if (utils.isDefined(filterableAttributesByAttributeSystemName.value[attribute])) {
            filterCriteria.value.push(toFilterCriteria(attribute, savedView.Filter[attribute], userStore.activeCatalog))
          }
        }
      }

      articlesHeader.value.type = 'filter'
      articlesHeader.value.name = savedView.DisplayName
      articlesHeader.value.value = filterCriteria.value

      utils.setTitle(userStore.activeCatalog.CatalogName, savedView.DisplayName)
    }
  }
}

async function onFilterConfirm(e: FilterCriteria[]) {
  console.debug('Filtering', JSON.stringify(e))
  filterOpen.value = false

  router.push({ ...router.currentRoute.value, query: { f: 1 } })
  if (!userStore.activeCatalog) { return }

  browseByDetailsCriteria.value = []
  categoryCriteria.value = []
  filterCriteria.value = e.map(itm => new FilterCriteria(itm))
  articlesHeader.value.type = 'filter'
  articlesHeader.value.name = undefined
  articlesHeader.value.value = filterCriteria.value
  utils.setTitle(userStore.activeCatalog.CatalogName, filterCriteria.value.map(itm => itm.toDisplayString(userStore.myAttributes!)).join(', '))
}

function onClearFilter(e?: FilterCriteria) {
  if (!e) {
    browseByDetailsCriteria.value = []
    filterCriteria.value = []
    articlesHeader.value = { type: 'path', value: {} }
    utils.setTitle(userStore.activeCatalog!.CatalogName)
  }
  const i = filterCriteria.value.findIndex(itm => itm.attribute === e?.attribute)
  if (i >= 0) {
    filterCriteria.value.splice(i, 1)
    if (filterCriteria.value.length === 0) {
      articlesHeader.value = { type: 'path', value: {} }
      utils.setTitle(userStore.activeCatalog!.CatalogName)
    }
    else {
      utils.setTitle(userStore.activeCatalog!.CatalogName, filterCriteria.value.map(itm => itm.toDisplayString(userStore.myAttributes!)).join(', '))
    }
  }
}

async function onSaveImage() {
  if (browseByStore.isBrowseByModel) { // save images of all articles of selected models
    const queryCriterion: Array<[number, string]> = [[userStore.activeCatalog!.CatalogCode, selectedArticles.value[0].ModelNumber]]
    let modelArticles: Article[] = []
    for (let i = 1; i < selectedArticles.value.length; i++) {
      queryCriterion.push([+userStore.activeCatalog!.CatalogCode, selectedArticles.value[i].ModelNumber])
    }
    const res = await appConfig.DB!.articles.where('[CatalogCode+ModelNumber]').anyOf(queryCriterion).toArray()
    if (res && isArray(res) && res[0]) {
      modelArticles = res
      saveImageDlg.value?.showDialog(modelArticles)
    }
  }
  else {
    saveImageDlg.value?.showDialog(selectedArticles.value)
  }
}

async function onEditFav() {
  if (!userStore.activeCatalog) { return }

  favoritesDlg.value?.showDialog(selectedArticles.value)
}

function onArticleDetailsEditFav(article: MyArticle) {
  favoritesDlg.value?.showDialog([article])
}

async function onCopyArt() {
  const artNbr = selectedArticles.value.map(itm => itm.ArticleNumber)
  try {
    await navigator.clipboard.writeText(artNbr.join('\n'))
    notificationsStore.addNotification({ message: t('notifications.artsCopied'), type: 'Success' })
  }
  catch (error) {
    notificationsStore.addNotification({ message: t('notifications.copyFailed'), type: 'Alert', details: utils.getErrorMessage(error), actions: ['ShowDetails', 'Support'] })
  }
}

async function onCopyModels() {
  const modelNumbers = selectedArticles.value.map(itm => itm.ModelNumber)
  try {
    await navigator.clipboard.writeText(modelNumbers.join('\n'))
    notificationsStore.addNotification({ message: t('notifications.modelsCopied'), type: 'Success' })
  }
  catch (error) {
    notificationsStore.addNotification({ message: t('notifications.copyFailed'), type: 'Alert', details: utils.getErrorMessage(error), actions: ['ShowDetails', 'Support'] })
  }
}

function onAddArticlePanelClosed() {
  isAddArticlePanelVisible.value = false
  styleCreateAction = undefined
  selectedArticles.value = [] as MyArticle[]
}

function openUpdateDrawer(actionType: ActionBarActionType) {
  updateActionType.value = actionType
  isUpdateArticleDrawerVisible.value = true
}

function closeUpdateDrawer() {
  updateActionType.value = undefined
  isUpdateArticleDrawerVisible.value = false
}

async function onActivateOrDeActivateRequest(actionType: ActionBarActionType) {
  if (userStore.activeCatalog && selectedArticles.value && selectedArticles.value.length
    && (actionType === ActionBarActionType.activateRequest || actionType === ActionBarActionType.deactivateRequest)) {
    const title = t('general.alert')
    const activateOrDeactivateAction = actionType === ActionBarActionType.deactivateRequest ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: selectedArticles.value.map(x => x.ArticleNumber).join() })

    await confirmDialog(title, message, async () => {
      const status = actionType === ActionBarActionType.deactivateRequest ? 0 : 1
      const payload = selectedArticles.value.map(art => ({ Id: art.Id, Status: status }) as UpdateRequestStatusModel)
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, payload)
      const updatePromises: Promise<number>[] = []
      payload.forEach((item) => {
        updatePromises.push(appConfig.DB!.requests.update([userStore.activeCatalog!.CatalogCode, item.Id], { Status: item.Status }))
      })
      if (updatePromises.length) {
        await Promise.all(updatePromises)
        await userStore.doLoadData(['Requests'])
        emitter('catalogDataUpdated', { source: 'Requests', fullReload: true })
      }
      notificationsStore.addNotification({ message: t(actionType === ActionBarActionType.deactivateRequest ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', payload.length), type: 'Success' })
    })
  }
}

function onArticlesUpdated(articles: MyArticle[] | Article[], type: string = '', info: any = '') {
  let message = t('general.updatedSuccessfully')
  if (updateActionType.value === ActionBarActionType.updateArticles) {
    message = t('updateArticle.updateSuccessfully', articles.length)
  }
  else if (updateActionType.value === ActionBarActionType.activate) {
    message = t('activeOrDeactiveArticle.activateSuccessfully', articles.length)
  }
  else if (updateActionType.value === ActionBarActionType.deactivate) {
    message = t('activeOrDeactiveArticle.deactivateSuccessfully', articles.length)
  }
  else if (updateActionType.value === ActionBarActionType.updateArticleSegmentation) {
    message = t('editSegmentations.updatedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.manageArticleState) {
    message = t('manageArticleState.updatedSuccessfully', articles.length)
  }
  else if (updateActionType.value === ActionBarActionType.updatePrices) {
    message = info && info.length ? t('editPrices.lockedConfirmationMessage') : t('editPrices.updatedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.updateRetailWindow) {
    message = t('updateArticleRetailWindow.saveSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.assignArticleResource) {
    message = type === 'create' ? t('createResourceForm.resourceAddedSuccessfully') : t('assignResourceForm.resourceAssignedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.editParentModel) {
    message = t('editParentModel.updatedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.editSizes) {
    message = t('editSizes.updatedSuccessfully')
  }
  else if (updateActionType.value === ActionBarActionType.assortArticles) {
    message = t('assortArticles.articlesAssortedSuccessfully')
  }
  notificationsStore.addNotification({ message, type: 'Success' })
  closeUpdateDrawer()
}

function onSelectionChanged(arts: MyArticle[]) {
  selectedArticles.value = arts
  if (arts.length === 0) {
    selectAllCheck.value = false
    selectAllIndeterminate.value = false
  }
  else if (arts.length === listedArticles.length) {
    selectAllCheck.value = true
    selectAllIndeterminate.value = false
  }
  else {
    selectAllCheck.value = false
    selectAllIndeterminate.value = true
  }
  sBar.setItemValue('selectedArticlesCount', arts.length.toLocaleString())
}

function onBucketSelectionChanged(articles: Array<MyArticle>, selectedBkts: Array<IAttributeBucket>) {
  selectedArticles.value = articles
  selectedBuckets.value = selectedBkts
  if (selectedBkts.length === 0) {
    selectAllCheck.value = false
    selectAllIndeterminate.value = false
  }
  else if (selectedBkts.length === visibleBucketsCount.value) {
    selectAllCheck.value = true
    selectAllIndeterminate.value = false
  }
  else {
    selectAllCheck.value = false
    selectAllIndeterminate.value = true
  }
  sBar.setItemValue('selectedArticlesCount', articles.length.toLocaleString())
}

function onSelectAll(val: boolean) {
  const listRef: any = articlesListRef
  if (isShowingBucketsListView.value) {
    listRef.value = bucketsListRef
  }
  if (val) {
    listRef.value?.selectAll()
  }
  else {
    listRef.value?.deselectAll()
  }
}

function onArticlesListDataChanged(articles: MyArticle[], _articlesIndex: { [id: number]: number }) {
  listedArticles = articles
  sBar.setItemValue('listedArts', articles.length.toLocaleString())
}

function getBucketsAttributeValueCallback() {
  getAndInitBucketsAttributesValue(userStore.activeCatalog!.CatalogCode, filterCriteria.value, getGroupByCol.value, getGroupByRow.value)
}

function onFilter(filter?: FilterCriteria) {
  selectedFilter.value = filter
  filterOpen.value = true
}

function openCustomSort() {
  showCustomSortDialog.value = true
}

function onCustomSort(selectedArticleSortPropertyObject: Record<string, string[]>) {
  userStore.setCustomSortArticlePropertyAndValues(selectedArticleSortPropertyObject)
  showCustomSortDialog.value = false
}

function toolbarAddButtonClicked() {
  openAddArticleDrawer('addNew', undefined)
}

function articleListContextMenuItemClicked(menuItem: IContextMenuItem, contextArticle: MyArticle) {
  if (menuItem.key === 'copyModel') {
    openAddArticleDrawer('copyModel', contextArticle)
  }
  else if (menuItem.key === 'childModel') {
    openAddArticleDrawer('childModel', contextArticle)
  }
  else if (menuItem.key === 'similarModelRequest') {
    openAddArticleDrawer('copyModel', contextArticle, true)
  }
  else if (menuItem.key === 'articleRequest') {
    openAddArticleDrawer('carryoverModel', contextArticle, true)
  }
  else if (menuItem.key === 'assignColor') {
    assignColorAction(contextArticle)
  }
  else if (menuItem.key === 'assortArticles') {
    openAssortArticleDrawer(contextArticle)
  }
}

function onBucketContextItemUpdated(bucket?: IAttributeBucket) {
  contextBucket.value = bucket
}

function bucketListContextMenuItemClicked(menuItem: IContextMenuItem) {
  if (menuItem.key === 'updateBucketAttributes') {
    openUpdateDrawer(ActionBarActionType.updateBucketAttributes)
  }
}

function assignColorAction(article: MyArticle) {
  contextArticle = article
  showAssignColor.value = true
}

function openAddArticleDrawer(actionName: StyleCreateActionType, ctxArticle?: MyArticle, isAddRequest = false) {
  contextArticle = ctxArticle
  styleCreateAction = actionName
  isRequestToAdd = isAddRequest
  isAddArticleDrawerVisible.value = true
}

function onAddArticleDrawerClosed() {
  contextArticle = undefined
  styleCreateAction = undefined
  isAddArticleDrawerVisible.value = false
}

async function gotoRouteSelection() {
  if (!['Catalog', 'CatalogArticleDetails'].includes(route.name!.toString())) { return }
  console.debug('GotoRouteSelection')
  if (userStore.activeCatalog && route.params && route.params.articleId) {
    await openArticleDetails()
    return
  }
  else if (articleDetailsOpen.value) {
    closeArticleDetails()
    return
  }

  if (!route.query || (!route.query.t && !route.query.f && !route.query.v)) {
    await nextTick()
    categoriesRef.value?.tree?.setCurrentKey('')
    browseByDetailsCriteria.value = []
    categoryCriteria.value = []
    filterCriteria.value = []
    articlesHeader.value = { type: 'path', value: {} }
    utils.setTitle(userStore.activeCatalog!.CatalogName)
    return
  }
  if (!route.query.t && route.query.f) { return }
  if (!route.query.t && route.query.v) { return }

  const treeKey = toInteger(route.query.t)
  await nextTick()
  const oldKey = categoriesRef.value?.tree?.getCurrentKey()
  categoriesRef.value?.tree?.setCurrentKey(treeKey)
  if (oldKey !== categoriesRef.value?.tree?.getCurrentKey()) {
    onCategoriesTreeItemClick(categoriesRef.value?.tree?.getCurrentNode())
  }
}

onMounted(() => {
  sBar.setItems([
    { id: 'ttlArts', label: t('status.totalArticles'), value: userStore.totalArticles.toLocaleString(), icon: '' },
    { id: 'listedArts', label: t('status.listedArticles'), value: '0', icon: '' },
    { id: 'selectedArticlesCount', label: t('status.selectedArticles'), value: '0', icon: '' },
  ])

  gotoRouteSelection()
  asideOpened.value = !userStore.activeCatalog?.Config.HideCatalogTreeByDefault
})

watch(() => [route.params, route.query.t], gotoRouteSelection)

watch(() => userStore.totalArticles, () => {
  sBar.setItemValue('ttlArts', userStore.totalArticles.toLocaleString())
})

watch(selectedArticles, (selectedArticles) => {
  const currentCatalogArticlePromises: Array<any> = []
  const uniqueArticleTracker: Array<string> = []
  let showCarryOverArticleButton = false
  // const firstArticleModelNumber = selectedArticles.length ? selectedArticles[0].ModelNumber : null
  let isCurrentCatalogArticlesSelected = false
  selectedArticles.forEach((selectedArticle) => {
    if (selectedArticle.CatalogCode !== userStore.activeCatalog?.CatalogCode && !uniqueArticleTracker.includes(selectedArticle.ArticleNumber)) {
      showCarryOverArticleButton = true
      uniqueArticleTracker.push(selectedArticle.ArticleNumber)
      currentCatalogArticlePromises.push(appConfig.DB!.articles.where({ CatalogCode: userStore.activeCatalog?.CatalogCode, ArticleNumber: selectedArticle.ArticleNumber }).toArray())
    }
    else if (selectedArticle.CatalogCode === userStore.activeCatalog?.CatalogCode) {
      isCurrentCatalogArticlesSelected = true
    }
  })
  Promise.all(currentCatalogArticlePromises).then((response) => {
    if (showCarryOverArticleButton) {
      const selectedArticlesExistInCurrentCatalog = response.some(currentCatalogArticles => currentCatalogArticles.length) // if any article in current catalog
      const carryoverArticleActionButton = actionBarButtons.value.find(actionBarButton => actionBarButton.id === ActionBarActionType.carryOverArticles)
      if (carryoverArticleActionButton) { // by default it will be added when selected contains articles that belong to previous catalog but they will be disabled
        carryoverArticleActionButton.disabled = selectedArticlesExistInCurrentCatalog || selectedArticlesInfo.value.containsPlaceholder || isCurrentCatalogArticlesSelected
      }
    }
  })
})

function onUpdateAssignColor() {
  contextArticle = undefined
  showAssignColor.value = false
  const message = t('assignColor.colorAssignSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
}

function onClosedAssignColor() {
  contextArticle = undefined
  showAssignColor.value = false
}

function openAssortArticleDrawer(article: MyArticle) {
  contextArticle = article
  showAssortArticleForm.value = true
}

function onAssortArticle() {
  contextArticle = undefined
  showAssortArticleForm.value = false
  const message = t('assortArticles.articlesAssortedSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
}

function onAssortArticleClosed() {
  contextArticle = undefined
  showAssortArticleForm.value = false
}

function openApproveRejectDialog(type: number) {
  approveRejectActionType = type
  showApproveRejectDialog.value?.showDialog(selectedArticles.value, type)
}

function onApproveReject() {
  contextArticle = undefined
  showApproveRejectDialog.value?.closeDialog()
  const message = approveRejectActionType === 1 ? t('requests.requestApprovesSuccessfully') : t('requests.requestRejectedSuccessfully')
  notificationsStore.addNotification({ message, type: 'Success' })
}
</script>

<style lang="scss" scoped>
#catalog {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
