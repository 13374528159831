<template>
  <div id="data-table" class="m-2.5 flex flex-col h-full bg-white overflow-hidden relative shadow-lg">
    <tx-data-table-toolbar
      :actions="listActions" :total-count="data.length" :selected-count="selectedRows.length" :show-static-actions="showToolbarStaticActions"
      :filtered-count="sortedFilteredData.length" @refresh="doRefresh" @column-chooser="doShowColumnChooser()"
      @filter="doToggleFilter" @export="doExport"
    />
    <!-- TABLE -->
    <div class="relative flex flex-col h-full min-w-full overflow-hidden">
      <!-- Header Row -->
      <div
        ref="headerEl"
        class="sticky top-0 z-10 flex flex-col overflow-x-hidden overflow-y-hidden grow-0" :style="{ minHeight: `${filterVisisble ? 131 : 46}px` }"
      >
        <!-- Column Resizing gutter -->
        <div
          v-show="resizingCol" :style="{ left: `${resizingColLeft}px` }"
          class="absolute top-0 bottom-0 w-1 h-full bg-dark-grey z-splitter" @mouseup.stop="doStopResizingCol"
        />
        <div :style="{ minWidth: `${fullWidth}px` }">
          <!-- Column headers -->
          <div
            class="h-[46px] m-h-[46px] font-medium bg-gray-100 border-y border-gray-300 flex flex-row items-center"
            :style="{ minWidth: `${fullWidth}px` }" @mousemove="doResizeCol" @mouseup="doStopResizingCol"
          >
            <!-- Header Checkbox -->
            <div class="sticky left-0 p-3 bg-gray-100 w-9">
              <tx-checkbox
                id="tx-datatable-select-all"
                :model-value="selectAll" @change="doSelectAll"
              />
            </div>
            <!-- Header Cell -->
            <div
              v-for="col in actualVisibleColumns" :key="col.property"
              class="flex flex-row items-center justify-start py-2 pl-2 overflow-hidden text-sm font-medium text-left align-middle bg-gray-100 cursor-pointer"
              :style="{ width: `${col.width}px` }" @mouseup="(payload) => doSort(col, payload)"
            >
              <div
                class="overflow-hidden text-ellipsis whitespace-nowrap"
                :title="indexedColumns.get(col.property)?.title"
              >
                {{ indexedColumns.get(col.property)?.title }}
              </div>
              <!-- Header Sort Icon -->
              <div class="pl-1">
                <font-awesome-icon v-if="!sortCriteria.has(col.property)" icon="fa-light fa-sort" class="text-disabled" />
                <font-awesome-icon v-else-if="sortCriteria.get(col.property)" icon="fa-light fa-sort-up" />
                <font-awesome-icon v-else icon="fa-light fa-sort-down" />
              </div>
              <!-- Header resize handle -->
              <div class="grow h-4 min-w-[4px]" />
              <div
                class="box-border w-1 h-4 border-gray-300 border-x cursor-ew-resize"
                @mousedown.stop="(e) => doStartResizingCol(col, e)"
              />
            </div>
            <!-- Header Actions -->
            <div
              v-if="itemActions && itemActions.length"
              class="w-14 h-[44px] sticky right-0 bg-gray-100 text-sm font-medium flex flex-row justify-start align-middle items-center p-2 text-center"
            >
              {{ t('general.actions') }}
            </div>
          </div>

          <!-- Filter Row -->
          <tx-data-table-filter
            v-model:filter-criteria="filterCriteria" v-model:visible="filterVisisble"
            :visible-columns="actualVisibleColumns" :full-width="fullWidth" :indexed-columns="indexedColumns"
            @close="resetFilterCriteria(false)" @open-advance-filter="openAdvancedFilter" @open-date-range-filter="openDateRangeFilter"
          />
        </div>
      </div>
      <!-- Virtual List -->
      <div v-bind="containerProps" style="overflow-y: overlay;" class="relative grow" @scroll="doSyncScroll">
        <div v-bind="wrapperProps" class="min-h-full">
          <!-- Loading Progress Bar -->
          <tx-indeterminate-progress v-if="loading" :style="{ minWidth: `${fullWidth}px`, height: '5px' }" />

          <!-- Data Row -->
          <div
            v-for="item in list" :key="item.index"
            class="h-[46px] m-h-[46px] flex flex-row border-b border-gray-200 box-border" :style="{ minWidth: `${fullWidth}px` }"
            :class="[item.data.selected ? 'bg-yellow-50' : 'bg-white', { 'hover:cursor-pointer': clickableRecord }]"
            @click="onRowClick(item.data.data)" @click.right.stop="onRowRightClick($event, item.data.data)"
          >
            <!-- Row check box -->
            <div class="sticky left-0 p-3 my-1 w-9" :class="item.data.selected ? 'bg-yellow-50' : 'bg-white'">
              <tx-checkbox v-model="item.data.selected" @change="triggerSelectionChanged()" />
            </div>

            <!-- Data Cell -->
            <slot name="fieldViewer" :indexed-columns="indexedColumns" :columns="actualVisibleColumns" :record="item.data.data">
              <tx-data-table-field-viewer
                v-for="col in actualVisibleColumns" :key="col.property" class="px-2" :width="`${col.width}px`"
                :value="get(item.data.data, col.property, null)" :field="indexedColumns.get(col.property)!"
              />
            </slot>

            <!-- Actions -->
            <div
              v-if="itemActions && itemActions.length"
              class="sticky right-0 flex flex-row items-center justify-start p-1 text-sm text-center align-middle w-14"
              :class="item.data.selected ? 'bg-yellow-50' : 'bg-white'"
            >
              <tx-button
                type="icon" faicon="fa-light fa-ellipsis-vertical"
                @click.stop="(ev) => onItemActionsIconClick(ev, item.data)"
              />
            </div>
          </div>
        </div>
        <div ref="actionsEl" class="flex flex-col bg-white shadow-card py-3 min-w-[150px]">
          <div
            v-for="(action, index) in itemActionsRef" v-show="action.visible" :key="index" class="flex items-center p-2 max-h-[300px] overflow-x-hidden overflow-y-auto hover:bg-hover"
            :class="action.enabled ? 'cursor-pointer text-default' : 'cursor-not-allowed text-disabled'"
            @click="onActionClick(action)"
          >
            <font-awesome-icon :icon="action.icon" class="px-2" />
            <div class="px-1">
              {{ action.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <tx-data-table-column-chooser ref="columnChooserEl" @ok="doUpdateColumns" />
    <tx-data-table-advance-filter ref="advanceFilterEl" :filter-criteria="filterCriteria" @ok="doAdvanceFilter" />
    <tx-data-table-date-range-filter ref="dateRangeFilterEl" :filter-criteria="filterCriteria" @ok="doDateRangeFilter" />
    <tx-data-table-export ref="exportEl" :selection-option="selectedRows.length > 0 ? 1 : (Object.keys(filterCriteria).length > 0 && selectedRows.length === 0) ? 2 : 0" @ok="doExportData" />
    <tx-data-table-record-details ref="recordDetailsEl" :fields="props.columns" :title-attribute="props.titleAttribute" />
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside, refDebounced, useThrottleFn, useVirtualList } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { get, isFunction } from 'lodash-es'
import ExcelJS from 'exceljs'
import TxButton from '../TxButton.vue'
import TxCheckbox from '../TxCheckbox.vue'
import TxIndeterminateProgress from '../TxIndeterminateProgress.vue'
import { useDynamicPopper } from '../../composables/popper'
import TxDataTableFieldViewer from './TxDataTableFieldViewer.vue'
import TxDataTableColumnChooser from './TxDataTableColumnChooser.vue'
import TxDataTableToolbar from './TxDataTableToolbar.vue'
import TxDataTableFilter from './TxDataTableFilter.vue'
import type { IExportForm } from './TxDataTableExport.vue'
import TxDataTableExport from './TxDataTableExport.vue'
import type { ITxDataTableColumn, ITxDataTableItemAction, ITxDataTableListAction, ITxDataTableVisibleColumn } from './TxDataTable.types'
import TxDataTableAdvanceFilter from './TxDataTableAdvanceFilter.vue'
import TxDataTableDateRangeFilter from './TxDataTableDateRangeFilter.vue'
import TxDataTableRecordDetails from './TxDataTableRecordDetails.vue'
import { useUserStore } from '@/store/userData'
import { FilterCriteria, FilterCriteriaMode } from '@/models/filterCriteria'
import { generateComparer } from '@/services/dataTableFactory'
import utils from '@/services/utils'
import useMasterDataLookup from '@/modules/admin/composables/masterDataLookup'

interface IProps {
  columns: ITxDataTableColumn[]
  data: any[]
  visibleColumns?: ITxDataTableVisibleColumn[]
  loading: boolean
  itemActions: ITxDataTableItemAction[]
  listActions: ITxDataTableListAction[]
  clickableRecord?: boolean
  initialDefaultVisibleColumnMaxCount?: number
  sortColumn?: { [attributeSystemName: string]: boolean }
  showToolbarStaticActions?: boolean
  allowListDetailsOnRightClick?: boolean
  title?: string
  titleAttribute?: string
}

const props = withDefaults(defineProps<IProps>(), {
  clickableRecord: false,
  showToolbarStaticActions: true,
  initialDefaultVisibleColumnMaxCount: 10,
  allowListDetailsOnRightClick: true,
  titleAttribute: 'ArticleNumber',
})

const emits = defineEmits<{
  (e: 'refresh'): void
  (e: 'selectionChanged', items: any[]): void
  (e: 'rowClick', rowData: any): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { loadLookupForTable } = useMasterDataLookup()
const indexedColumns = computed(() => new Map(props.columns.map(o => [o.property, o])))
const actualVisibleColumns = ref<ITxDataTableVisibleColumn[]>([])
const filterCriteria = ref<Record<string, FilterCriteria>>({})
const debouncedFilterCriteria = refDebounced(filterCriteria, 500)
const sortCriteria = ref(new Map<string, boolean>(Object.entries(props.sortColumn ?? {})))
const columnChooserEl = ref<InstanceType<typeof TxDataTableColumnChooser>>()
const actionsEl = ref<HTMLInputElement | null>(null)
const headerEl = ref<HTMLElement | null>(null)
const itemActionsRef = ref<{ icon: string, label: string, enabled: boolean, visible: boolean, onClick: () => void }[]>()
const actionsPopper = useDynamicPopper(actionsEl, 'bottom-end')
const selectedRows = ref<any[]>([])
const resizingCol = ref<ITxDataTableVisibleColumn>()
let resizingColStartX = 0
let resizingColStartLeft = 0
let resizingColMoved = false
const resizingColLeft = ref(0)
const filterVisisble = ref(false)
const advanceFilterEl = ref<InstanceType<typeof TxDataTableAdvanceFilter>>()
const dateRangeFilterEl = ref<InstanceType<typeof TxDataTableDateRangeFilter>>()
const exportEl = ref<InstanceType<typeof TxDataTableExport>>()
const recordDetailsEl = ref<InstanceType<typeof TxDataTableRecordDetails>>()

const constants = {
  defaultColumnWidth: 150,
}

onClickOutside(actionsEl, () => {
  actionsPopper.hide()
})

const dataWithSelection = computed(() => props.data.map(o => ({ selected: false, data: o })))

const selectAll = computed(() => dataWithSelection.value.length > 0 && dataWithSelection.value.every(o => o.selected))

const sortedFilteredData = computed(() => {
  let data = dataWithSelection.value
  if (debouncedFilterCriteria.value) {
    data = data.filter(i => recordMatchesFilter(i.data, debouncedFilterCriteria.value))
  }
  if (sortCriteria.value.size > 0 && indexedColumns.value.size > 0) {
    const orderedSortCriteria = Array.from(sortCriteria.value, ([property, ascending]) => generateComparer(property, ascending, indexedColumns.value.get(property)!.type))
    data.sort((a, b) => compareRecordSortOrder(a.data, b.data, orderedSortCriteria))
  }
  return data
})
const fullWidth = computed(() => {
  return actualVisibleColumns.value.reduce((p, c) => p + c.width, 0) + 16 /* Margin */ + 20 /* Select box */ + (props.itemActions && props.itemActions.length ? 56 : 0) /* Actions */
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(sortedFilteredData, { itemHeight: 50 })

const doResizeCol = useThrottleFn((e: MouseEvent) => {
  if (resizingCol.value && e.currentTarget) {
    const movementOnX = e.clientX - resizingColStartX
    const newColLeft = resizingColLeft.value + movementOnX
    const newWidth = resizingCol.value.width + newColLeft - resizingColStartLeft
    if (newWidth < 75) { return }
    resizingColLeft.value += movementOnX
    resizingColStartX = e.clientX
    resizingColMoved = true
  }
}, 1)

onMounted(() => {
  reCalculateActualVisibleColumns()
  window.addEventListener('keydown', handleHotkey)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleHotkey)
})

function handleHotkey(event) {
  if (event.key === '/') {
    doToggleFilter()
    event.preventDefault()
  }
}

watch(() => props.columns, () => reCalculateActualVisibleColumns())

watch(() => toRefs(props.data), () => {
  scrollTo(0)
})

function doSort(col: ITxDataTableVisibleColumn, payload: MouseEvent) {
  if (resizingCol.value) { return }

  // clear sort criteria if user did not hold control key while click and either it was sort on multiple colum or sort was on different column
  if (!payload.ctrlKey && (sortCriteria.value.size > 1 || !sortCriteria.value.has(col.property))) {
    sortCriteria.value.clear()
  }

  if (sortCriteria.value.has(col.property)) {
    sortCriteria.value.set(col.property, !sortCriteria.value.get(col.property))
  }
  else {
    sortCriteria.value.set(col.property, true)
  }
  scrollTo(0)
}

function doShowColumnChooser() {
  columnChooserEl.value?.show(props.columns, actualVisibleColumns.value.map(o => indexedColumns.value.get(o.property)))
}

function doUpdateColumns(columns: ITxDataTableColumn[]) {
  // Remove column that are no longer selected
  const newColumns: ITxDataTableVisibleColumn[] = []
  columns.forEach((col, index) => {
    const existingCol = actualVisibleColumns.value.find(i => i.property === col.property)
    newColumns.push(existingCol ? { property: col.property, width: existingCol.width, order: index } : { property: col.property, width: constants.defaultColumnWidth, order: index })
  })
  actualVisibleColumns.value = newColumns

  // If the filter panel is open then we need to update the filter criterias
  if (filterVisisble.value) {
    resetFilterCriteria(true)
  }
}

function doSelectAll(val: TxBooleanish) {
  const newVal = val === true || val === 'true' || val === 1
  sortedFilteredData.value.forEach(o => o.selected = newVal)
  triggerSelectionChanged()
}

function triggerSelectionChanged() {
  selectedRows.value = dataWithSelection.value.reduce((acc, cur) => {
    cur.selected && acc.push(cur.data)
    return acc
  }, [] as any[])

  emits('selectionChanged', selectedRows.value)
}

function doRefresh() {
  scrollTo(0)
  emits('refresh')
}

function onRowClick(rowData: any) {
  emits('rowClick', rowData)
}

function onRowRightClick(event: MouseEvent, rowData: any) {
  event.preventDefault()
  if (props.allowListDetailsOnRightClick) {
    // show Details dialog
    recordDetailsEl.value?.showDialog(rowData)
  }
}

function reCalculateActualVisibleColumns() {
  actualVisibleColumns.value = []
  if (props.visibleColumns && props.visibleColumns.length > 0) {
    actualVisibleColumns.value = props.visibleColumns
  }
  else {
    for (let i = 0; i < props.initialDefaultVisibleColumnMaxCount && i < props.columns.length; i++) {
      actualVisibleColumns.value.push({ property: props.columns[i].property, order: i, width: constants.defaultColumnWidth })
    }
  }
}

function compareRecordSortOrder(first: any, second: any, criteriaFns: ((a: any, b: any) => any)[]) {
  return criteriaFns.reduce((acu, currentComparer) => acu || currentComparer(first, second), 0)
}

function onItemActionsIconClick(ev: Event, item: { data: any }) {
  itemActionsRef.value = props.itemActions.map((o) => {
    const res = { enabled: false, visible: false, icon: o.icon, label: o.label, onClick: () => o.onClick(item.data) }
    if (isFunction(o.enabled)) {
      res.enabled = o.enabled(item.data)
    }
    else {
      res.enabled = o.enabled
    }
    if (isFunction(o.visible)) {
      res.visible = o.visible(item.data)
    }
    else {
      res.visible = o.visible
    }
    return res
  })
  if (ev.currentTarget instanceof HTMLElement) {
    actionsPopper.show(ev.currentTarget)
  }
  else {
    console.warn(ev.target)
  }
}

function onActionClick(action) {
  if (action.enabled) {
    action.onClick()
    actionsPopper.hide()
    itemActionsRef.value = []
  }
}

function doStartResizingCol(col: ITxDataTableVisibleColumn, e: MouseEvent) {
  resizingColStartX = e.clientX
  const bcr = (e.target as HTMLElement).parentElement?.parentElement?.getBoundingClientRect()
  resizingColLeft.value = e.clientX - (bcr?.left || 0)
  resizingColStartLeft = resizingColLeft.value
  resizingCol.value = col
}

function doStopResizingCol(e: MouseEvent) {
  if (resizingColMoved && resizingCol.value) {
    const newWidth = resizingCol.value.width + resizingColLeft.value - resizingColStartLeft
    resizingCol.value.width = Math.max(newWidth, 75)
    e.stopPropagation()
  }
  resizingCol.value = undefined
  resizingColMoved = false
}

function doToggleFilter() {
  resetFilterCriteria(false)
  filterVisisble.value = !filterVisisble.value
}

function resetFilterCriteria(keepExistingCriteria: boolean) {
  if (!keepExistingCriteria) { filterCriteria.value = {} }
  actualVisibleColumns.value.forEach((itm) => {
    if (!indexedColumns.value.has(itm.property)) { return }
    const col = indexedColumns.value.get(itm.property)!
    if (!keepExistingCriteria || !filterCriteria.value.hasOwnProperty(itm.property)) {
      filterCriteria.value[itm.property] = new FilterCriteria({ attribute: itm.property, exclude: false, mode: FilterCriteria.attributeTypeToFilterMode(col.type, col.filterLookup) })
    }
  })

  if (keepExistingCriteria) {
    const existingProps = Object.getOwnPropertyNames(filterCriteria.value)
    existingProps.forEach((prop) => {
      if (!actualVisibleColumns.value.find(e => e.property === prop)) {
        delete filterCriteria.value[prop]
      }
    })
  }
}

function recordMatchesFilter(data: any, filterCriterias: Record<string, FilterCriteria>) {
  return Object.getOwnPropertyNames(filterCriterias).every((prop) => {
    const criteria = filterCriterias[prop]

    if (!criteria) {
      return true
    }

    if (criteria.filterBlank) {
      // check if the attribute value is blank (null, undefined, or empty string)
      const isBlankValue = data[criteria.attribute] == null || data[criteria.attribute] === ''
      if (!criteria.exclude) {
        // filter all the blanks
        return !!isBlankValue
      }
      else if (criteria.exclude) {
        // if filterBlank and exclude enabled then we need to show the records having proper value and discard the blank values
        return !isBlankValue
      }
    }

    const hasValue = criteria.hasValue()
    if (!hasValue) {
      return true
    }

    return criteria.matchesRecord(data, true, true)
  })
}

function doSyncScroll() {
  headerEl.value?.scrollTo({ left: containerProps.ref.value?.scrollLeft })
}

function openAdvancedFilter(col: ITxDataTableVisibleColumn) {
  advanceFilterEl.value?.show(col)
}

function openDateRangeFilter(col: ITxDataTableVisibleColumn) {
  dateRangeFilterEl.value?.show(col)
}

function doAdvanceFilter(columnFilterObject: Record<string, { filterValues: string, filterBlank: boolean, excludeMode: boolean }>) {
  Object.keys(columnFilterObject).forEach((property) => {
    const filterObject = columnFilterObject[property]
    const mode = FilterCriteriaMode.multiString
    const filterValues = filterObject.filterValues.split('\n').map(val => val.trim())

    const filterCriteriaItem = new FilterCriteria({
      attribute: property,
      mode,
      multipleVals: filterObject.filterBlank ? undefined : filterValues,
      exclude: filterObject.excludeMode.valueOf(),
      filterBlank: filterObject.filterBlank.valueOf(),
      isAdvancedFilter: true,
    })
    filterCriteria.value[property] = filterCriteriaItem
  })
  scrollTo(0)
}

function doDateRangeFilter(columnFilterObject: Record<string, { startDate: string, endDate: string, filterBlank: boolean }>) {
  Object.keys(columnFilterObject).forEach((property) => {
    const filterObject = columnFilterObject[property]
    const filterValues = [filterObject.startDate, filterObject.endDate]
    if (filterCriteria.value[property]) {
      filterCriteria.value[property].multipleVals = filterObject.filterBlank ? undefined : filterValues
      filterCriteria.value[property].filterBlank = filterObject.filterBlank.valueOf()
      filterCriteria.value[property].isAdvancedFilter = true
    }
  })
  scrollTo(0)
}

function doExport() {
  exportEl.value?.show()
}

async function doExportData(form: IExportForm) {
  if (userStore.activeCatalog) {
    const columns: ITxDataTableColumn[] = []
    if (form.columnSelection === 'visible') {
      for (const visibleColumn of actualVisibleColumns.value) {
        const column = indexedColumns.value.get(visibleColumn.property)
        if (column) {
          columns.push(column)
        }
      }
    }
    else {
      for (const column of props.columns) {
        columns.push(column)
      }
    }
    const data = form.dataSource === 'all' ? props.data : form.dataSource === 'filtered' ? sortedFilteredData.value : sortedFilteredData.value.filter(column => column.selected)

    const lookupData = await loadLookupForTable(columns, userStore.activeCatalog)

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet()

    worksheet.addRow(columns.map(col => col.title?.replace(/&amp;/, '&')))

    const chunkSize = 1000
    for (let i = 0; i < data.length; i += chunkSize) {
      await new Promise<void>(resolve => setTimeout(resolve, 0))
      const chunk = data.slice(i, i + chunkSize)
      const rows: any[] = []

      for (const item of chunk) {
        const rowData: any[] = []
        for (const column of columns) {
          const propertyValue = form.dataSource === 'all' ? get(item, column.property, null) : get(item.data, column.property, null)
          rowData.push(utils.getAdminAttributeValue(column, propertyValue, lookupData, userStore.activeCatalog))
        }

        rows.push(rowData)
      }
      if (rows.length) {
        worksheet.addRows(rows)
      }
    }

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', `${props.title || 'Records'}.xlsx`)
    link.click()

    exportEl.value?.close()
  }
}
</script>
