<template>
  <tx-dialog v-model="visible" :title="t('generateDialog.title', { action: props.isMerch ? 'Slides' : 'Frames' })" width="50%" height="70%">
    <div class="flex flex-col w-full h-full">
      <!-- Steps -->
      <div class="px-6 py-4 bg-gray-100 border-t border-gray-200">
        <div class="flex justify-between">
          <template v-for="(step, index) in steps" :key="step">
            <div class="flex flex-col items-center">
              <div
                class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                v-text="index + 1"
              />
              <div class="text-sm" v-text="step" />
            </div>
            <div
              v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
              :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
            />
          </template>
        </div>
      </div>
      <!-- Content -->
      <div class="flex-1 w-full h-full mt-4 overflow-y-auto">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        <!-- Step 1: Template & Source -->
        <div v-show="currentStepIndex === 0">
          <div class="text-base">
            {{ t('generateDialog.steps.template.desc') }}
          </div>
          <div class="grid items-center grid-cols-4 gap-4 mt-4">
            <label class="text-xs tracking-wide uppercase label required" v-text="t('generateDialog.steps.template.title')" />
            <tx-select
              v-model="form.templateId" class="col-span-3" :data="allowedTemplates" value-prop="id" display-prop="name"
              required filterable :errors="v$.templateId.$errors" @blur="v$.templateId.$touch"
            />

            <label class="text-xs tracking-wide uppercase label required">{{ t('generateDialog.steps.template.source') }}
              <div v-if="articlesLimit">(Limit {{ articlesLimit }})</div>
            </label>
            <div class="grid grid-cols-3 col-span-3 gap-4">
              <label><input v-model="form.source" type="radio" value="full"><span class="ml-1">{{ t('generateDialog.steps.template.sourceFull') }}</span></label>
              <label><input v-model="form.source" type="radio" value="fav"><span class="ml-1">{{ t('generateDialog.steps.template.sourceFav') }}</span></label>
              <label><input v-model="form.source" type="radio" value="list"><span class="ml-1">{{ t('generateDialog.steps.template.sourceList') }}</span></label>
              <label><input v-model="form.source" type="radio" value="modelList"><span class="ml-1">{{ t('generateDialog.steps.template.sourceModelList') }}</span></label>
              <label><input v-model="form.source" type="radio" value="excel"><span class="ml-1">{{ t('generateDialog.steps.template.sourceExcel') }}</span></label>
              <label v-if="form.templateId === whiteboardConstants.frameTemplatesId.standard"><input v-model="form.source" type="radio" value="savedViews"><span class="ml-1">{{ t('generateDialog.steps.template.sourceSaveViews') }}</span></label>
            </div>
          </div>
        </div>
        <!-- Step 2: Articles -->
        <div v-show="currentStepIndex === 1">
          <div class="text-base">
            {{ getArticlesLabelDesc }}
          </div>
          <div class="w-full h-full my-2">
            <tx-select
              v-if="form.source === 'fav'"
              v-model="form.sourceList"
              :label="t('generateDialog.steps.template.sourceFav')"
              :data="availableFavorites"
              value-prop="Id"
              display-prop="Tag"
              secondary-display-prop="CreatedByUserName"
              required
              clearable
              filterable
              multiple-values
            />
            <tx-input
              v-else-if="form.source === 'list'"
              v-model="form.sourceText"
              style="height: 100px;"
              type="textarea"
              :label="t('generateDialog.steps.template.sourceList')"
              :placeholder="t('generateDialog.steps.template.sourceList')"
              required
            />
            <tx-input
              v-else-if="form.source === 'modelList'"
              v-model="form.sourceText"
              style="height: 100px;"
              type="textarea"
              :label="t('generateDialog.steps.template.sourceModelList')"
              :placeholder="t('generateDialog.steps.template.sourceModelList')"
              required
            />
            <div v-else-if="form.source === 'excel'">
              <file-upload
                v-model="form.sourceFile"
                file-format-hint="Excel (xlsx)"
                accept-format=".xlsx"
                @change="onFileChange"
              />
            </div>
            <tx-select
              v-else-if="form.source === 'savedViews'"
              v-model="form.sourceList"
              :label="t('generateDialog.steps.template.sourceSaveViews')"
              :data="availableSavedViews"
              value-prop="Id"
              display-prop="FullDisplayName"
              secondary-display-prop="Type"
              required
              clearable
              filterable
              multiple-values
            />
          </div>
        </div>
        <!-- Step 3: Map -->
        <div v-show="currentStepIndex === 2">
          <div class="text-base">
            {{ t('generateDialog.steps.mapping.desc') }}
          </div>
          <file-mapping-editor
            v-show="excelWorkbook" ref="refTemplateFileMappingEditor" v-model="form.mapping" :is-merch="props.isMerch"
            :template-id="form.templateId" :excel-workbook="excelWorkbook" :current-step="currentStepIndex" :validation-step="2"
          />
        </div>
        <!-- Step 4: Options -->
        <div v-show="currentStepIndex === 3">
          <div class="text-base">
            {{ t('generateDialog.steps.options.desc', { action: props.isMerch ? 'slides' : 'frames' }) }}
          </div>
          <!-- TODO: Need to work on predefined options -->
          <!-- Options -->

          <parent-folder-editor v-if="currentStepIndex === 3 && props.isMerch" v-model="selectedFolderLabel" :merch="props.merch" :slide-tree="props.slideTree" @update="folderSelectedForSlides" />
          <options-editor
            ref="refTemplateOptionsEditor" :template-id="form.templateId" format="ppt" :is-merch="props.isMerch" :show-one-slide-per-style="props.isMerch && form.source === 'modelList'"
            :current-step="currentStepIndex" :validation-step="3" @update="updateFormOptions"
          />
        </div>
        <!-- Finish -->
        <div v-show="currentStepIndex === 4">
          <tx-alert :show="showExceedLimitWarning" type="warning" :text="form.source === 'modelList' ? t('generateDialog.limitWarningForModel', { articlesLimit }) : t('generateDialog.limitWarningForArticle', { articlesLimit }) " dismissible />
          <div class="text-base">
            {{ t('generateDialog.steps.finish.desc', { action: props.isMerch ? 'slides' : 'frames' }) }}
          </div>
          <table class="min-w-full mt-4">
            <tbody class="divide-y divide-gray-200">
              <tr>
                <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                  {{ t('generateDialog.steps.template.title') }}:
                </td>
                <td v-if="((props.isMerch && merchTemplates[form.templateId]) || templates[form.templateId])" class="px-4 py-2 text-gray-500">
                  {{ props.isMerch ? merchTemplates[form.templateId].name : templates[form.templateId].name }}
                </td>
              </tr>
              <tr>
                <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                  {{ t('generateDialog.steps.template.source') }}:
                </td>
                <td class="px-4 py-2 text-gray-500">
                  {{ form.source }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="isListIncludeInvalidArticle" class="p-2">
            <div v-if="isConsiderAllSeasonsData">
              <font-awesome-icon class="w-6 h-6 mr-2 text-red-500" icon="fa-light fa-warning" />
              <span class="text-red-500">
                {{ form.source === 'modelList' ? t('generateDialog.invalidNumberErrorModel') : t('generateDialog.invalidNumberErrorArticle') }}

              </span>
            </div>
            <div v-else>
              <font-awesome-icon class="w-6 h-6 mr-2 text-red-500" icon="fa-light fa-warning" />
              <span class="text-red-500">
                {{ form.source === 'modelList' ? t('generateDialog.invalidOrNotExistInCurrentSeasonNumberErrorModel') : t('generateDialog.invalidOrNotExistInCurrentSeasonNumberErrorArticle') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button
          type="cancel" :disabled="currentStepIndex <= 0" :loading="loading"
          :text="t('general.back')" width="113px" height="40px" @click="onBack"
        />
        <tx-button
          v-show="currentStepIndex < (steps.length - 1)" type="confirm" :loading="loading" :text="t('general.next')"
          width="113px" height="40px" :disabled="v$.$invalid" @click="onNext"
        />
        <tx-button
          v-show="currentStepIndex >= (steps.length - 1)" class="bg-green-800" type="confirm" :loading="loading" :text="t('general.finish')"
          width="113px" height="40px" @click="onFinish"
        />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { Workbook } from 'exceljs'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { clone } from 'lodash-es'
import ParentFolderEditor from '@/modules/merch/components/ParentFolderEditor.vue'
import templates from '@/modules/whiteboard/services/templates'
import merchTemplates from '@/modules/merch/services/templates'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxInput from '@/shared/components/TxInput.vue'
import FileUpload from '@/shared/components/FileUpload.vue'
import FileMappingEditor from '@/shared/components/templates/TemplateFileMappingEditor.vue'
import OptionsEditor from '@/shared/components/templates/TemplateOptionsEditor.vue'
import type FavoriteTag from '@/models/favoriteTag'
import type SavedView from '@/models/savedView'
import type Article from '@/models/article'
import type MyArticle from '@/models/myArticle'
import type { FilterCriteria } from '@/models/filterCriteria'
import { toFilterCriteria } from '@/models/filterCriteria'
import utils from '@/services/utils'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import { merchConstants, whiteboardConstants } from '@/models/constants'
import type Merch from '@/modules/merch/services/merch'
import type Whiteboard from '@/modules/whiteboard/services/whiteboard'
import { useNotificationStore } from '@/store/notification'

interface IGenerateFrameForm {
  templateId: number
  source: 'full' | 'fav' | 'list' | 'modelList' | 'excel' | 'savedViews'
  sourceList: number[]
  sourceText: string
  sourceFile: null | File
  mapping: Record<string, any>
  options: Record<string, any>
}
interface ITemplateData {
  articles: MyArticle[]
  excelData: Record<string, Record<string, string[]>> | undefined
}
const props = withDefaults(defineProps<IProps>(), { isMerch: true })
const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'createFolder', name: string, parentFolderName: string): void
}>()
const initialTemplateDataState: ITemplateData = {
  articles: [],
  excelData: undefined,
}
interface IProps {
  modelValue: boolean
  slideTree?: ITreeNode[]
  isMerch?: boolean
  merch?: Merch | undefined
  whiteboard?: Whiteboard
}

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { filterableAttributesByAttributeSystemName } = useFilterableAttributes()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const steps = ['Template', 'Articles', 'Map Columns', 'Options', 'Finish']
const currentStepIndex = ref(0)
const availableFavorites = ref<FavoriteTag[]>([])
const availableSavedViews = ref<SavedView[]>([])
const excelWorkbook = ref<Workbook>()
const refTemplateFileMappingEditor = ref<InstanceType<typeof FileMappingEditor>>()
const refTemplateOptionsEditor = ref<InstanceType<typeof OptionsEditor>>()
const showExceedLimitWarning = ref(false)
const templateData = reactive<ITemplateData>(initialTemplateDataState)
const isConsiderAllSeasonsData = ref(true)
const isListIncludeInvalidArticle = ref(false)
const allowedTemplates = computed(() => {
  // TODO: Need to check by catalog config 'AllowedMerchTemplates'
  if (props.isMerch) {
    return Object.values(merchTemplates)
  }
  else {
    return Object.values(templates)
  }
})

const selectedFolderLabel = computed(() => {
  if (props.slideTree && props.slideTree.length && props.slideTree[0]?.key) {
    return props.slideTree[0].key
  }
  else {
    return ''
  }
})

const articlesLimit = computed(() => {
  let limit = 300
  if (userStore.activeCatalog) {
    limit = userStore.activeCatalog?.Config.GenerateFrameArticlesLimit
    if (props.isMerch) {
      limit = userStore.activeCatalog?.Config.GenerateSlideArticlesLimit
    }
  }
  return limit
})
const initialFormState: IGenerateFrameForm = {
  templateId: 5,
  source: 'full',
  sourceList: [],
  sourceText: '',
  sourceFile: null,
  mapping: {},
  options: {},
}

const form = reactive<IGenerateFrameForm>({ ...initialFormState })

const getArticlesLabelDesc = computed(() => {
  return form.source === 'excel'
    ? t('generateDialog.steps.articles.descFile', { action: props.isMerch ? 'slides' : 'frames' })
    : form.source === 'fav'
      ? t('generateDialog.steps.articles.descFav', { action: props.isMerch ? 'slides' : 'frames' })
      : form.source === 'list'
        ? t('generateDialog.steps.articles.descList', { action: props.isMerch ? 'slides' : 'frames' })
        : form.source === 'modelList'
          ? t('generateDialog.steps.articles.descModelList', { action: props.isMerch ? 'slides' : 'frames' })
          : form.source === 'savedViews'
            ? t('generateDialog.steps.articles.savedView', { action: props.isMerch ? 'slides' : 'frames' })
            : ''
})

const rules = computed(() => {
  const result: Record<string, any> = {
    templateId: { required: withI18nMessage(required) },
    source: { required: withI18nMessage(required) },
    sourceList: {},
    sourceText: {},
    sourceFile: {},
    mapping: {},
    options: {},
  }
  if (currentStepIndex.value === 1) {
    if (form.source === 'fav' || form.source === 'savedViews') {
      result.sourceList = { required: withI18nMessage(required) }
    }
    else if (form.source === 'list' || form.source === 'modelList') {
      result.sourceText = { required: withI18nMessage(required) }
    }
    else if (form.source === 'excel') {
      result.sourceFile = { required: withI18nMessage(required) }
    }
  }
  return result
})
const v$ = useVuelidate(rules, form)

function resetForm() {
  Object.assign(form, initialFormState)
  form.templateId = allowedTemplates.value.length > 0
    ? allowedTemplates.value[0].id
    : 0
  refTemplateFileMappingEditor.value?.reset()
  refTemplateOptionsEditor.value?.reset()
}

async function showDialog() {
  currentStepIndex.value = 0
  resetForm()
  visible.value = true
  if (userStore.activeCatalog) {
    availableFavorites.value = (await appConfig.DB!.favoriteTags
      .where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1 })
      .filter((tag) => {
        if (tag.CreatedByUserName === userStore.currentUsername) { return true }
        if (tag.SharedUsers && tag.SharedUsers.length > 0) { return true }
        if (tag.SharedUsersGroups && tag.SharedUsersGroups.length > 0) { return true }
        return false
      })
      .toArray()
    ).sort((a, b) => {
      if (a.CreatedByUserName === userStore.currentUsername) { return -1 }
      return a.Tag.localeCompare(b.Tag)
    })

    const mySavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedBy: userStore.userProfile.id, IsCatalogLevel: 0, Status: 1 })
      .toArray()

    const catalogSavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, IsCatalogLevel: 1, Status: 1 })
      .toArray()

    availableSavedViews.value = mySavedViews.concat(catalogSavedViews)
  }
}
function folderSelectedForSlides(folder: string, parentNode) {
  form.options.folder = parentNode
}
function onFileChange(file: File | null) {
  form.mapping.sheetId = ''
  if (file) {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        excelWorkbook.value = new Workbook()
        excelWorkbook.value.xlsx.load(event.target.result as ArrayBuffer)
          .then((workbook) => {
            form.mapping.sheetId = workbook.worksheets.length > 0 ? workbook.worksheets[0].id : ''
          })
      }
    }
    reader.readAsBinaryString(file)
  }
}

function onBack() {
  if (form.source === 'full' && currentStepIndex.value === 3) {
    currentStepIndex.value = 0
  }
  else if (form.source !== 'excel' && currentStepIndex.value === 3) {
    currentStepIndex.value = 1
  }
  else {
    currentStepIndex.value--
  }
}

async function onNext() {
  if (currentStepIndex.value >= (steps.length - 1)) { return }

  if (form.source === 'full' && currentStepIndex.value === 0) {
    currentStepIndex.value = 3
  }
  else if (form.source !== 'excel' && currentStepIndex.value === 1) {
    currentStepIndex.value = 3
  }
  else if (currentStepIndex.value === 3) {
    loading.value = true
    await getTemplateData()
    loading.value = false
    currentStepIndex.value++
  }
  else {
    currentStepIndex.value++
  }
}
async function generateRequestArticleIndexes(indexKey: string) {
  const indexedRequestArticles: Record<string, Article[]> = {}
  if (userStore.activeCatalog) {
    const requests = await appConfig.DB!.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1, IsCreateArticleRequest: 1 }).toArray()
    for (const request of requests) {
      if (request.Content) {
        const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
        if (utils.isDefined(requestArticle[indexKey])) {
          if (!indexedRequestArticles[requestArticle[indexKey]!.toString()]) {
            indexedRequestArticles[requestArticle[indexKey]!.toString()] = []
          }
          indexedRequestArticles[requestArticle[indexKey]!.toString()].push(requestArticle)
        }
      }
    }
  }
  return indexedRequestArticles
}
async function getArticlesValidDBData(list: string[], isArticleList: boolean, sortedCatalogCode: number[]) {
  let articles: Article[] = []
  if (isArticleList) {
    const indexedRequestArticleNumberToArticle = await generateRequestArticleIndexes('ArticleNumber')
    const queryCriterion: Array<[number, string]> = []
    const requestArticles: Article[] = []
    for (let index = 0; index < list.length; index++) {
      if (indexedRequestArticleNumberToArticle[list[index]]) {
        requestArticles.push(indexedRequestArticleNumberToArticle[list[index]][0])
      }
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], list[index]])
      }
    }
    if (queryCriterion.length) {
      const catalogArticles = await appConfig.DB!.articles.where('[CatalogCode+ArticleNumber]')
        .anyOf(queryCriterion)
        .filter(article => article.Status === 1)
        .toArray()

      if (catalogArticles.length) {
        articles = catalogArticles
        requestArticles.forEach((requestArticle) => {
          const article = catalogArticles.find(catalogArticle => catalogArticle.ArticleNumber === requestArticle.ArticleNumber)
          if (!article) {
            articles.push(requestArticle)
          }
        })
      }
      else {
        articles = requestArticles
      }
    }
    else {
      articles = requestArticles
    }
  }
  else {
    const indexedRequestModelNumberToArticle = await generateRequestArticleIndexes('ModelNumber')

    const queryCriterion: Array<[number, number, string]> = []
    const requestArticles: Article[] = []
    for (let index = 0; index < list.length; index++) {
      if (indexedRequestModelNumberToArticle[list[index]]) {
        requestArticles.push(...indexedRequestModelNumberToArticle[list[index]])
      }
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], 1, list[index]])
      }
    }
    if (queryCriterion.length) {
      const catalogArticles = await appConfig.DB!.articles.where('[CatalogCode+Status+ModelNumber]')
        .anyOf(queryCriterion)
        .filter(article => article.Status === 1)
        .toArray()

      if (catalogArticles.length) {
        articles = catalogArticles
        requestArticles.forEach((requestArticle) => {
          const article = catalogArticles.find(catalogArticle => catalogArticle.ArticleNumber === requestArticle.ArticleNumber)
          if (!article) {
            articles.push(requestArticle)
          }
        })
      }
      else {
        articles = requestArticles
      }
    }
    else {
      articles = requestArticles
    }
  }
  articles.sort((a, b) => {
    if (a.ArticleNumber.toLowerCase() === b.ArticleNumber.toLowerCase()) {
      return sortedCatalogCode.indexOf(a.CatalogCode) - sortedCatalogCode.indexOf(b.CatalogCode)
    }
    return a.ArticleNumber.toLowerCase() > b.ArticleNumber.toLowerCase() ? 1 : -1
  })
  if (userStore.activeCatalog && !isConsiderAllSeasonsData.value) {
    articles = articles.filter(article => article.CatalogCode === userStore.activeCatalog!.CatalogCode)
  }
  if (!isListIncludeInvalidArticle.value) {
    for (let i = 0; i < list.length; i++) {
      const foundArticle = articles.find(article => ((isArticleList && article.ArticleNumber === list[i]) || (!isArticleList && article.ModelNumber === list[i])))
      if (!foundArticle) {
        isListIncludeInvalidArticle.value = true
        break
      }
    }
  }
  return articles
}
function getModelsArticlesAsPerLimit(catalogArticles) {
  const articles: Article[] = []
  let counter = 0
  const groupedByModelNumber = catalogArticles.reduce((acc, item) => {
    if (!acc[item.ModelNumber]) {
      if (counter < articlesLimit.value) {
        counter++
        acc[item.ModelNumber] = []
      }
      else {
        showExceedLimitWarning.value = true
      }
    }
    if (counter >= articlesLimit.value) {
      if (acc[item.ModelNumber]) {
        acc[item.ModelNumber].push(item)
      }
    }
    else {
      acc[item.ModelNumber].push(item)
    }
    return acc
  }, {})
  Object.values(groupedByModelNumber).forEach((data) => {
    articles.push(...data as Article[])
  })
  return articles
}
async function getTemplateData() {
  const result: { articles: MyArticle[], excelData: Record<string, Record<string, string[]>> | undefined } = {
    articles: [],
    excelData: undefined,
  }
  let sortDataList: string[] = []

  let articles: Article[] = []
  isConsiderAllSeasonsData.value = true
  isListIncludeInvalidArticle.value = false
  let isModelsGenerating = false
  showExceedLimitWarning.value = false
  if ((form.templateId !== whiteboardConstants.frameTemplatesId.standard || (form.templateId === whiteboardConstants.frameTemplatesId.standard && !form.options.frameTitle.includes('_Seasons')))
    && (form.templateId !== whiteboardConstants.frameTemplatesId.visualLineBuilder
    || (form.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && !form.options.verticalAttributes.includes('_Seasons') && !form.options.horizontalAttributes.includes('_Seasons') && !form.options.groupBy.includes('_Seasons')))) {
    isConsiderAllSeasonsData.value = false
  }
  if ((form.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && form.options.groupByModel) || (form.templateId === merchConstants.slideGenTemplatesId.techSheet && form.options.generateOneSlidePerStyle)) {
    isModelsGenerating = true
  }
  const sortedCatalogCode = props.isMerch ? [] : utils.sort(Object.values(userStore.linkedCatalogDetails), ['SeasonalSequence'], false).map(catalog => catalog.CatalogCode)
  if (userStore.activeCatalog && userStore.myAttributes) {
    sortedCatalogCode.splice(0, 0, userStore.activeCatalog.CatalogCode)
    if (form.source === 'full') {
      if (isModelsGenerating) {
        const catalogArticles = await appConfig.DB!.articles.where('[CatalogCode+Status]').equals([userStore.activeCatalog.CatalogCode, 1]).toArray()
        articles = getModelsArticlesAsPerLimit(catalogArticles)
      }
      else {
        articles = await appConfig.DB!.articles.where('[CatalogCode+Status]').equals([userStore.activeCatalog.CatalogCode, 1]).limit(articlesLimit.value).toArray()
      }
      if (userStore.totalArticles && userStore.totalArticles > articlesLimit.value) {
        showExceedLimitWarning.value = true
      }
    }
    else if (form.sourceList.length && form.source === 'fav') {
      const selectedFavs = await appConfig.DB!.favoriteTags.where('Id').anyOf(form.sourceList).toArray()
      const articleIds = new Set<number>()
      if (selectedFavs.length > 0) {
        selectedFavs.forEach((tag) => {
          if (tag.Articles.length > 0) {
            tag.Articles.forEach(artId => articleIds.add(artId))
          }
        })
      }
      if (articleIds.size > 0) {
        if (isModelsGenerating) {
          const catalogArticles = await appConfig.DB!.articles.where('[CatalogCode+Id]')
            .anyOf(Array.from(articleIds).map(articleId => [userStore.activeCatalog!.CatalogCode, articleId]))
            .filter(art => art.Status === 1)
            .toArray()
          articles = getModelsArticlesAsPerLimit(catalogArticles)
        }
        else {
          articles = await appConfig.DB!.articles.where('[CatalogCode+Id]')
            .anyOf(Array.from(articleIds).map(articleId => [userStore.activeCatalog!.CatalogCode, articleId]))
            .filter(art => art.Status === 1)
            .limit(articlesLimit.value)
            .toArray()
        }
        if (articleIds.size > articlesLimit.value) {
          showExceedLimitWarning.value = true
        }
      }
      if (articleIds.size >= articlesLimit.value) {
        showExceedLimitWarning.value = true
      }
    }
    else if (utils.isValidStringValue(form.sourceText) && form.source === 'list') {
      const articleNumbers = form.sourceText.split(/\r?\n/).map(s => s.trim())
      const articleNumbersClone = clone(articleNumbers)
      sortDataList = form.sourceText.split(/\r?\n/).map(s => s.trim())
      if (articleNumbers.length > 0) {
        if (isModelsGenerating) {
          const currentData = await getArticlesValidDBData(articleNumbers, true, sortedCatalogCode)
          articles = getModelsArticlesAsPerLimit(currentData)
          articles = sortArticlesSequence(articles, sortDataList)
        }
        else {
          while (articles.length < articlesLimit.value && articleNumbers.length !== 0) {
            let currentData = await getArticlesValidDBData(articleNumbers.splice(0, articlesLimit.value), true, sortedCatalogCode)
            currentData = sortArticlesSequence(currentData, sortDataList)
            articles.push(...currentData.splice(0, articlesLimit.value - articles.length))
          }
          if (articleNumbersClone.length > articlesLimit.value) {
            showExceedLimitWarning.value = true
          }
        }
      }
    }
    else if (utils.isValidStringValue(form.sourceText) && form.source === 'modelList') {
      const modelNumbers = form.sourceText.split(/\r?\n/).map(s => s.trim())
      const modelNumbersClone = clone(modelNumbers)
      sortDataList = form.sourceText.split(/\r?\n/).map(s => s.trim())
      if (modelNumbers.length > 0) {
        if (isModelsGenerating) {
          const currentData = await getArticlesValidDBData(modelNumbers, false, sortedCatalogCode)
          articles = getModelsArticlesAsPerLimit(currentData)
          articles = sortArticlesSequence(articles, sortDataList)
        }
        else {
          while (articles.length < articlesLimit.value && modelNumbers.length !== 0) {
            let currentData = await getArticlesValidDBData(modelNumbers.splice(0, articlesLimit.value), false, sortedCatalogCode)
            currentData = sortArticlesSequence(currentData, sortDataList)
            articles.push(...currentData.splice(0, articlesLimit.value))
          }
          if (modelNumbersClone.length > articlesLimit.value) {
            showExceedLimitWarning.value = true
          }
        }
      }
    }
    else if (form.sourceList.length && form.source === 'savedViews') {
      const selectedSavedViews = await appConfig.DB!.savedViews.where('Id').anyOf(form.sourceList).toArray()
      // const addedArticleIds = new Set<Number>()
      if (selectedSavedViews.length > 0) {
        const filterCriteria: FilterCriteria[] = []
        for (const savedView of selectedSavedViews) {
          for (const attribute in savedView.Filter) {
            if (utils.isDefined(filterableAttributesByAttributeSystemName.value[attribute])) {
              filterCriteria.push(toFilterCriteria(attribute, savedView.Filter[attribute], userStore.activeCatalog!))
            }
          }
          articles = await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog, userStore.myAttributes, filterCriteria, true, userStore.currentUsername)
          if (articles.length > articlesLimit.value) {
            showExceedLimitWarning.value = true
          }
          articles.slice(0, articlesLimit.value)
          // savedViewArticles.forEach((article) => {
          //   if(!addedArticleIds.has(article.CatalogArticleId)) {
          //     articles.push(article)
          //     addedArticleIds.add(article.CatalogArticleId)
          //   }
          // })
        }
      }
    }
    else if (utils.isDefined(form.sourceFile) && refTemplateFileMappingEditor.value && excelWorkbook.value && form.mapping.sheetId && form.source === 'excel') {
      const worksheet = excelWorkbook.value.getWorksheet(form.mapping.sheetId)
      if (worksheet) {
        const articleNumbers: string[] = []
        const excelColIndexMap = refTemplateFileMappingEditor.value.excelColumns.reduce((result, value, index) => {
          result[value] = index
          return result
        }, {} as Record<string, number>)
        const mappingColIndices: Record<string, number[]> = {}
        refTemplateFileMappingEditor.value.selectedTemplateFileMapping.forEach((mapping) => {
          mappingColIndices[mapping.name] = []
          if (mapping.multiple && form.mapping[mapping.name] && Array.isArray(form.mapping[mapping.name])) {
            form.mapping[mapping.name].forEach((val) => {
              if (utils.isDefined(excelColIndexMap[val])) {
                mappingColIndices[mapping.name].push(excelColIndexMap[val])
              }
            })
          }
          else if (utils.isDefined(excelColIndexMap[form.mapping[mapping.name]])) {
            mappingColIndices[mapping.name].push(excelColIndexMap[form.mapping[mapping.name]])
          }
        })
        if (mappingColIndices.articleNumber && mappingColIndices.articleNumber.length) {
          const excelData: Record<string, Record<string, string[]>> = {}
          const articleNumberColIndex = mappingColIndices.articleNumber[0] + 1
          worksheet.eachRow((row, rowNumber) => {
            const articleNumberCell = row.getCell(articleNumberColIndex)
            if (rowNumber > 1 && articleNumberCell && articleNumberCell.value) {
              const articleNumber = articleNumberCell.value.toString()
              articleNumbers.push(articleNumber)
              sortDataList.push(articleNumber)
              if (!utils.isDefined(excelData[articleNumber])) { excelData[articleNumber] = {} }
              for (const col in mappingColIndices) {
                if (!utils.isDefined(excelData[articleNumber][col])) { excelData[articleNumber][col] = [] }
                mappingColIndices[col].forEach((c) => {
                  const cell = row.getCell(c + 1)
                  if (cell && cell.value) {
                    excelData[articleNumber][col].push(cell.value.toString())
                  }
                })
              }
            }
          })
          if (articleNumbers.length > 0) {
            const articleNumbersClone = clone(articleNumbers)
            if (isModelsGenerating) {
              const currentData = await getArticlesValidDBData(articleNumbers, true, sortedCatalogCode)
              articles = getModelsArticlesAsPerLimit(currentData)
              articles = sortArticlesSequence(articles, sortDataList)
            }
            else {
              while (articles.length < articlesLimit.value && articleNumbers.length !== 0) {
                let currentData = await getArticlesValidDBData(articleNumbers.splice(0, articlesLimit.value), true, sortedCatalogCode)
                currentData = sortArticlesSequence(currentData, sortDataList)
                articles.push(...currentData.splice(0, articlesLimit.value))
              }
              if (articleNumbersClone.length > articlesLimit.value) {
                showExceedLimitWarning.value = true
              }
            }
          }
          result.excelData = excelData
        }
      }
    }
    const catalogCodeWiseArticle = new Map()
    articles.forEach((article) => {
      if (!catalogCodeWiseArticle.has(article.CatalogCode)) {
        catalogCodeWiseArticle.set(article.CatalogCode, [article])
      }
      else {
        catalogCodeWiseArticle.get(article.CatalogCode).push(article)
      }
    })
    const myArticlesData: MyArticle[] = []
    for (let index = 0; index < sortedCatalogCode.length; index++) {
      const catalogArticles = catalogCodeWiseArticle.get(sortedCatalogCode[index])
      if (catalogArticles && catalogArticles.length) {
        const catalog = userStore.linkedCatalogDetails[sortedCatalogCode[index]] ? userStore.linkedCatalogDetails[sortedCatalogCode[index]] : userStore.activeCatalog
        const catalogMyArticles = await appConfig.DB!.buildMyArticles(catalogArticles, catalog, userStore.linkedCatalogDetails, userStore.myAttributes, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        if (catalogMyArticles && catalogMyArticles.length) {
          myArticlesData.push(...catalogMyArticles)
        }
      }
    }
    result.articles = myArticlesData
    if (form.source === 'full' || form.source === 'fav') {
      result.articles = utils.sortMyArticles(result.articles)
    }
  }
  templateData.articles = result.articles
  templateData.excelData = result.excelData
  // return result
}
function sortArticlesSequence(articles, sortDataList) {
  if (sortDataList.length && utils.isValidStringValue(form.sourceText) && form.source === 'list') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  else if (sortDataList.length && utils.isValidStringValue(form.sourceText) && form.source === 'modelList') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ModelNumber) - sortDataList.indexOf(b.ModelNumber))
      || a.ModelNumber > b.ModelNumber || -(a.ModelNumber < b.ModelNumber))
  }
  else if (sortDataList.length && utils.isDefined(form.sourceFile) && excelWorkbook.value && form.mapping.sheetId && form.source === 'excel') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  return articles
}
async function onFinish() {
  if (props.isMerch) {
    if (merchTemplates[form.templateId] && userStore.activeCatalog) {
      errorMessage.value = ''
      loading.value = true
      // const templateData = await getTemplateData()
      if (templateData.articles.length > 0) {
        const response = await merchTemplates[form.templateId].generate(userStore.activeCatalog, templateData.articles, form.options, props.merch)
        notificationStore.addNotification({ message: t('generateDialog.steps.messages.success', { action: props.isMerch ? 'slides' : 'frames', numberOf: response }), type: 'Success' })
        visible.value = false
      }
      else {
        notificationStore.addNotification({ message: t('generateDialog.steps.messages.failed'), type: 'Warning' })
        errorMessage.value = 'Unable to generate since there is no valid article'
      }
    }
  }
  else {
    if (props.whiteboard && templates[form.templateId] && userStore.activeCatalog && userStore.myAttributes) {
      errorMessage.value = ''
      loading.value = true
      // const templateData = await getTemplateData()
      if (templateData.articles.length > 0) {
      // const excelData: Record<string, Record<string, string | string[]>>
      //   = { '1252085-001': { 'frameTitle': ['Footwear', 'Male'], 'articlePrice': ['10'] } }
        const startPosition = { x: 0, y: 0 }
        const objects = await templates[form.templateId].generate(userStore.activeCatalog, userStore.linkedCatalogDetails, templateData.articles, form.options, userStore.myAttributes, userStore.currentUsername, startPosition, templateData.excelData, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, false)
        if (objects.length && props.whiteboard) {
          props.whiteboard.addObjects(objects, true)
        }
        visible.value = false
      }
      else {
        errorMessage.value = 'Unable to generate since there is no valid article'
      }
    }
  }
  loading.value = false
}
function updateFormOptions(modelValue) {
  form.options = modelValue
}
watch(() => visible.value, val => emit('update:modelValue', val))

watch(() => form.source, (val) => {
  if (refTemplateOptionsEditor.value) {
    refTemplateOptionsEditor.value.disableOptions.frameTitle = val === 'excel'
    refTemplateOptionsEditor.value.reset()
  }
})

defineExpose({
  showDialog,
})
</script>
