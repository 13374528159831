import type { ComputedRef, Ref } from 'vue'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import useEventsBus from './eventBus'
import type Article from '@/models/article'
import type { Price } from '@/models/articlePrice'
import type { FilterCriteria } from '@/models/filterCriteria'
import MyArticle from '@/models/myArticle'
import type SimpleFavoriteTag from '@/models/simpleFavoriteTag'
import appConfig from '@/services/appConfig'
import { useBrowseByStore } from '@/store/browseBy'
import { useUserStore } from '@/store/userData'
import { ARTICLES_LIMIT } from '@/services/db'
import utils, { CancelToken } from '@/services/utils'

// export function useLiveArticlesObs(criteria: Ref<FilterCriteria[] | undefined>, onLoading: (fullReload: boolean) => void, onChanged: (fullReload: boolean) => void) {
//   const myData = ref<MyArticle[]>([])
//   const myDataIndex = computed(() => myData.value.reduce((acc, curr, index) => { acc[curr.Id] = index; return acc }, {}))
//   const userStore = useUserStore()
//   const basePart = `idb://${appConfig.T1Env}`
//   const listener = async (changedParts: ObservabilitySet) => {
//     let updateFavs = false

//     console.log('Changed parts', changedParts)
//     for (const part in changedParts) {
//       if (part === `${basePart}/favoriteTags/Articles`) {
//         // Favorites have changed
//         updateFavs = true
//       }
//     }

//     if (updateFavs) {
//       // Reload articles favorites
//       const allFavs = await appConfig.DB!.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').equals([userStore.activeCatalog!.CatalogCode, userStore.currentUsername, 1]).toArray()
//       myData.value.forEach(art => {
//         art._FavoriteTags = allFavs.filter(fav => fav.Articles.indexOf(art.Id) >= 0)
//       })
//     }
//   }

//   const reloadArticles = async () => {
//     myData.value = await appConfig.DB!.getMyArticlesByCriteria(userStore.activeCatalog!, userStore.myAttributes!, userStore.currentUsername, criteria?.value || [], true, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
//   }

//   watch(() => criteria, async () => {
//     console.debug('criteria changed')
//     onLoading(true)
//     await reloadArticles()
//     onChanged(true)
//   }, { deep: true, immediate: true })

//   Dexie.on('storagemutated', listener)

//   onUnmounted(() => {
//     Dexie.on('storagemutated').unsubscribe(listener)
//   })

//   return myData
// }

export function useLiveArticlesCriteria(criteria: ComputedRef<FilterCriteria[] | undefined>, listContainer: Ref<HTMLElement | undefined>, onLoading: (fullReload: boolean) => void, onChanged: (fullReload: boolean) => void, doLoadMore: (count: number, increaseTotalVisible: boolean) => void, totalVisibleArts, availableArticlesToLoad) {
  const myData = ref<MyArticle[]>([])
  const myDataIndex = computed(() => myData.value.reduce((acc, curr, index) => {
    acc[curr.CatalogArticleId] = index
    return acc
  }, {} as Record<string, number>))

  let lastDataLoad = new Date()

  const userStore = useUserStore()
  const browseByStore = useBrowseByStore()
  const { onAppEvent, offAppEvent } = useEventsBus()
  const scrollPosition = ref<{ top: number, left: number }>()
  let applyFilterStartTime: number | null = null
  let applyBrowseByStartTime: number | null = null

  const getCatalogCodes = () => {
    const catalogCodes: number[] = []
    if (userStore.activeCatalog) {
      catalogCodes.push(userStore.activeCatalog.CatalogCode)
    }
    if (criteria.value) {
      const seasonIndex = criteria.value.findIndex(c => c.attribute === '_Seasons')
      if (seasonIndex >= 0 && criteria.value[seasonIndex].multipleVals) {
        criteria.value[seasonIndex].multipleVals?.forEach(val => catalogCodes.push(Number(val)))
      }
    }
    return catalogCodes
  }

  const loadResources = async () => {
    const catalogCodes = new Set(getCatalogCodes())

    const resources = await appConfig.DB!.resources.where('Articles').anyOf(Object.keys(myDataIndex.value))
      .filter(itm => catalogCodes.has(itm.CatalogCode) && itm.Status === 1)
      .toArray()
    resources.sort((a, b) => {
      const result = a.SortOrder - b.SortOrder
      if (result !== 0) {
        return result
      }
      else if (a.ResourceCategory.toString().trim().toLowerCase() + a.ResourceName.toString().trim().toLowerCase() < b.ResourceCategory.toString().trim().toLowerCase() + b.ResourceName.toString().trim().toLowerCase()) {
        return -1
      }
      else if (a.ResourceCategory.toString().trim().toLowerCase() + a.ResourceName.toString().trim().toLowerCase() > b.ResourceCategory.toString().trim().toLowerCase() + b.ResourceName.toString().trim().toLowerCase()) {
        return 1
      }
      else {
        return 0
      }
    })

    myData.value.forEach((art) => {
      art._Resources = resources.filter(r => r.Articles.includes(art.Id))
    })
  }

  const reloadArticles = async (fullReload) => {
    if (!criteria?.value || criteria?.value.length === 0 || !userStore.activeCatalog) {
      nextTick(() => {
        onChanged(true)
      })
      return
    }

    if (fullReload) { onLoading(true) }

    let articles: Article[] = []
    if (!browseByStore.isBrowseByModel) {
      articles = await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog, userStore.myAttributes!, criteria?.value || [], true, userStore.currentUsername, browseByStore.browseByDetailsMatchCurrentCatalogOnlyFromCriteria, userStore.activeCatalog.RequestsEnabled > 0, fullReload ? null : lastDataLoad)
    }
    else {
      articles = await appConfig.DB!.getModelsByCriteria(userStore.activeCatalog, userStore.myAttributes!, criteria?.value || [], true, false, fullReload ? undefined : lastDataLoad)
    }

    userStore.setTotalArticles(criteria?.value)

    if (fullReload) { myData.value = [] }

    for (let index = 0; index < articles.length; index++) {
      const article = articles[index]
      let retailPrice: Price | null = null
      let wholesalePrice: Price | null = null
      let outletPrice: Price | null = null
      if (article.CatalogCode === userStore.activeCatalog?.CatalogCode) {
        if (userStore.priceGroups.retail) {
          retailPrice = article._Prices[userStore.priceGroups.retail.Id]
        }
        if (userStore.priceGroups.wholesale) {
          wholesalePrice = article._Prices[userStore.priceGroups.wholesale.Id]
        }
        if (userStore.priceGroups.outlet) {
          outletPrice = article._Prices[userStore.priceGroups.outlet.Id]
        }
      }
      else {
        const linkedCatalog = userStore.linkedCatalogDetails[article.CatalogCode]
        if (userStore.priceGroups.retail) {
          const linkedPriceGroup = linkedCatalog?._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.retail.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            retailPrice = article._Prices[linkedPriceGroup.Id]
          }
        }
        if (userStore.priceGroups.wholesale) {
          const linkedPriceGroup = linkedCatalog?._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.wholesale.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            wholesalePrice = article._Prices[linkedPriceGroup.Id]
          }
        }
        if (userStore.priceGroups.outlet) {
          const linkedPriceGroup = linkedCatalog?._IndexedCatalogPriceGroupByLowercaseName[userStore.priceGroups.outlet.Name.toString().trim().toLowerCase()]
          if (linkedPriceGroup) {
            outletPrice = article._Prices[linkedPriceGroup.Id]
          }
        }
      }

      const favoriteTags = article._FavoriteTags as SimpleFavoriteTag[]
      const myArticle = new MyArticle(article, userStore.activeCatalog!.AssignedCatalogAttributes, retailPrice?.Price || 0, wholesalePrice?.Price || 0, outletPrice?.Price || 0, [], true, [], favoriteTags, [], userStore.activeCatalog!.Season, userStore.activeCatalog!.DataSourceTypeId, userStore.activeCatalog!.Config.ArticlesCustomSortOrder)

      if (fullReload && myData.value.length >= ARTICLES_LIMIT) {
        break
      }

      if (fullReload) {
        if (appConfig.DB!.articleMatchesCriteria(myArticle, userStore.myAttributes!, criteria?.value || [], true, true)) {
          myData.value.push(myArticle)
        }
      }
      else {
        const existingIndex = myDataIndex.value[myArticle.CatalogArticleId]
        if (existingIndex !== undefined) {
          if (appConfig.DB!.articleMatchesCriteria(myArticle, userStore.myAttributes!, criteria?.value || [], true, true)) {
            myData.value[existingIndex] = myArticle
          }
          else {
            myData.value.splice(existingIndex, 1)
          }
        }
        else {
          if (appConfig.DB!.articleMatchesCriteria(myArticle, userStore.myAttributes!, criteria?.value || [], true, true)) {
            myData.value.push(myArticle)
          }
        }
      }
    }

    // if (!fullReload) {
    //   // Assign updated favorites
    //   myData.value.forEach((art) => {
    //     art._FavoriteTags = favResponse.filter(fav => fav.Articles.includes(art.Id)).map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color))
    //   })
    // }

    // sort articles
    myData.value = utils.sortMyArticles(myData.value)

    loadResources()
    // loadFavs()
    // When need to include allocations uncomment the below call
    // loadAllocations()

    onChanged(true)
    if (applyFilterStartTime !== null) {
      const loadTime = performance.now() - applyFilterStartTime
      appConfig.setApplicationInsights('Apply Filter', loadTime, userStore.activeCatalog?.CatalogCode, userStore.userProfile.userName)
      applyFilterStartTime = null
    }
    if (applyBrowseByStartTime !== null) {
      const loadTime = performance.now() - applyBrowseByStartTime
      appConfig.setApplicationInsights('Apply Browse By', loadTime, userStore.activeCatalog?.CatalogCode, userStore.userProfile.userName)
      applyBrowseByStartTime = null
    }

    lastDataLoad = new Date()
  }

  // const loadFavs =  () => {
  //   console.debug('Loading article favorites')
  //   if (favsSub && !favsSub.closed) favsSub.unsubscribe()

  //   const catalogCodes = getCatalogCodes()

  //   const favs = liveQuery(async () => appConfig.DB!.favoriteTags.where('[CatalogCode+CreatedByUserName+Status]').anyOf(catalogCodes.map(catalogCode => [catalogCode, userStore.currentUsername, 1])).toArray())
  //   favsSub = favs.subscribe(res => {
  //     myData.value.forEach(art => {
  //       art._FavoriteTags = res.filter(fav => fav.Articles.indexOf(art.Id) >= 0).map(itm => new SimpleFavoriteTag(itm.Id, itm.Tag, itm.Color))
  //     })
  //   })
  // }

  // const loadAllocations = async () => {
  //   const allocations = await appConfig.DB!.allocations.where('Articles').anyOf(myData.value.map(art => art.Id)).toArray()
  //   myData.value.forEach((art) => {
  //     art._Allocations = allocations.filter(r => r.Articles.includes(art.Id))
  //   })
  // }

  watch(() => criteria, async (newCriteria) => {
    console.info('criteria changed', newCriteria)
    applyFilterStartTime = performance.now()
    await reloadArticles(true)
  }, { deep: true, immediate: true })

  async function reloadArticlesWithPosition(fullReload: boolean = false) {
    scrollPosition.value = undefined
    if (listContainer.value) {
      scrollPosition.value = { top: listContainer.value.scrollTop, left: listContainer.value.scrollLeft }
    }
    console.info('reloadArticlesWithPosition', scrollPosition.value)
    await reloadArticles(fullReload)
  }

  async function onBrowseByChanged() {
    applyBrowseByStartTime = performance.now()
    await reloadArticles(true)
  }

  function scrollToNewArticle(newArticleId: string) {
    nextTick(() => {
      if (listContainer.value) {
        const articleElement = listContainer.value.querySelector(`[data-id="${newArticleId}"]`) as HTMLElement

        if (articleElement && articleElement !== null) {
          articleElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

          // highlight article
          articleElement.classList.add('highlight-article')

          // remove highlight after 5 seconds
          setTimeout(() => {
            articleElement.classList.remove('highlight-article')
          }, 5000)
        }
        else {
          if (availableArticlesToLoad.value !== 0) {
            doLoadMore(30, true)
            scrollToNewArticle(newArticleId)
          }
        }
      }
    })
  }

  function onCatalogDataUpdated(data: IDataUpdatedEvent) {
    reloadArticlesWithPosition(data.fullReload).then(async () => {
      // if new article created then scroll to the newly created article
      if (data && data.newArticleIds && data.newArticleIds.length > 0) {
        // check if the new articleid is present in the current view myData
        // if present we will load and scroll to new article
        let newArticleId = data.newArticleIds[0]
        let index = myData.value.findIndex(art => art.CatalogArticleId === newArticleId.toString())
        if (browseByStore.isBrowseByModel) {
          // if new model
          for (const articleId of data.newArticleIds) {
            const newindex = myData.value.findIndex(art => art.CatalogArticleId === articleId.toString())
            if (newindex !== -1) {
              newArticleId = articleId
              index = newindex
              break
            }
          }
          // when model alredy rendered then for newArticleIds will not be part of the view, find out the rendered model and highlight it
          if (index === -1 && userStore.activeCatalog) {
            const articles = await appConfig.DB!.getMyArticlesByCatalogArticleId(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, newArticleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
            if (articles.length !== 0) {
              const articlesByModel = await appConfig.DB!.getMyArticlesByModelNumber(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articles[0].ModelNumber, true, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
              if (!(articlesByModel instanceof CancelToken) && articlesByModel) {
                const modelArticleIds = articlesByModel.map(art => art.CatalogArticleId)
                for (const articleId of modelArticleIds) {
                  const newindex = myData.value.findIndex(art => art.CatalogArticleId === articleId.toString())
                  if (newindex !== -1) {
                    newArticleId = articleId
                    index = newindex
                    break
                  }
                }
              }
            }
          }
        }
        if (index !== -1) {
          nextTick(() => {
            scrollToNewArticle(newArticleId)
          })
        }
      }
    })
  }

  onMounted(() => {
    onAppEvent('catalogDataUpdated', onCatalogDataUpdated)
    onAppEvent('browseByChanged', onBrowseByChanged)
  })

  onUnmounted(() => {
    offAppEvent('catalogDataUpdated', onCatalogDataUpdated)
    offAppEvent('browseByChanged', onBrowseByChanged)
  })

  return {
    myData,
    myDataIndex,
    scrollPosition,
  }
}
